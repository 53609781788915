import React from 'react';
import {connectToWebChat} from 'botframework-webchat';
import ContactUsAttachment from './ContactUsAttachment';

const ConnectedAttachmentWithContactUs = connectToWebChat(
  ({ postActivity }) => ({ postActivity })
)(props => 
<ContactUsAttachment {...props} />
)

export default ConnectedAttachmentWithContactUs;