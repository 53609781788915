import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useEnrollmentStatus from "../../Hooks/useEnrollmentStatus";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import{ useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translation"

function EnrollmentStatusNoStatus(props) {
    const { next, closeEs } = useEnrollmentStatus();
    const { currentLanguage } = useLanguage();
    const EnrollmentStatusNoStatusTranslations = translations[currentLanguage]["EnrollmentStatusNoStatus"];
    const buttonTranslations = translations[currentLanguage]["button"];
    const { onNext } = props;

    const handleExit = () => {
        closeEs(currentLanguage);
               
        }
        
    const handleNext = () => {
        onNext();
    }

        return (
        <PageLayout>
            <PageLayout.Content>
                <Content EnrollmentStatusNoStatusTranslations={EnrollmentStatusNoStatusTranslations} />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={handleExit}type="primary" style={{ paddingBottom:'16px'}}>
                    {buttonTranslations.Exit}
                </NavigationButton>
                <NavigationButton onClick={handleNext} type="secondary" style={{ marginBottom: "20px" }}>
                    {buttonTranslations.ProvideFeedback}
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

function Content({ EnrollmentStatusNoStatusTranslations }) {
    return (
        <div
            style={{
                fontSize: '21px',
                lineHeight: '29.4px',
                fontWeight: 'normal',
                margin: '0',
            }}
        >
            <SubHeader
                style={{ display: 'flex', paddingBottom:"8px", marginBottom: "-17px"}}
                subHeader={EnrollmentStatusNoStatusTranslations.Title}
            />
            <Body
                bodyText={
                    <>
                        <div style={{ marginTop: '8px', paddingBottom:"32px"}}>
                           {EnrollmentStatusNoStatusTranslations.Body}
                           </div>
                           <div>
                           {EnrollmentStatusNoStatusTranslations.Body2}
                        </div>
                    </>
                }
            />
            
        </div>
    );
}

export default EnrollmentStatusNoStatus;