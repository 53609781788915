import React from 'react';
import {connectToWebChat} from 'botframework-webchat';
import FeedbackAttachment from "./FeedbackAttachment";

const ConnectedAttachmentWithFeedback = connectToWebChat(
  ({ postActivity }) => ({ postActivity })
)(props => 
<FeedbackAttachment {...props} />
)

export default ConnectedAttachmentWithFeedback;