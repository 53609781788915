import { useContext, useState } from "react";
import { ChatbotContext } from "../Contexts/ChatbotContext";
import {PasswordResetContext} from "../Contexts/PasswordResetContext";
import { useLanguage } from "../UiKit/Wizard/langugeContext";
import useWebChat from "./useWebChat";


function usePasswordReset() {
    const {
        isPasswordResetMenuOpen,
        setIsPasswordResetMenuOpen,
        currentPage,
        setCurrentPage,
        closeModal, 
        setCloseModal,
        helpModal,
        setHelpModal,
        offBusinessHoursHelpModal,
        setOffBusinessHoursHelpModal,
        isDidNotGetPasscodeModalOpen,
        setIsDidNotGetPasscodeModalOpen
        // setUsername,
        // setDob,
        // setOtpType,
        // setPasscode,
        // setExceededAttemptMessage,
        // setUserNotFound,
        // setServiceNotAvailable,
        // setExceededOTPMessage,
    } = useContext(PasswordResetContext);

    const { currentLanguage } = useLanguage();
    // const {sendActivity} = useWebChat
    const { setIsPasswordResetWizardOpen } = useContext(ChatbotContext);
    const { sendActivity } = useWebChat();

    const resetPasswordResetState = () => {
      //console.log("Resetting Password Reset State");
      // setUsername('');
      // setDob('');
      //  setOtpType(null);
      //  setPasscode('');
      //  setExceededAttemptMessage('');
      //  setUserNotFound(false);
      // setServiceNotAvailable(false);
      // setExceededOTPMessage('');
      // setOTPRetry('');
      // setOTPSessionExpired('');
      // setOTPServiceUnavailable('');
      // setCurrentPage(0); 
    }
    
    const next = () => {
       // console.log("Current Page", currentPage);
        setCurrentPage(currentPage + 1);
    };
    
    const previous = () => {
        //console.log("Current Page", currentPage);
        if (currentPage === 0) return;
        if (currentPage > 0) {
           // console.log("Current Page isndie", currentPage,  (currentPage - 1));
            setCurrentPage(currentPage - 1);
        }
    };
    
    const closePasswordReset = () => {
      //console.log("Closing Password Reset");
      sendActivity(currentLanguage === "en" ? { text: "Password reset", type: "message" } : { text: "Password reset", type: "message" });
    };

    const openFixedCloseModal = () => {
        setCloseModal({isOpen: true, type: "fixed"});
    };
    const openRelativeCloseModal = () => {
        //console.log("Opening relative close modal");
        setCloseModal({isOpen: true, type: "relative"});
    };
    const closeCloseModal = () => {
     // console.log("Closing Close Modal");
        setCloseModal({isOpen: false, type: null});
    }
    
    const openFixedHelpModal = () => {
      setHelpModal({isOpen: true, type: "fixed"});
    };
    const openRelativeHelpModal = () => {
      setHelpModal({isOpen: true, type: "relative"});
    };
    
    const closePhoneSupportModal = () => {
      setHelpModal({isOpen: false, type: null});
    }
    
    const openFixedOffHoursHelpModal = () => {
      setOffBusinessHoursHelpModal({isOpen: true, type: "fixed"});
    };
    const openRelativeOffHoursHelpModal = () => {
      setOffBusinessHoursHelpModal({isOpen: true, type: "relative"});
    };
    
    const closePhoneSupportOffHoursModal = () => {
      setOffBusinessHoursHelpModal({isOpen: false, type: null});
    }

    const togglePasswordResetMenu = () => {
       // console.log("Im here in reset toggle menu");
        setIsPasswordResetMenuOpen(!isPasswordResetMenuOpen);
      } 
    
    return {
        isPasswordResetMenuOpen,
        setIsPasswordResetMenuOpen,
        next,
        previous,
        closePasswordReset,
        currentPage,
        setCurrentPage,
        closeModal,
        closeCloseModal,
        openFixedCloseModal,
        openRelativeCloseModal,
        helpModal,
        openFixedHelpModal,
        openRelativeHelpModal,
        closePhoneSupportModal,
        openFixedOffHoursHelpModal,
        openRelativeOffHoursHelpModal,
        closePhoneSupportOffHoursModal,
        togglePasswordResetMenu,
        offBusinessHoursHelpModal,
        resetPasswordResetState, 
        setIsDidNotGetPasscodeModalOpen,
        isDidNotGetPasscodeModalOpen
    };
    }

export default usePasswordReset;