import React from 'react';
import {connectToWebChat} from 'botframework-webchat';
import UsernameDobAttachment from "./UsernameDobAttachment";

const ConnectedAttachmentWithUsernameDob = connectToWebChat(
  ({ postActivity }) => ({ postActivity })
)(props => 
<UsernameDobAttachment {...props} />
)

export default ConnectedAttachmentWithUsernameDob;