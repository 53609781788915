import React from 'react';
import './StarRating.css'

class StarRating extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      rating: null,
    }
  }

  rate(rating) {
    this.setState({ rating: rating });
    this.props.onClick(rating);
  }

  render() {
    var stars = [];
    
    for(var i = 1; i <= 5; i++) {
      var dynamicClass = 'star-rating__star';
      
      if (this.state.rating >= i && this.state.rating != null) {
        dynamicClass += ' is-selected';
      }

      stars.push(
        <label className={dynamicClass} onClick={ this.rate.bind(this, i)} key={'star_'+i}>
          ★
        </label>
      );
    }
    
    return (
      <div className="star-rating">
        {stars}
      </div>
    );
  }
};

export default StarRating;