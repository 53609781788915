import React, { useEffect , useState} from "react";
import classes from './Cici.module.css'; 
import ChatbotContextProvider from "../../Contexts/ChatbotContext";
import { LanguageProvider } from "../../UiKit/Wizard/langugeContext";
import Webchat from "../Webchat/Webchat";
import AffordabilityToolWizard from '../AffordabilityToolWizard/AffordabilityToolWizard';
import useWebChat from "../../Hooks/useWebChat";
import WebChatContextProvider from "../../Contexts/WebChatContext";
import useChatbot from "../../Hooks/useChatbot";
import DeveloperTools from "../DevelopmentHelpers/DeveloperTools";
import M2CWizard from "../M2CWizard/M2CWizard";
import PasswordResetWizard from "../PasswordResetWizard/PasswordResetWizard";
import EnrollmentStatusWizard from "../EnrollmentStatusWizard/EnrollmentStatusWizard";
import useLanguageHook from "../../Hooks/useLanguageHook";
import ForgotUsernameWizard from "../ForgotUsername/ForgotUsernameWizard";
import closeIcon from '../../Images/close.svg';
import chatbotHoverIcon from '../../Images/chatbot_bot_hover_icon.svg';
import chatBotIcon from "../../Images/CiCi-Desktop-Default 2.svg";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import UserSession from "../../Services/UserSession/UserSession";
import { jwtDecode } from "jwt-decode";
const userSessionService = new UserSession(localStorage, jwtDecode);

function Cici() {
    return (
        <LanguageProvider>
            <ChatbotContextProvider>
                <WebChatContextProvider>
                    <CiciHelper />
                </WebChatContextProvider>
            </ChatbotContextProvider>
        </LanguageProvider>
    );
}
 
function CiciHelper() {
    const { open, close, isMinimized, isClosed, isAffordabilityToolWizardOpen, isM2CWizardOpen, sb260Event, isEnrollmentStatusWizardOpen, isPasswordResetMenuOpen, enrollmentStatusEvent, passwordResetEvent, isForgotUsernameMenuOpen, forgotUsernameEvent, openWithUtterance, getAutoOpenUtteranceFromQueryString, hasAutoOpenUtterance, handleAutoOpenUtterance } = useChatbot();
    const { sendActivity, sendLoggingEventToBackend, sendMessage, postActivity } = useWebChat();
    const { updateLanguage } = useLanguageHook();
    const { currentLanguage } = useLanguage();

    const [isTextBoxVisible, setTextBoxVisible] = useState(true); 
    const [isCiciVisible, setCiciVisible] = useState(true); 
    const [isHovered, setIsHovered] = useState(false); 
    const [isGreetingVisible, setGreetingVisible] = useState(false);

    useEffect(() => {
        const greetingTimeout = setTimeout(() => {
            setGreetingVisible(true); 
        }, 10000);

        const dismissTimeout = setTimeout(() => {
            setGreetingVisible(false); 
        }, 20000); 

        return () => {
            clearTimeout(greetingTimeout);
            clearTimeout(dismissTimeout);
        };
    }, []);



    useEffect(() => {
        if(userSessionService.getPreviousLanguage()){
            updateLanguage(userSessionService.getPreviousLanguage());
            return;
        }
        const isPageSpanish = () => {
            return document.location.href.indexOf('espanol') !== -1;
        };
        if (isPageSpanish()) {
            updateLanguage('es');
        } else {
            updateLanguage('en');
        }
    }, []);

    useEffect(() => {
        setCiciVisible(true); 
    }, []);

    /**
     * auto open
     */
    useEffect(()=>{
        if(hasAutoOpenUtterance()){
            handleAutoOpenUtterance(getAutoOpenUtteranceFromQueryString());
        }
    },[]);

    if (isClosed || !isCiciVisible) return null;

    const shouldHideWebchat = () => {
        if (isAffordabilityToolWizardOpen) return true;
        if (isM2CWizardOpen) return true;
        if (isEnrollmentStatusWizardOpen) return true;
        if (isPasswordResetMenuOpen) return true;
        if (isForgotUsernameMenuOpen) return true;
        return false;
    }

    const getCiciClasses = () => {
        let ciciClasses = classes['cici'];
        if (isMinimized) ciciClasses += ` ${classes['cici--is-minimized']}`;
        return ciciClasses;
    };

    return (
        <div className={getCiciClasses()}>
            {process.env.NODE_ENV === "development" ? <DeveloperTools /> : null}

            {isMinimized && (
                <div className={classes['cici__wrapper']}>
                    {isGreetingVisible && (
                        <div className={classes['cici__text-box']}
                            //  style={{
                            //      marginLeft: isHovered ? '57px' : '36px', 
                            //      top: isHovered ? '-45px' : '-36px'
                            //  }}
                             >
                            <p className={classes['cici__question-text']}>
                                {currentLanguage === 'es' ? '¿Ayuda?' : 'Need Help?'}
                            </p>
                            <p className={classes['cici__help-text']}>
                                {currentLanguage === 'es' ? 'Chateemos.' : "Let's chat!"}
                            </p>
                            <button
                                className={classes['cici__close-button']}
                                onClick={() => setGreetingVisible(false)} 
                            >
                                <img src={closeIcon} alt="Close Icon" />
                            </button>
                        </div>
                    )}
                    <button
                        className={classes['cici__open-button']}
                        onClick={open} 
                        onMouseEnter={() => setIsHovered(true)} 
                        onMouseLeave={() => setIsHovered(false)} 
                    >
                       <img 
                            src={chatBotIcon} 
                            alt={currentLanguage === 'es' ? 'Icono de chat con robot sonriente' : 'Chat Icon with Smiling Robot'} 
                        />
                    </button>
                </div>
            )}

            {!isMinimized && (
                <div className={`${classes['cici__body']}`}>
                    <div className={`${shouldHideWebchat() ? 'd-none' : ''}`}>
                        <Webchat />
                    </div>
                    {isAffordabilityToolWizardOpen && <AffordabilityToolWizard sendLoggingEventToBackend={sendLoggingEventToBackend} />}
                    {isM2CWizardOpen && <M2CWizard sendActivity={sendActivity} event={sb260Event} sendLoggingEventToBackend={sendLoggingEventToBackend} />}
                    {isEnrollmentStatusWizardOpen && <EnrollmentStatusWizard sendActivity={sendActivity} event={enrollmentStatusEvent} sendLoggingEventToBackend={sendLoggingEventToBackend} sendMessage={sendMessage} />}
                    {isPasswordResetMenuOpen && <PasswordResetWizard postActivity={postActivity} sendActivity={sendActivity} event={passwordResetEvent} />}
                    {isForgotUsernameMenuOpen && <ForgotUsernameWizard postActivity={postActivity} sendActivity={sendActivity} event={forgotUsernameEvent} />}
            </div>
            )}
        </div>
    );
}
export default Cici;