import React from "react";
import { translations } from "./translations"
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useM2C from "../../Hooks/useM2C";


function KeepConfirmationZeroPremium(props) {
    const { previous, sb260Event, closeM2C } = useM2C();
    const { onNext, onBack, memberDecisions, sendLoggingEventToBackend } = props;
    const { currentLanguage } = useLanguage();
    const translationsKeepOrCancelConfirmation = translations[currentLanguage]["KeepConfirmZeroPremium"];
    const NavButton = translations[currentLanguage]["NavButton"];

    return (
        <>
        <WizardLayout.Content>
        <PageLayout>
            <PageLayout.Header style={{ marginBottom: "10px"}}>
                <SubHeader subHeader={translationsKeepOrCancelConfirmation.Title} />
            </PageLayout.Header>
            <PageLayout.Content>
                <div style={{ marginBottom: "32px" }}>
                    <Body bodyText={translationsKeepOrCancelConfirmation.Body1} />
                </div>
                <div>
                    <Body bodyText={translationsKeepOrCancelConfirmation.Body2} />
                </div>
            </PageLayout.Content>
        </PageLayout>
        </WizardLayout.Content>
        <WizardLayout.Navigation>
        <NavigationButton onClick={() => closeM2C()} type="primary">
          {NavButton.ExitButton}
        </NavigationButton>
        <NavigationButton onClick={() => onNext()} type="secondary">
          {NavButton.ProvideFeedbackButton}
        </NavigationButton>
      </WizardLayout.Navigation>
        </>
    );
}

export default KeepConfirmationZeroPremium;