import React from "react";
import styles from "./Body.module.css";

function Body({ bodyText, style }) {
    return (
        <>
            <div className={styles.cici_bodyText} style={style}>{bodyText}</div>
        </>
      );
    };
    
export default Body;
