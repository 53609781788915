import usePostActionHandler from "./usePostActionHandler";
import useIncomingActionHandler from "./useIncomingActionHandler";
import useChatbot from "./useChatbot";

function useActionHandler(){
    const { handleIncomingAction } = useIncomingActionHandler();
    const { handlePostAction } = usePostActionHandler();
    const { setShowWelcomScreen } = useChatbot();

    const handleAction = (action, next) => {
        if(_isIncomingActivityAction(action)){
            setShowWelcomScreen(false);
            return handleIncomingAction(action, next);
        }
        if(_isPostActivityAction(action)){
            setShowWelcomScreen(false);
            return handlePostAction(action, next);
        }
        return next(action);
    };

    const _isIncomingActivityAction = (action) => {
        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') return true;
        return false;
    };

    const _isPostActivityAction = (action) => {
        const actionType = action.type;
        if (actionType === 'DIRECT_LINE/POST_ACTIVITY' || actionType === 'POST_ACTIVITY') {
        return true;
        }
        return false;
    };
    
    return {
        handleAction
    }
}

export default useActionHandler;