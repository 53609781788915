import React from "react";
import classes from './WelcomeScreenSuggestedActions.module.css';
import SuggestedAction from "../../../../UiKit/SuggestedAction/SuggestedAction";
import useWebChat from "../../../../Hooks/useWebChat";
import { useLanguage } from "../../../../UiKit/Wizard/langugeContext";
import { translations } from '../translations';

function WelcomeScreenSuggestedActions() {
    const { store } = useWebChat();
    const { currentLanguage } = useLanguage();
    const welcomeScreenTranslations = translations[currentLanguage]["welcomeScreenSuggestedActions"];

    const onSuggestedActionClick = (message) => {
        store.dispatch({
            type: "WEB_CHAT/SEND_MESSAGE",
            payload: {
                text: message,
            },
        });
    };

    return (
        <div className={`${classes['welcome-screen-suggested-actions']}`}>
            {welcomeScreenTranslations.suggestedActions.map((action, index) => (
                <SuggestedAction
                    key={index}
                    onClick={() => onSuggestedActionClick(action.title)}
                    tabIndex="0" 
                >
                    {action.title}
                </SuggestedAction>
            ))}
        </div>
    );
}

export default WelcomeScreenSuggestedActions;
