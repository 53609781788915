import React from 'react';
import {connectToWebChat} from 'botframework-webchat';
import PasscodeAttachment from "./PasscodeAttachment";

const ConnectedAttachmentWithPasscode = connectToWebChat(
  ({ postActivity }) => ({ postActivity })
)(props => 
<PasscodeAttachment {...props} />
)

export default ConnectedAttachmentWithPasscode;