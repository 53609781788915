import React from 'react';
import Lottie from 'react-lottie';
import animationData from './lottie/CiCi-Avatar-Final-2024.json';
import styles from './WelcomeMessage.module.css';
import { useLanguage } from "../../../../UiKit/Wizard/langugeContext";

const WelcomeMessage = () => {
    const { currentLanguage } = useLanguage();

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const ariaLabelText = currentLanguage === 'en' 
        ? "Smiling robot assistant flying in to greet you"
        : "Asistente robot sonriente volando para saludarte";

    return (
        <div className={styles.welcome_lottie_avatar} role="img" aria-label={ariaLabelText}>
            <Lottie
                options={defaultOptions}
                height={112}
                width={112}
            />
        </div>
    );
};

export default WelcomeMessage;
