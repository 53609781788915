import React from 'react';
import Modal from '../../UiKit/Modal/Modal';
import NavigationButton, {NavigationButtonGroup} from '../../UiKit/Wizard/NavigationButton/NavigationButton'

import { useLanguage } from '../../UiKit/Wizard/langugeContext';
import { translations } from '../AffordabilityToolWizard/translations'
import useChatbot from '../../Hooks/useChatbot';
import useForgotUsername from '../../Hooks/useForgotUsername';

function CloseModal({type}) {
    const { closeCloseModal, minimize, closeForgotUsername } = useForgotUsername();
    const { currentLanguage } = useLanguage();
    const { closeForgotUsernameWizard } = useChatbot();
    const closeTranslations = translations[currentLanguage]["CloseModal"];
    return (
        <Modal type={type}>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Close onClick={()=>closeCloseModal()}/>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title>{closeTranslations.ModalTitle}</Modal.Title>
                    <Modal.Content>
                        {closeTranslations.ModalContent}
                    </Modal.Content>
                    <NavigationButtonGroup>
                        <NavigationButton style={{marginBottom: "8px"}} type={"primary"} onClick={()=>closeCloseModal()}>{closeTranslations.returnButton}</NavigationButton>
                        <NavigationButton type={"secondary"} onClick={()=>closeForgotUsernameWizard()}>{closeTranslations.exitButton}</NavigationButton>
                    </NavigationButtonGroup>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    )
}

export default CloseModal;