import React from 'react';

function ContactUsAttachment(props){

  const getOnLiveChat = () => {
    props.postActivity({
      type: 'message',
        value: {
          intentName: 'liveChatRequest'
        }
    }); 
  }

  return(
    <div className="container">
      <div className="row">
        <div className="col">
          <form className="form">
            <div className="d-flex"><span className="m-auto"><strong>Contact Us</strong></span></div>
            <a type="button" className="btn btn-block ac-pushButton style-default my-3" href="tel:8554783748">Call service center: 855-478-3748</a>
            <button type="button" className="btn btn-block ac-pushButton style-default my-3" onClick={()=>getOnLiveChat()}>Chat with a representative</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUsAttachment;