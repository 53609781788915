//traslations go here
import React from 'react';
import { Placeholder } from 'react-bootstrap';

const Es = "es";
const En = "en";

export const translations = {
  en: {
        "RetunToChatButton": "Return to chat",
        "Close": "Close",
        "Help": "Help",
        "PhoneSupport": "Phone Support",
        "OffBusinessHours": "Off Business Hours",
        button: {
            "Begin": "Begin",
            "Next": "Next",
            "Back": "Back",
            "Submit": "Submit",
            "Exit": "Exit",
            "ProvideFeedback": "Provide Feedback",
        },
        WelcomePage: {
            "Title": "Let’s check the status of your enrollment.",
            "Body": " If you have completed an application, you can quickly check the status of your Covered Califoria enrollment and details.",
            "AlertBody" :"For help with Medi-Cal enrollment, contact your local county office.",
            "AlertTitle": <><b>Medi-Cal Enrollment</b></>,
        },
        AuthenticationPage: {
            "Title": "Confirm Your Identity",
            "DOB": "Date of Birth",
            "DOBPlaceholder": "mm/dd/yyyy",
            "DOBVaildation": "Enter a valid date of birth.",
            "SSN": "Social Security Number (SSN)",
            "SSNPlaceholder1": "123",
            "SSNPlaceholder2": "45",
            "SSNPlaceholder3": "6789",
            "SSNValidation": "Enter your Social Security number.",
            "ZIP": "ZIP Code",
            "ZIPPlaceholder": "Enter ZIP Code",
            "ZIPValidation": "Enter a valid ZIP code.",
            "alt": "Medi-Cal and Covered California Logos"
        },
        EnrollmentStatusNoStatus:{
            "Title": "It looks like you’re not enrolled in coverage.",
            "Body": <> I could not find any enrollment details for your account. For more information, 
            <a 
            href="https://covered-ca.my.salesforce-sites.com/chat/?lang=en_US"target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline", color :"#2D77A3" }}>chat with a representative</a> or or give us a call.</>,
            "Body2": <><b>Covered California Service Center</b><p><a href="tel:8557084590" style={{ textDecoration: "underline", color: "#2D77A3" }}>
            (855) 708-4590
          </a></p>Monday - Friday: 8 a.m. to 6 p.m.</>
        },
        EnrollmentStatusUnsupportedScenarios:{
            "Title": "Please Contact Us",
            "Body": "I found more than one record for your account. A representative will need to help you with your enrollment status and details.",
            "Body2": <> You can{" "}
            <a
              href="https://covered-ca.my.salesforce-sites.com/chat/?lang=en_US"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "underline", color :"#2D77A3" }}
            >
              live chat
            </a>{" "}
            us or call us at the number below.</>,
            "Body3": <><b>Covered California Service Center</b><p> <a href="tel:8557084590" style={{ textDecoration: "underline", color: "#2D77A3" }}>
            (855) 708-4590
          </a></p>Monday - Friday: 8 a.m. to 6 p.m.</>,
        },
        EnrollmentStatusCoverageYear: {
            "subHeader": "Coverage Year",
            "bodyText": {
                "first": "Select the year you want to check.",
            },
            "Placeholder": "Select a year",
        },
        EnrollmentStatusResults: {
            "Title": "Enrollment Status",
             "PlanCard": {
                "healthHeader": "Health Plan Group",
                "dentalHeader": "Dental Plan Group",
                "label1": "Household Members",
                "label2": "Plan Name",
                "label3": "Coverage Dates",
                "label4": "Amount You Pay",
                "memberLabel": "Member",
                "membersLabel": "Members",
                "perMonth": "per month",
             },

        },
        EnrollmentStatusServiceError:{
            Title: <><b>Something went wrong.</b></>,
            bodyText: "Sorry, but we’re not able to complete this request right now. Please try again later."
        },
        EnrollmentStatusProvideFeedback:{
            Title: "How did we do?",
            Placeholder: "Optional: Tell us about your experience",
        },
        EnrollmentStatusThankYou:{
            Title: "Thank you!",
            bodyText: "Your feedback means a lot! With your help, we can make our services even better for you."
        },
        CloseModal : {
            ModalTitle: "Before You Exit",
            ModalContent: "If you leave now, you will lose any information you entered.",
            returnButton: "Return to tool",
            exitButton: "Exit without saving",
          },
        ToolMenu: {
            ToolMenuTitle: "Menu",
            liveHelp: {
              liveHelp: "Live Help",
              liveChat: "Live Chat",
              inPersonHelp: "In-Person Help",
              phoneSupport: "Phone Support"
            },
            commonChatTopics: {
              commonChatTopics: "Common Chat Topics",
              iNeedCoverage: "I need coverage",
              accountHelp: "Account help",
              myMedicalisEnding: "My Medi-Cal is ending",
            },
            language: {
              language: "Language",
              english: "English",
              spanish: "Spanish"
            },
            privacy: {
              privacy: "Privacy",
              privacyPolicy: {
                privacyPolicyFirst: "Do not share Personally Identifiable Information, like your Social Security Number, date of birth, case number, or last name.",
                privacyPolicyLink: "View our",
                privacyPolicySecond: "Privacy Policy",
              },
            },
            Feedback: {
              Feedback: "Feedback",
              feedbackText:{
                first:"We'd love to know how we are doing. Let us know by",
                second: "provide feedback",
              }
            },
            ToolMenuFooter: {
                label: "Resume Chat"
              }
        }

    },
    es: {
        "RetunToChatButton": "Volver al chat",
        "Close": "Cerrar",
        "Help": "Ayuda",
        "PhoneSupport": "Soporte telefónico",
        "OffBusinessHours": "Fuera del horario de atención",
        button: {
            "Begin": "Comenzar",
            "Next": "Siguiente",
            "Back": "Volver",
            "Submit": "Enviar",
            "Exit": "Salir",
            "ProvideFeedback": "Dame tus comentarios",
        },
        WelcomePage: {
            "Title": "Verifiquemos el estatus de tu inscripción.",
            "Body": "Si completaste una solicitud, puedes verificar rápidamente el estatus  de tu inscripción de Covered California.",
            "AlertBody" :"Para obtener ayuda con la inscripción en Medi-Cal, comunícate con la oficina local de tu condado.",
            "AlertTitle": <><b>Inscripción en Medi-Cal</b></>,
        },
        AuthenticationPage: {
            "Title": "Confirma tu identidad",
            "DOB": "Fecha de nacimiento",
            "DOBPlaceholder": "mm/dd/aaaa",
            "DOBVaildation": "Ingresa una fecha de nacimiento válida.",
            "SSN": "Número de Seguro Social (SSN)",
            "SSNPlaceholder1": "123",
            "SSNPlaceholder2": "45",
            "SSNPlaceholder3": "6789",
            "SSNValidation": "Ingresa un número de Seguro Social válido.",
            "ZIP": "Código postal",
            "ZIPPlaceholder": "Ingresa un código postal",
            "ZIPValidation": "Ingresa un código postal válido.",
            "alt": "Logotipos de Medi-Cal y Covered California"
        },
        EnrollmentStatusNoStatus:{
            "Title": "Parece que no estás inscrito en la cobertura.",
            "Body": <> No pude encontrar ningún detalle de inscripción para tu cuenta. Para obtener más información, puedes <a 
            href="https://covered-ca.my.salesforce-sites.com/chat/?lang=en_US"target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline", color :"#2D77A3" }}> chatear con un representante</a> o llamarnos.</>,
            "Body2": <><b>Centro de Servico de Covered California</b><p><a href="tel:8557084590" style={{ textDecoration: "underline", color: "#2D77A3" }}>
            (855) 708-4590
          </a></p>Lunes a viernes: 8 a.m. a 6 p.m.</>
        },
        EnrollmentStatusUnsupportedScenarios:{
            "Title": "Por favor, comunícate con nosotros.",
            "Body": "Encontré más de un registro para tu cuenta. Un representante tendrá que ayudarte con el estatus y los detalles de tu inscripción.",
            "Body2": <> Puedes {" "}
            <a
              href="https://covered-ca.my.salesforce-sites.com/chat/?lang=es"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "underline", color :"#2D77A3" }}
            > {""}chatear en vivo </a>  o llamarnos al número que aparece a continuación. </>,
            "Body3": <><b>Covered California Service Center</b><p><a href="tel:8557084590" style={{ textDecoration: "underline", color: "#2D77A3" }}>
            (855) 708-4590
          </a></p>Monday - Friday: 8 a.m. to 6 p.m.</>
        },
        EnrollmentStatusCoverageYear: {
            "subHeader": "Año de cobertura",
            "bodyText": {
                "first": "¿Para qué año de cobertura te inscribiste?",
            },
            "Placeholder": "Selecciona el año",
        },
        EnrollmentStatusResults: {
            "Title": "Estatus de inscripción",
             "PlanCard": {
                "healthHeader": "Plan de Salud Grupo",
                "dentalHeader": "Dental Plan Group",
                "label1": "Miembros del hogar",
                "label2": "Nombre del plan",
                "label3": "Fechas de cobertura",
                "label4": "Cantidad que pagas",
                "memberLabel": "Miembro",
                "membersLabel": "Miembros",
                "perMonth": "por mes",

             },

        },
        EnrollmentStatusServiceError:{
            Title: <><b>Algo salió mal.</b></>,
            bodyText: "Lo siento, pero no puedo completar esta solicitud en este momento. Por favor, inténtalo de nuevo más tarde."
        },
        EnrollmentStatusProvideFeedback:{
            Title: "¿Cómo fue tu experiencia hoy?",
            Placeholder: "Opcional: Cuéntanos más sobre tu experiencia.",
        },
        EnrollmentStatusThankYou:{
            Title: "¡Gracias!",
            bodyText: "¡Tus comentarios significan mucho! Con tu ayuda, podemos hacer que nuestros servicios sean aún mejores para ti."
        },
        CloseModal : {
            ModalTitle: "Antes de que salgas.",
            ModalContent: "Si sales ahora, perderás toda la información que ingresaste.",
            returnButton: "Volver a la herramienta",
            exitButton: "Salir sin guardar",
          },
        ToolMenu: {
            ToolMenuTitle: "Menú",
            liveHelp: {
              liveHelp: "Ayuda por teléfono",
              liveChat: "Chat en vivo",
              inPersonHelp: "Ayuda en persona",
              phoneSupport: "Apoyo por teléfono"
            },
            commonChatTopics: {
              commonChatTopics: "Temas de chat comunes",
              iNeedCoverage: "Necesito cobertura",
              accountHelp: "Ayuda con la cuenta",
              myMedicalisEnding: "Mi Medi-cal está por terminar",
            },
            language: {
              language: "Idioma",
              english: "Inglés",
              spanish: "Español"
            },
            privacy: {
              privacy: "Privacidad",
              privacyPolicy: {
                privacyPolicyFirst: "No compartas información de identificación personal, como tu número de Seguro Social, fecha de nacimiento, número de caso o apellido.",
                privacyPolicyLink: "Leé nuestra",
                privacyPolicySecond: "Política de privacidad",
              },
            },
            Feedback: {
              Feedback: "Comentarios",
              feedbackText:{
                first:"Nos encantaría saber cómo fue tu experiencia. Déjanos tus",
                second: "comentarios",
              }
               },
               ToolMenuFooter: {
                label: "Continuar con chat"
              },
               }
    }
}

export default translations