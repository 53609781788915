import { createDirectLine, createStore } from 'botframework-webchat';
import { jwtDecode } from 'jwt-decode';
import { DirectLine } from "botframework-directlinejs";

import useWebChat from './useWebchat/useWebChat';
import chatbotTokenService from '../Services/ChatbotTokenService';
import dataLayerService from '../Services/DatalayerService';
import UserSession from '../Services/UserSession/UserSession';
const userSessionService = new UserSession(localStorage, jwtDecode);

/**
 * This is a function that creates a DirectLine object the old way.
 */
const _oldCreateDirectLine = (directLineToken) => {
   return new DirectLine({ token: directLineToken.token });
  // return new DirectLine({
  //   token: directLineToken.token,
  //    domain: 'https://sturdy-system-jjqrwv9wj6q257p9-3002.app.github.dev/directline',
  //   webSocket: false
  // });
};

/**
 * This is a function that creates a DirectLine object the new way with react hooks
 */
const _createDirectLine = (token) => {
  return createDirectLine(token);
  // const directlineObject = createDirectLine({
  //   token,
  //   domain: 'http://localhost:3002/directline',
  //   webSocket: false
  // });
};

function useWebChatContainer() {
  return useWebChat(userSessionService, chatbotTokenService, dataLayerService, _oldCreateDirectLine, createStore);
}

export default useWebChatContainer;