import React, { useState, useEffect, useRef } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useM2C from "../../Hooks/useM2C";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import moment from "moment";
import InputMask from "react-input-mask";
import sendEventToDataLayer from "../../___archive/datalayer-event-logger-for-transactions/sendEventToDataLayer";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import styles from "../../UiKit/Wizard/WizardLayout/WizardLayout.module.css";
import Alert from "../../UiKit/Wizard/Alert/Alert";

function AuthenticationPage(props) {
  const { next, previous } = useM2C();
  const {
    dob,
    ssnPart1,
    ssnPart2,
    ssnPart3,
    setSsnPart1,
    setSsnPart2,
    setSsnPart3,
    setZip,
    setDob,
    zip,
    M2CWizardEventData,
  } = useM2C();
  const [shouldShowDOBValidationError, setShouldShowDOBValidationError] = useState(false);
  const [shouldShowSSNValidationError, setShouldShowSSNValidationError] = useState(false);
  const [shouldShowZipValidationError, setShouldShowZipValidationError] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { sendLoggingEventToBackend, authenticateSubmitText, authenticationExit } = props;
  const { currentLanguage } = useLanguage();
  const NavButton = translations[currentLanguage]["NavButton"];
  const authenticationPageTranslations = translations[currentLanguage]["AuthenticationPage"];
  const [errors, setErrors] = useState(props.error && props.error !== "" ? [props.error] : []);
  const submit = props.onSubmit;
  const { sb260Event } = useM2C();

  const ssnPart1Ref = useRef(null);
  const ssnPart2Ref = useRef(null);
  const ssnPart3Ref = useRef(null);
  const zipInputRef = useRef(null);
  const someOtherFieldRef = useRef(null);
  const dobInputRef = useRef(null);


  const isDobValid = (dob) => {
    return moment(dob, "MM/DD/YYYY", true).isValid();
  };

  const isSsnValid = (ssn) => {
    return /^[0-9]{9}$/.test(ssn);
  };

  const isZipValid = (zip) => {
    if (zip.length !== 5) return false;
    if (isNaN(zip)) return false;
    return true;
  };


  const onDOBChange = (e) => {
    const dob = e.target.value;
    setDob(dob);

    // Check if the DOB is fully filled (10 characters, including `/`)
    if (dob.length === 10 && zipInputRef.current) {
        zipInputRef.current.focus();  // Move to ZIP code input after filling DOB
    }

    setShouldShowDOBValidationError(hasSubmitted && !isDobValid(dob));


    if (isDobValid(dob) && ssnPart1Ref.current) {
        ssnPart1Ref.current.focus();
    }
};



  const onSSNChange = (part, value) => {
    const numericValue = value.replace(/\D/g, "");


    if (part === 1) {
        setSsnPart1(numericValue);
        if (numericValue.length === 3 && ssnPart2Ref.current) {
            ssnPart2Ref.current.focus();  
        }
    }
    if (part === 2) {
        setSsnPart2(numericValue);
        if (numericValue.length === 2 && ssnPart3Ref.current) {
            ssnPart3Ref.current.focus();  
        }
    }
    if (part === 3) {
        setSsnPart3(numericValue);
        if (numericValue.length === 4 && zipRef.current) {
            zipRef.current.focus();  
        }
    }

    const fullSSN = `${ssnPart1}${ssnPart2}${ssnPart3}`;
    setShouldShowSSNValidationError(hasSubmitted && fullSSN.length !== 9 && !isSsnValid(fullSSN));
};


  const onZipChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");  // Remove non-numeric characters
    setZip(numericValue);

    setShouldShowZipValidationError(hasSubmitted && numericValue.length !== 5 && !isZipValid(numericValue));
};

  const cleanSSN = () => {
    return `${ssnPart1}${ssnPart2}${ssnPart3}`;
  };

  const zipRef = useRef(null);


  const onSubmit = () => {
    const formErrors = [];
    setHasSubmitted(true);

    if (!isDobValid(dob)) {
      formErrors.push(authenticationPageTranslations.DOBVaildation);
      setShouldShowDOBValidationError(true);
    } else {
      setShouldShowDOBValidationError(false);
    }

    if (!isSsnValid(cleanSSN())) {
      formErrors.push(authenticationPageTranslations.SSNValidation);
      setShouldShowSSNValidationError(true);
    } else {
      setShouldShowSSNValidationError(false);
    }

    if (!isZipValid(zip)) {
      formErrors.push(authenticationPageTranslations.ZIPValidation);
      setShouldShowZipValidationError(true);
    } else {
      setShouldShowZipValidationError(false);
    }

    setErrors(formErrors);
    if (formErrors.length === 0) {
      submit({
        ssn: cleanSSN(),
        dob: moment(dob).format("YYYY-MM-DD"),
        zip,
      });
    }
  };

  useEffect(() => {
    if (props.error && props.error !== "") {
      setErrors([props.error]);
    }
  }, [props]);

  useEffect(() => {
    if (errors.indexOf(props.error) !== -1) {
      setErrors(errors.filter((error) => error !== props.error));
    }
  }, [zip, dob, ssnPart1, ssnPart2, ssnPart3]);

  useEffect(() => {
    if (hasSubmitted && errors.length === 0) {
      sendEventToDataLayer({
        event: "chatbot",
        eventDetails: {
          name: "cici_sb260_authentication_failure",
        },
      });

      props.sendLoggingEventToBackend({
        intentName: currentLanguage === "en" ? "M2CAuthenticateSuccess" : "M2CAuthenticateSuccessSpanish",
        text: currentLanguage === "en" ? "M2CAuthenticateSuccess" : "M2CAuthenticateSuccessSpanish"
      });
    }
  }, [hasSubmitted, errors]);

  const renderErrorMessages = () => {
    return errors.map((error, index) => (
      <Alert
        key={index}
        iconType="red"
        frameIcon="red"
        message={
          <span dangerouslySetInnerHTML={{
            __html: error.replace(
              "(855) 708-4590",
              "<a href='tel:8557084590' style='color: #334048; text-decoration: underline;'>(855) 708-4590</a>"
            )
          }} />
        }
      />
    ));
  };

  return (
    <>
      <WizardLayout.Content style={{ height: "calc(100% - 122px)" }}>
        <PageLayout>
          <PageLayout.Header style={{ marginBottom: "-17px" }}>
            <SubHeader
              style={{ marginBottom: "-" }}
              subHeader={authenticationPageTranslations.Title}
            />
          </PageLayout.Header>
          <PageLayout.Content>
            <Form>
             {errors.length > 0 && errors[0] === translations[currentLanguage]["SHOW_USER_NOT_FOUND"] && (
                <div style={{ marginTop: "32px", marginBottom: "20px" }}>
                  {renderErrorMessages()}
                </div>
              )}
              <Form.FormGroup>
                <Form.Label style={{ display: "block" }}>
                  {authenticationPageTranslations.DOB}
                </Form.Label>
                <Form.Control>
                  <InputMask
                    mask="99/99/9999"
                    value={dob}
                    onChange={onDOBChange}
                    placeholder={authenticationPageTranslations.DOBPlaceholder}
                  
                    maskChar={null}
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type="text"
                        isValid={!shouldShowDOBValidationError}
                        ref={dobInputRef}
                      />
                    )}
                  </InputMask>
                </Form.Control>
                {shouldShowDOBValidationError && (
                  <InputValidationMessage>
                    {authenticationPageTranslations.DOBVaildation}
                  </InputValidationMessage>
                )}
              </Form.FormGroup>
              <Form.FormGroup>
                <Form.Label style={{ display: "block" }}>
                  {authenticationPageTranslations.SSN}
                </Form.Label>
                <Form.Control
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "12px",
                    alignSelf: "stretch",
                  }}
                >
                  <Input
                    style={{
                      display: "flex",
                      padding: "10.5px 12px",
                      alignItems: "center",
                      gap: "10px",
                      flex: "1 0 0",
                    }}
                    placeholder={authenticationPageTranslations.SSNPlaceholder1}
                    type="text"
                    maxLength="3"
                    value={ssnPart1}
                    onChange={(e) => onSSNChange(1, e.target.value)}
                    isValid={!shouldShowSSNValidationError}
                    ref={ssnPart1Ref}
                  />
                  <Input
                    style={{
                      display: "flex",
                      padding: "10.5px 12px",
                      alignItems: "center",
                      gap: "10px",
                      width: "66px",
                    }}
                    placeholder={authenticationPageTranslations.SSNPlaceholder2}
                    type="text"
                    maxLength="2"
                    value={ssnPart2}
                    onChange={(e) => onSSNChange(2, e.target.value)}
                    isValid={!shouldShowSSNValidationError}
                    ref={ssnPart2Ref}
                  />
                  <Input
                    style={{
                      display: "flex",
                      padding: "10.5px 12px",
                      alignItems: "center",
                      gap: "10px",
                      flex: "1 0 0",
                    }}
                    placeholder={authenticationPageTranslations.SSNPlaceholder3}
                    type="text"
                    maxLength="4"
                    value={ssnPart3}
                    onChange={(e) => onSSNChange(3, e.target.value)}
                    isValid={!shouldShowSSNValidationError}
                    ref={ssnPart3Ref}
                  />
                </Form.Control>
                {shouldShowSSNValidationError && (
                  <InputValidationMessage>
                    {authenticationPageTranslations.SSNValidation}
                  </InputValidationMessage>
                )}
              </Form.FormGroup>
              <Form.FormGroup style={{ marginBottom: "22px" }}>
                <Form.Label style={{ display: "block" }}>
                  {authenticationPageTranslations.ZIP}
                </Form.Label>
                <Form.Control>
                  <Input
                    placeholder={authenticationPageTranslations.ZIPPlaceholder}
                    type="text"
                    maxLength="5"
                    value={zip}
                    onChange={onZipChange}
                    isValid={!shouldShowZipValidationError}
                    ref={zipRef}
                  />
                </Form.Control>
                {shouldShowZipValidationError && (
                  <InputValidationMessage>
                    {authenticationPageTranslations.ZIPValidation}
                  </InputValidationMessage>
                )}
              </Form.FormGroup>
            </Form>
          </PageLayout.Content>
        </PageLayout>
      </WizardLayout.Content>
      <div style={{ position: "relative" }}>
        <div className={`${styles.overlaybottom}`} style={{ height: "73px" }}></div>
      </div>
      <WizardLayout.Navigation>
        <NavigationButton onClick={onSubmit} type={"primary"}>
          {NavButton.NextButton}
        </NavigationButton>
        <NavigationButton onClick={() => previous()} type={"secondary"}>
          {NavButton.BackButton}
        </NavigationButton>
      </WizardLayout.Navigation>
    </>
  );
}

export default AuthenticationPage;