import React from 'react';
import {connectToWebChat} from 'botframework-webchat';
import EmailDobForgotUsernameAttachment from './EmailDobAttachment';

const ConnectedAttachmentWithEmailDob = connectToWebChat(
  ({ postActivity }) => ({ postActivity })
)(props => 
<EmailDobForgotUsernameAttachment {...props} />
)

export default ConnectedAttachmentWithEmailDob;