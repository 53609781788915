import { useWebChatContext } from "../../Contexts/WebChatContext";
import useLanguageHook from '../useLanguageHook';
import { getGaId } from "../../Services/PostActivityHandler";
import useActionHandler from "../useActionHandler";

function useWebChat(userSessionService, chatbotTokenService, dataLayerService, createDirectLine, createStore) {
  const { directline, setDirectline, store, setStore, error, setError } = useWebChatContext();
  const { currentLanguage } = useLanguageHook();
  const { handleAction } = useActionHandler();

  const _createDirectlineObject = (token) => {
    const directlineObject = createDirectLine(token);
    return directlineObject;
  }

  const _getToken = async () => {
    if (userSessionService.isNewUser()) {
      return await chatbotTokenService.getToken()
    }
    try {
      return await chatbotTokenService.refreshToken(userSessionService.getConversationId());
    } catch (error) {
      return await chatbotTokenService.getToken();
    }
  }

  const initializeWebchat = async () => {
    try {
      var token = await _getToken();
      var directlineObject = _createDirectlineObject(token);
      var webchatStore = _createStore();
      var language = currentLanguage;
      _updateSession({token, directlineObject, language});
      setStore(webchatStore);
      setDirectline(directlineObject);
    } catch (error) {
      setError({
        message: error.message
      });
      return;
    }
  }

  const _updateSession = ({token, directlineObject, language}) => {
    userSessionService.update({token, directlineObject, language});
  };

  const _createStore = () => {
    return createStore({}, ({ dispatch }) => next => action => {
      return handleAction(action, next);
    })
  };

  const isInitialized = () => {
    if (directline && store) {
      return true;
    }
    return false;
  };

  const sendActivity = async (activity) => {
    store.dispatch({
      meta: {
        method: "keyboard"
      },
      type: 'DIRECT_LINE/POST_ACTIVITY',
      payload: {
        activity: {
          locale: document.documentElement.lang,
          pageUrl: window.location.pathname,
          gaId: getGaId(),
          ...activity
        }
      }
    });
  };

  const sendActivityUsingDirectlineObject = (directlineObject, activity) => {
    directlineObject
      .postActivity(activity)
      .subscribe(
        (id) => {
          /*console.log(id)*/
        },
        (error) => {
          /*console.log('Error posting activity', error)*/
        }
      );
  };

  const sendLoggingEventToBackend = (payload) => {
    if (payload.intentName === "Providefeedback-MCT" || payload.intentName === "EnrollmentStatusFeedback") {
      store.dispatch({
        meta: {
          method: "keyboard"
        },
        type: 'DIRECT_LINE/POST_ACTIVITY',
        payload: {
          activity: {
            type: "event",
            name: "logEvent",
            value: {
              intent: {
                name: payload.intentName || "undefined",
                text: payload.text || "undefined"
              },
              data: {
                rating: payload.rating,
                comment: payload.comment
              }
            }
          }
        }
      });
    }
    else {
      store.dispatch({
        meta: {
          method: "keyboard"
        },
        type: 'DIRECT_LINE/POST_ACTIVITY',
        payload: {
          activity: {
            type: "event",
            name: "logEvent",
            value: {
              intent: {
                name: payload.intentName || "undefined",
                text: payload.text || "undefined"
              },
            }
          }
        }
      });
    }
  };

  const sendMessage = (message) => {
    store.dispatch({
      meta: {
        method: "keyboard"
      },
      type: 'DIRECT_LINE/POST_ACTIVITY',
      payload: {
        activity: {
          type: "message",
          text: message,
          locale: document.documentElement.lang,
          pageUrl: window.location.pathname,
          gaId: getGaId()
        }
      }
    });
  };

  const postActivity = (activity) => {
    directline
      .postActivity({
        type: "event",
        value: document.documentElement.lang,
        locale: "en",
        from: { id: "me" },
        name: "updateLanguage",
        pageUrl: window.location.pathname,
      })
      .subscribe(
        (id) => {
          /*console.log(id)*/
        },
        (error) => {
          /*console.log('Error posting activity', error)*/
        }
      );
  };

  const resetWebchat = () => {
    setDirectline(null);
    setStore(null);
    userSessionService.resetSession();
  }

  const styleOptions = {
    hideUploadButton: true
  }

  return {
    directline,
    store,
    styleOptions,
    locale: currentLanguage,
    error,
    initializeWebchat,
    isInitialized,
    sendActivity,
    sendActivityUsingDirectlineObject,
    sendLoggingEventToBackend,
    sendMessage,
    _getToken,
    postActivity,
    resetWebchat
    
  };
}

export default useWebChat;