// Desc: Welcome page for the forgot username flow
import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useForgotUsername from "../../Hooks/useForgotUsername";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";

import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";
import useChatbot from "../../Hooks/useChatbot";
import useWebChat from "../../Hooks/useWebchat/useWebChat";

function SuccessForgotUsername({ onNext }) {
    const { currentLanguage } = useLanguage();
    const { closeForgotUsername } = useForgotUsername();
    const {closeForgotUsernameWizard} = useChatbot();
    const successPageTranslations = translations[currentLanguage]["SuccessPage"];
    const buttontranslations = translations[currentLanguage]["Button"];
   
    return (
        <PageLayout>
            <PageLayout.Content>
                <Content successPageTranslations={successPageTranslations} />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton  onClick={() => closeForgotUsernameWizard()} type={"primary"}>{buttontranslations.exit}</NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

function Content({successPageTranslations}){

    const { sendMessage } = useWebChat();
    const {closeForgotUsernameWizard} = useChatbot();
    const { currentLanguage } = useLanguage();

    const handlePasswordReset= () => {
        closeForgotUsernameWizard();
        const text = currentLanguage === "en" ? "Password Reset" : "Olvidé mi contraseña";
        sendMessage( text);
    }

    const text1 = currentLanguage === "en" ? "Do you need to " : "¿Necesitas ";
    const text2 = currentLanguage === "en" ? "reset your password" : "restablecer tu contraseña";
   
    return (
        <div
            style={{
                fontSize: '21px',
                lineHeight: '29.4px',
                fontWeight: 'normal',
                margin: '0',
            }}
        >
            <SubHeader
                style={{ display: 'flex', marginBottom: '8px', marginTop: '0px' }}
                subHeader={successPageTranslations.title}
            />
            <Body
                bodyText={
                    <>
                    <div style={{ marginTop: '10px' }}>
                        {successPageTranslations.body}
                        {/* <a href="/forgot-username">Forgot your username?</a> */}
                    </div>
                    <div style={{ marginTop: '32px' }}>
                        <p>{text1} <a 
                            rel="noopener noreferrer"
                            style={{ textDecoration: "underline", color :"#2D77A3", cursor: "pointer" }}
                            onClick={() => handlePasswordReset()}
                            >{text2}</a>?</p>
                    </div>
                </>
                }
            />
        </div>
    );
}

export default SuccessForgotUsername;