import useChatbot from "./useChatbot";

const useEnrollmentStatusActionHandler = () => {
    const { openEnrollmentStatusWizard } = useChatbot();
    
    const handleEnrollmentStatusAction = (action) => {
        openEnrollmentStatusWizard(action.payload.activity.value);
    };

    return {
        handleEnrollmentStatusAction
    }
}

export default useEnrollmentStatusActionHandler;