import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const CAPTCHA_SITE_KEY = process.env.NODE_ENV === 'development' ? "6Lea14UkAAAAADFKcSV8372FNO5CoTEy4RLktKjW" : "6Ld32mQkAAAAAJDLX9kgVM56Zl8-IgOj3Xm9GawQ";

function EmailDobForgotUsernameAttachment(props){
  const [email, setEmail ] = useState("");
  const [dob, setDob] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isCaptchaHidden, setIsCaptchaHidden] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEmailValidationVisible, setIsEmailValidationVisible] = useState(false);
  const [isDobValidationVisible, setIsDobValidationVisible] = useState(false);
  const [isPrivacyStatementExpanded, setIsPrivacyStatementExpanded] = useState(false);
  const captchaRef = useRef();

  // should only run once
  useEffect(()=>{
    localStorage.setItem("conversationMayContainPii", true);
  },[]);

  const onSubmit = async () => {
    props.postActivity({
      type: 'message',
        value: {
          type: 'forgotUsernameCardClick',
          email: email,
          dob: dob,
          //captchaToken: token,
          intentName: 'forgotUsername'
        }
    }); 
    setIsCaptchaHidden(true);
    setIsSubmitted(true);
  }

  const onCaptchaChange = (captcha) => {
    setCaptchaToken(captcha);
    updateSubmitButtonState(email, dob, captcha);
  }

  const isEmailValid = (newEmail) => {
    return String(newEmail)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const isDobValid = (newDob) => {
    if(dob === ""){
      return false;
    }
    if(isNaN(new Date(newDob))){
      return false;
    }
    return true;
  }

  const areInputsValid = (email, dob) => {
    if(!isEmailValid(email)){
      return false;
    }
    if(!isDobValid(dob)){
      return false;
    }
    return true;
  }

  const isCaptchaValid = (captcha) => {
    if(!captcha){
      return false;
    }
    if(captcha === ""){
      return false;
    }
    return true;
  }

  const updateSubmitButtonState = (username, dob, captcha) => {
    if(areInputsValid(username, dob)){
      setIsSubmitDisabled(false);
    }else{
      setIsSubmitDisabled(true);
    }
  }

  const onEmailUpdate = (newEmail) => {
    setEmail(newEmail);
    if(!isEmailValid(newEmail)){
      setIsEmailValidationVisible(true);
    }else{
      setIsEmailValidationVisible(false);
    }
    updateSubmitButtonState(newEmail, dob, captchaToken);
  }

  const onDobUpdate = (event) => {
    const newDob = event.target.value;

    setDob(newDob);
    if(!isDobValid(newDob)){
      setIsDobValidationVisible(true);
    }else{
      setIsDobValidationVisible(false);
    }
    updateSubmitButtonState(email, newDob, captchaToken);
  } 

  const onDobKeyUp = (event) => {
    const newDob = event.target.value;
    if(!isDobValid(newDob)){
      setIsDobValidationVisible(true);
    }else{
      setIsDobValidationVisible(false);
    }
    updateSubmitButtonState(email, newDob, captchaToken);
  }

  return(
    <div className="container">
      <div className="row">
        <div className="col">
          <form className="form">
          <div className="d-flex">
            <span className="mx-auto font-weight-bold h4 my-2">Forgot Your Username</span>
          </div>
          <div className="d-flex">
            <span className="mx-auto h5">Enter your information below</span>
          </div>
            <div className="form-group">
              <label for="username">Email: </label>
              <input required disabled={isSubmitted} id="email" className="form-control" type={isSubmitted ? "password" : "email"} value={email} placeholder="email@example.com" onBlur={e => onEmailUpdate(e.target.value)} onChange={e => onEmailUpdate(e.target.value)}></input>
              <div className={isEmailValidationVisible ? "text-sm text-danger" : "text-sm text-danger d-none"}>Please enter a valid email.</div>
            </div>
            <div className="form-group">
              <label for="dob">Date of Birth:</label>
              <input required disabled={isSubmitted} id="dob" className="form-control" type={isSubmitted ? "password" : "date"} value={dob} placeholder="mm/dd/yyyy" onKeyUp={e=>onDobKeyUp(e)} onChange={e => onDobUpdate(e)}></input>
              <div className={isDobValidationVisible ? "text-sm text-danger" : "text-sm text-danger d-none"}>Enter a valid date in the format mm/dd.</div>
            </div>
            {/* <div className={"d-none"}>
              {/* <ReCAPTCHA
                sitekey={CAPTCHA_SITE_KEY}
                ref={captchaRef}
                size={"invisible"}
              />
            </div> */} 
            <div className="my-2">
              <p>If you do not have or remember your email, please reach out to the Covered California Service Center.</p>
              <p><strong>Note: </strong>If you do not see an email from donotreply@coveredca.com, try looking in your SPAM or junk folder.</p>
            </div>
            <div className="my-3">
              <button type="button" className="h5 font-weight-bold btn btn-link px-0" onClick={()=>setIsPrivacyStatementExpanded(!isPrivacyStatementExpanded)}>Privacy Statement</button>
              <div style={{color: "#212529"}} className={isPrivacyStatementExpanded ? "d-block" : "d-none"}>
                The information requested on this form is required by Covered California to assist with your request, 
                and the information you provide will be used by Covered California solely for that purpose. Legal 
                references authorizing the collection of the personally-identifiable information through this form 
                include Sections 1798.22, 1798.25, 1798.27 and 1798.35 of the California Civil Code and Section 155.260(a) 
                of the Code of Federal Regulations. California Health Benefits Exchange dba Covered California, 
                1601 Exposition Blvd, Sacramento, CA 95815 (800) 889-3871.
              </div>
            </div>
            <button type="button" disabled={isSubmitDisabled || isSubmitted} className={isSubmitDisabled || isSubmitted ? "btn btn-block btn-disabled rounded my-3" :"btn btn-block ac-pushButton style-default my-3"} onClick={()=>onSubmit()}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EmailDobForgotUsernameAttachment;