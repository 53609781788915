import React from 'react';
import ConnectedAttachmentWithFeedback from './Feedback/ConnectedAttachmentWithFeedback';
import ConnectedAttachmentWithLiveChat from './LiveChat/ConnectedAttachmentWithLiveChat';
import ConnectedAttachmentWithWelcomeMessage from './ArpWelcomeMessage/ConnectedAttachmentWithWelcomeMessage';
import ConnectedAttachmentWithPasscode from './ResetPassword/ConnectedAttachmentWithPasscode';
import ConnectedAttachmentWithPassword from './ResetPassword/ConnectedAttachmentWithPassword';
import ConnectedAttachmentWithUsernameDob from './ResetPassword/ConnectedAttachmentWithUsernameDob';
import ConnectedAttachmentWithOtpType from './ResetPassword/ConnectedAttachementWithOtpType';
import ConnectedAttachmentWithEmailDob from './ResetPassword/ConnectedAttachmentWithEmailDob';
import ConnectedAttachmentWithContactUs from './ResetPassword/ConnectedAttachmentWithContactUs';
import useChatbot from '../../Hooks/useChatbot';

const attachmentMiddleware = () => next => card => {
  const { openPasswordResetWizard, setCurrentPage } = useChatbot();
  const welcomeRole = card.activity.from.role === "user";
  if (card.activity.type !== 'typing' && !welcomeRole && card.activity.hasOwnProperty("entities") && card.activity.entities[0].type === "welcomeMessageARP") {
    return (
      <ConnectedAttachmentWithWelcomeMessage activity={card.activity}>
        {next(card)}
      </ConnectedAttachmentWithWelcomeMessage>
    );
  }


  if (card.attachment && card.attachment.contentType === 'application/liveChatCard') {

    return (
      <ConnectedAttachmentWithLiveChat activity={card.activity}>
        {next(card)}
      </ConnectedAttachmentWithLiveChat>
    );
  }

  if (card.attachment && card.attachment.contentType === 'application/feedbackcard') {

    return (
      <ConnectedAttachmentWithFeedback activity={card.activity}>
        {next(card)}
        </ConnectedAttachmentWithFeedback>
    );
  }


  // For all other cases, proceed with the next middleware
  return next(card);
  
  // switch(card.attachment.contentType) {
  //   case 'application/contactUsCard':
  //     return (
  //       <ConnectedAttachmentWithContactUs activity={card.activity}>
  //         {next(card)}
  //       </ConnectedAttachmentWithContactUs>
  //     );
  //   case 'application/usernameDobCardForgotUsername':
  //     return (
  //       <ConnectedAttachmentWithEmailDob activity={card.activity}>
  //         {next(card)}
  //       </ConnectedAttachmentWithEmailDob>
  //     );
  //   case 'application/feedbackcard':
  //     //console.log(card)
  //     return (
  //       <ConnectedAttachmentWithFeedback activity={card.activity}>
  //         {next(card)}
  //       </ConnectedAttachmentWithFeedback>
  //     );
  //   case 'application/usernameDobCard':
  //     return (
  //       <ConnectedAttachmentWithUsernameDob activity={card.activity}>
  //         {next(card)}
  //       </ConnectedAttachmentWithUsernameDob>
  //     );

  //   case 'application/otpTypeCard':
  //     return (
  //       // openPasswordResetWizard(),
  //       //  setCurrentPage(2),
  //       <ConnectedAttachmentWithOtpType activity={card.activity}>
  //         {next(card)}
  //       </ConnectedAttachmentWithOtpType>
  //     );
  //   case 'application/passcodeCard':
  //     return (
  //       <ConnectedAttachmentWithPasscode activity={card.activity}>
  //         {next(card)}
  //       </ConnectedAttachmentWithPasscode>
  //     );
  //   case 'application/passwordCard':
  //     return (
  //       <ConnectedAttachmentWithPassword activity={card.activity}>
  //         {next(card)}
  //       </ConnectedAttachmentWithPassword>
  //     );
  //   case 'application/liveChatCard':
  //     //console.log(card)
  //     return (
  //       <ConnectedAttachmentWithLiveChat activity={card.activity}>
  //         {next(card)}
  //       </ConnectedAttachmentWithLiveChat>
  //     );   
  //   case 'application/vnd.microsoft.card.adaptive':
  //     // if(card.attachment.content.actions[0].data.title !== "helpOnDemand")
  //     // {
  //     //   return next(card);
  //     // }
  //     return next(card);
  //   default:
  //     return next(card);
  // }
};

export default attachmentMiddleware;