import React, { useState, forwardRef } from 'react';
import styles from './Input.module.css';
import ShowPassword from '../../../Images/ShowPassword.svg'; 

const Input = forwardRef(
  ({ placeholder, type = "text", value, onChange, isValid, style, maxLength, checked }, ref) => {
    const [inputType, setInputType] = useState(type);

    const togglePasswordVisibility = () => {
      setInputType(inputType === 'password' ? 'text' : 'password');
    };

    const fontStyleClass = value ? styles['input__text--normal'] : styles['input__text--italic'];
    const classNames = `${styles.input__text} ${fontStyleClass} ${isValid === undefined || isValid ? '' : styles['input__text--invalid']}`;

    return (
      <div style={{ position: 'relative' }}>
        <input
          className={classNames}
          type={inputType}
          placeholder={type === "checkbox" ? undefined : placeholder}
          onChange={onChange}
          value={type === "checkbox" ? undefined : value}
          style={style}
          maxLength={maxLength}
          checked={type === "checkbox" ? checked : undefined}
          ref={ref}  
        />
        {type === 'password' && (
          <img
            src={ShowPassword}
            alt="Show Password"
            onClick={togglePasswordVisibility}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer'
            }}
          />
        )}
      </div>
    );
  }
);

export default Input;
