import React, { useState } from 'react';

function PasscodeAttachment(props){
  const [passcode, setPasscode] = useState("");
  const [isDisabled, setIsDisabled]=useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [useExpiredToken, setUseExpiredToken] = useState(false);
  const [isUsedExpiredTokenVisible, setIsUsedExpiredTokenVisible] = useState(false);

  const onSubmit = (passcode) => {
    props.postActivity({
      type: 'message',
        value: {
          type: 'forgotPasswordCardClick',
          passcode: passcode,
          intentName: 'forgotPassword',
          useExpiredToken: useExpiredToken
        }
    }); 
    setIsDisabled(true);
  }

  const isPasscodeValid = (passcode) => {
    if(passcode && passcode !== ""){
      return true;
    }
    return false;
  }

  const onPasscodeChange = (newPasscode) => {
    setPasscode(newPasscode);
    if(isPasscodeValid(newPasscode)){
      setIsSubmitDisabled(false);
    }else{
      setIsSubmitDisabled(true);
    }
  }

  const userPreferredOtpType = props.activity.attachments[0].content.data.userPreferredOtpType === "phone" ? "text message" : "email";

  return(
    <div className="container">
      <div className="row">
        <div className="col">
          <p>
            To confirm your identity, enter the one-time passcode you received in your { userPreferredOtpType }.
          </p>
          <form className="form" onSubmit={ e => e.preventDefault() }>
            <div className="form-group">
              <input disabled={isDisabled} className="form-control" type="password" onChange={e => onPasscodeChange(e.target.value)}></input>
            </div>
            {isUsedExpiredTokenVisible ? 
              <div>
                <input
                    id="use-expired-token"
                    type="checkbox"
                    checked={useExpiredToken}
                    onChange={()=>setUseExpiredToken(!useExpiredToken)}
                />
                <label for="use-expired-token">use expired token</label>
              </div>
            : null}
            <button disabled={isSubmitDisabled || isDisabled} type="button" className={isSubmitDisabled || isDisabled ? "btn btn-block btn-disabled rounded my-3" :"btn btn-block ac-pushButton style-default my-3"} onClick={()=>onSubmit(passcode)}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default PasscodeAttachment;