import { useContext } from "react";
import { ChatbotContext } from "../Contexts/ChatbotContext";
import useChatbot from "./useChatbot";
import { EnrollmentStatusContex } from "../Contexts/EnrollmentStatusContext";
import useEnrollmentStatusContext from "./useEnrollmentStatusContext";
import useWebChat from "./useWebChat";


function useEnrollmentStatus() {
  const {
    currentPage,
    setCurrentPage,
    dob,
    setDob,
    ssnPart1,
    setSsnPart1,
    ssnPart2,
    setSsnPart2,
    ssnPart3,
    setSsnPart3,
    zip,
    setZip,
    closeModal, 
    setCloseModal,
    helpModal,
    setHelpModal,
    offBusinessHoursHelpModal,
    setOffBusinessHoursHelpModal,
    EnrollmentStatusWizardEventData,
    setEnrollmentStatusWizardEventData,
    coverageYear,
    setCoverageYear,
    IsEnrollmentStatusMenuOpen,
    setIsEnrollmentStatusMenuOpen,
} = useEnrollmentStatusContext();
  
   const chatbotHook = useChatbot();

  const { enrollmentStatusEvent } = useContext(ChatbotContext);
  const { setIsEnrollmentStatusWizardOpen } = useContext(ChatbotContext);
  const { sendMessage } = useWebChat();

  const next = () => {
    // if (currentPage > 1) return;
   // console.log("Current Page", currentPage);
    setCurrentPage(currentPage + 1);
  };

  const previous = () => {
    if (currentPage === 0) return;
    setCurrentPage(currentPage - 1);
  };

  const closeEs = (currentLanguage) => {
  //  console.log("use enrollment close");
    setIsEnrollmentStatusWizardOpen(false);
    sendMessage(currentLanguage === "es" ? 'Mi cuenta de Covered California' : 'My Covered California account')
    
  };

  const updateCoverageYear = (year) => {
    setCoverageYear(year);
  };

  const openFixedCloseModal = () => {
    setCloseModal({isOpen: true, type: "fixed"});
};
const openRelativeCloseModal = () => {
    setCloseModal({isOpen: true, type: "relative"});
};
const closeCloseModal = () => {
    setCloseModal({isOpen: false, type: null});
}

const openFixedHelpModal = () => {
  setHelpModal({isOpen: true, type: "fixed"});
};
const openRelativeHelpModal = () => {
  setHelpModal({isOpen: true, type: "relative"});
};

const closePhoneSupportModal = () => {
  setHelpModal({isOpen: false, type: null});
}

const openFixedOffHoursHelpModal = () => {
  setOffBusinessHoursHelpModal({isOpen: true, type: "fixed"});
};
const openRelativeOffHoursHelpModal = () => {
  setOffBusinessHoursHelpModal({isOpen: true, type: "relative"});
};

const closePhoneSupportOffHoursModal = () => {
  setOffBusinessHoursHelpModal({isOpen: false, type: null});
}

const toggleMenu = () => {
  //console.log("toggleMenu", IsEnrollmentStatusMenuOpen);
  setIsEnrollmentStatusMenuOpen(!IsEnrollmentStatusMenuOpen);
}  


  return {
    closeEs,
    currentPage,
    setCurrentPage,
    next,
    previous,
    dob,
    setDob,
    ssnPart1,
    setSsnPart1,
    ssnPart2,
    setSsnPart2,
    ssnPart3,
    setSsnPart3,
    zip,
    setZip,
    enrollmentStatusEvent,
    setEnrollmentStatusWizardEventData,
    EnrollmentStatusWizardEventData,
    closeModal,
    closeCloseModal,
    openFixedCloseModal,
    openRelativeCloseModal,
    helpModal,
    openFixedHelpModal,
    openRelativeHelpModal,
    closePhoneSupportModal,
    openFixedOffHoursHelpModal,
    openRelativeOffHoursHelpModal,
    closePhoneSupportOffHoursModal,
    offBusinessHoursHelpModal,
    coverageYear,
    setCoverageYear,
    updateCoverageYear,
    toggleMenu,
    IsEnrollmentStatusMenuOpen,
    setIsEnrollmentStatusMenuOpen,
     ...chatbotHook
  };
}

export default useEnrollmentStatus;
