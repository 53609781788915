
import React from 'react';
import useEnrollmentStatus from "../../Hooks/useEnrollmentStatus";
import { useLanguage } from '../../UiKit/Wizard/langugeContext';
import translations from './translation';
import PageLayout from '../../UiKit/Wizard/Page/PageLayout';
import SubHeader from '../../UiKit/Wizard/SubHeader/SubHeader';
import PlanCard from '../../UiKit/Wizard/EnrollmentStatusPlanDetails/EnrollmentStatusPlanDetails';

import NavigationButton from '../../UiKit/Wizard/NavigationButton/NavigationButton';
import WizardLayout from '../../UiKit/Wizard/WizardLayout/WizardLayout';
import styles from '../../UiKit/Wizard/WizardLayout/WizardLayout.module.css';
import { Button } from 'bootstrap';

function EnrollmentStatusResults( props ) {
  const { next, previous, closeEs } = useEnrollmentStatus();
  const { currentLanguage } = useLanguage();
  const { data, selectedYear, onNext, sendMessage, on } = props;
  const EnrollmentStatustResultTranslation = translations[currentLanguage]["EnrollmentStatusResults"];
  const buttonTranslations = translations[currentLanguage]["button"];

  const getDetails = selectedYear
    ? {
        [selectedYear]:
          (data.getDetails && data.getDetails[selectedYear]) || {},
      }
    : data.getDetails || {};

  const handleNext = () => {
    onNext();
  } 

  const handleExit = () => {
    closeEs(currentLanguage);
           
    }


  return (
    <>
      <WizardLayout.Content>
        <PageLayout>
          <PageLayout.Header style={{ marginBottom: "-17px" }}>
            <SubHeader subHeader={EnrollmentStatustResultTranslation.Title} />
          </PageLayout.Header>
          <PageLayout.Content className="wizard__content" style={{ marginTop: "32px" }}>
            {Object.entries(getDetails).map(([year, details]) => (
              <div key={year}>
                {details.Health.map((healthPlan, index) => (
                  <div key={index}>
                    <PlanCard
                      year={year}
                      enrollmentStatus={healthPlan.enrollmentStatus}
                      enrollmentStartDate={healthPlan.enrollmentBenefitEffectiveStartDate}
                      enrollmentEndDate={healthPlan.enrollmentBenefitEffectiveEndDate}
                      planDetails={healthPlan.planName}
                      carrierName={healthPlan.carrierName}
                      healthHouseholdMembers={healthPlan.memberCount}
                      lang={currentLanguage}
                      planType="Health"
                      totalPlans={details.Health.length}
                      index={index}
                      premium={healthPlan.netPremiumAmount}
                      data={data}
                      translations={EnrollmentStatustResultTranslation.PlanCard}
                    />
                  </div>
                ))}

                {details.Dental.map((dentalPlan, index) => (
                  <div key={index}>
                    <PlanCard
                      year={year}
                      enrollmentStatus={dentalPlan.enrollmentStatus}
                      enrollmentStartDate={dentalPlan.enrollmentBenefitEffectiveStartDate}
                      enrollmentEndDate={dentalPlan.enrollmentBenefitEffectiveEndDate}
                      planDetails={dentalPlan.planName}
                      carrierName={dentalPlan.carrierName}
                      dentalHouseholdMembers={dentalPlan.memberCount}
                      lang={currentLanguage}
                      planType="Dental"
                      totalPlans={details.Dental.length}
                      index={index}
                      premium={dentalPlan.netPremiumAmount}
                      data={data}
                      translations={EnrollmentStatustResultTranslation.PlanCard}
                    />
                  </div>
                ))}
                <div style={{ marginBottom: "40px" }}></div>
              </div>
            ))}
          </PageLayout.Content>
        </PageLayout>
      </WizardLayout.Content>
      <div style={{ position: "relative" }}>
        <div className={`${styles.overlaybottom}`}></div>
      </div>
      <WizardLayout.Navigation>
        <NavigationButton onClick={handleExit} type={`primary`}>
          {buttonTranslations.Exit}
        </NavigationButton>
        <NavigationButton onClick={handleNext} type={"secondary"}>
          {buttonTranslations.ProvideFeedback}
        </NavigationButton>
      </WizardLayout.Navigation>
    </>
  );
}

export default EnrollmentStatusResults;
