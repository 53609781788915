import React from 'react';
import {connectToWebChat} from 'botframework-webchat';
import PasswordAttachment from "./PasswordAttachment";

const ConnectedAttachmentWithPasscode = connectToWebChat(
  ({ postActivity }) => ({ postActivity })
)(props => 
<PasswordAttachment {...props} />
)

export default ConnectedAttachmentWithPasscode;