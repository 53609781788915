import React, { useContext } from 'react';
import HeaderLayout from '../../UiKit/Chatbot/Header/HeaderLayout';
import HeaderCloseButton from '../../UiKit/Chatbot/Header/HeaderCloseButton/HeaderCloseButton';
import HeaderMenuToggle from '../../UiKit/Chatbot/Header/HeaderMenuToggle/HeaderMenuToggle';
import useAffordabilityTool from '../../Hooks/useAffordabilityTool';
import HeaderTitle from '../../UiKit/Chatbot/Header/HeaderTitle/HeaderTitle'
import useAffordabilityToolInApp from '../../Hooks/useAffordabilityToolInApp';
import { ChatbotContext } from '../../Contexts/ChatbotContext';
import useChatbot from '../../Hooks/useChatbot';

function AffordabilityToolInAppHeader() {
    const { toggleAffordabilityToolMenu, close } = useAffordabilityTool();
    const { setIsMinimized } = useContext(ChatbotContext)
    const {toggleChatbotMenu} = useChatbot();
    return (
        <HeaderLayout>
            <HeaderLayout.MenuToggle>
                <HeaderMenuToggle onClick={() => toggleChatbotMenu()} />
            </HeaderLayout.MenuToggle>
            <HeaderLayout.Title>
                <HeaderTitle />
            </HeaderLayout.Title>
            <HeaderLayout.CloseButton>
                <HeaderCloseButton
                    onClick={() => setIsMinimized(true)}
                />
            </HeaderLayout.CloseButton>
        </HeaderLayout>
    )
}

export default AffordabilityToolInAppHeader;