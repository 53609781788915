import React, { useContext } from 'react';
import HeaderLayout from '../../UiKit/Chatbot/Header/HeaderLayout';
import HeaderCloseButton from '../../UiKit/Chatbot/Header/HeaderCloseButton/HeaderCloseButton';
import HeaderMenuToggle from '../../UiKit/Chatbot/Header/HeaderMenuToggle/HeaderMenuToggle';
import HeaderTitle from '../../UiKit/Chatbot/Header/HeaderTitle/HeaderTitle'
import usePasswordReset from '../../Hooks/usePasswordReset';
import { ChatbotContext } from '../../Contexts/ChatbotContext';
import useChatbot from '../../Hooks/useChatbot';

function PasswordResetHeader() {
    const { togglePasswordResetMenu, closePasswordReset} = usePasswordReset();
    const { setIsMinimized } = useContext(ChatbotContext)
    const {toggleChatbotMenu} = useChatbot();
    return (
        <HeaderLayout>
            <HeaderLayout.MenuToggle>
            <HeaderMenuToggle onClick={() => toggleChatbotMenu()} />
            </HeaderLayout.MenuToggle>
            <HeaderLayout.Title>
                <HeaderTitle />
            </HeaderLayout.Title>
            <HeaderLayout.CloseButton>
                <HeaderCloseButton
                    onClick={() => setIsMinimized(true)}
                />
            </HeaderLayout.CloseButton>
        </HeaderLayout>
    )
}

export default PasswordResetHeader;