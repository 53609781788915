// PersonStatus.js
import React from 'react';
import Edit from '../Edit/Edit';
import './PersonStatus.css'; 
import CheckIcon  from '../../../Images/Keep.svg';
import CrossIcon from '../../../Images/Cancel.svg';
import { useLanguage } from '../langugeContext';
import { translations } from '../../../Components/M2CWizard/translations';

const PersonStatus = ({ name, age, status, onEdit }) => {
    const { currentLanguage } = useLanguage();
    const PersonStatusTranslations = translations[currentLanguage].ReviewAndConfirmPage.PersonStatus;
    const isKeepingPlan = status === "keep";
    return (
        <div className="person-status">
            <div className="person-details">
                <div className="person-info">
                    <p className="person-name">{name}</p>
                </div>
            </div>
            <Edit onClick={onEdit}>{PersonStatusTranslations.Edit}</Edit>
        </div>
    );
};

export default PersonStatus;