import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import ReactInputMask from 'react-input-mask';
import sendEventToDataLayer from '../../../Services/datalayer-event-logger-for-transactions/sendEventToDataLayer';

const CAPTCHA_SITE_KEY = process.env.NODE_ENV === 'development' ? "6Lea14UkAAAAADFKcSV8372FNO5CoTEy4RLktKjW" : "6Ld32mQkAAAAAJDLX9kgVM56Zl8-IgOj3Xm9GawQ";

function UsernameDobAttachment(props){
  const [username, setUsername ] = useState("");
  const [dob, setDob] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isCaptchaHidden, setIsCaptchaHidden] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUsernameValidationVisible, setIsUsernameValidationVisible ] = useState(false);
  const [isDobValidationVisible, setIsDobValidationVisible] = useState(false);
  const [isPrivacyStatementExpanded, setIsPrivacyStatementExpanded] = useState(false);
  const captchaRef = useRef();

  const onSubmit = async () => {
    const token = await captchaRef.current.executeAsync();
    // console.log("token generated: ****"+token.substring(token.length-5, token.length-1));
    props.postActivity({
      type: 'message',
        value: {
          username: username,
          dob: dob,
          captchaToken: token,
          type: 'forgotPasswordCardClick',
          intentName: 'forgotPassword'
        }
    }); 
    setIsCaptchaHidden(true);
    setIsSubmitted(true);
  }

  const onForgotUsername = () => {
    sendEventToDataLayer({
      event: "chatbot",
      eventDetails: {
        category: "CiCi",
        action: "Password Reset Flow",
        label: "'Forgot Username' Link Click"
      }
    });
    props.postActivity({
      type: 'message',
        value: {
          intentName: 'forgotUsername'
        }
    });
    setIsSubmitted(true);
  }

  const onCaptchaChange = (newCaptcha) => {
    setCaptchaToken(newCaptcha);
    updateSubmitButtonState(username, dob, newCaptcha);
  }

  const isUsernameValid = (username) => {
    if(!username || username === ""){
      return false;
    }
    return true;
  }

  const isDobValid = (dob) => {
    if(dob === ""){
      return false;
    }
    if(dob === "__/__"){
      return false;
    }
    if(isNaN(new Date(dob+"/2022"))){
      return false;
    }
    return true;
  }

  const isCaptchaValid = (captcha) => {
    if(!captcha){
      return false;
    }
    if(captcha === ""){
      return false;
    }
    return true;
  }

  const areInputsValid = (username, dob) => {
    if(!isUsernameValid(username)){
      return false;
    }
    if(!isDobValid(dob)){
      return false;
    }
    return true;
  }

  const updateSubmitButtonState = (username, dob, captcha) => {
    if(areInputsValid(username, dob)){
      setIsSubmitDisabled(false);
    }else{
      setIsSubmitDisabled(true);
    }
  }

  const onUsernameUpdate = (newUsername) => {
    setUsername(newUsername);
    if(!isUsernameValid(newUsername)){
      setIsUsernameValidationVisible(true);
    }else{
      setIsUsernameValidationVisible(false);
    }
    updateSubmitButtonState(newUsername, dob, captchaToken);
  }

  const onDobUpdate = (event) => {
    const newDob = event.target.value;
    setDob(newDob);
    if(!isDobValid(newDob)){
      setIsDobValidationVisible(true);
    }else{
      setIsDobValidationVisible(false);
    }
    updateSubmitButtonState(username, newDob, captchaToken);

  } 


  // should only run once
  useEffect(()=>{
    localStorage.setItem("conversationMayContainPii", true);
  },[]);

  return(
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="d-flex">
            <span className="mx-auto font-weight-bold h4 my-2">Forgot Your Password</span>
          </div>
          <form className="form">
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input disabled={isSubmitted} id="username" className="form-control" type={isSubmitted ? "password" : "text"} value={username} placeholder="Username" onBlur={e => onUsernameUpdate(e.target.value)} onChange={e => onUsernameUpdate(e.target.value)}></input>
              <div className={isUsernameValidationVisible ? "text-sm text-danger" : "text-sm text-danger d-none"}>Username: Cannot be empty.</div>
              <button disabled={isSubmitted} type="button" className={isSubmitted ? "btn btn-disabled rounded my-3" : "btn btn-link pl-0"} onClick={()=>onForgotUsername()}>Forgot Username?</button>
            </div>
            <div className="form-group">
              <label htmlFor="dob">Month and Day of Birth:</label>
              <ReactInputMask 
                disabled={isSubmitted} 
                mask="99/99" 
                maskChar="_" 
                id="dob" 
                className="form-control" 
                type={isSubmitted ? "password" : "text"} 
                placeholder="MM/DD" 
                onChange={onDobUpdate}
              />
              <div className={isDobValidationVisible ? "text-sm text-danger" : "text-sm text-danger d-none"}>Enter a valid date in the format mm/dd.</div>
            </div>
            <div className={"d-none"}>
              <ReCAPTCHA
                sitekey={CAPTCHA_SITE_KEY}
                ref={captchaRef}
                size="invisible"
              />
            </div>
            <div className="my-3">
              <button type="button" className="h5 font-weight-bold btn btn-link px-0" onClick={()=>setIsPrivacyStatementExpanded(!isPrivacyStatementExpanded)}>Privacy Statement</button>
              <div style={{color: "#212529"}} className={isPrivacyStatementExpanded ? "d-block" : "d-none"}>
                The information requested on this form is required by Covered California to assist with your request, 
                and the information you provide will be used by Covered California solely for that purpose. Legal 
                references authorizing the collection of the personally-identifiable information through this form 
                include Sections 1798.22, 1798.25, 1798.27 and 1798.35 of the California Civil Code and Section 155.260(a) 
                of the Code of Federal Regulations. California Health Benefits Exchange dba Covered California, 
                1601 Exposition Blvd, Sacramento, CA 95815 (800) 889-3871.
              </div>
            </div>
            <button disabled={isSubmitDisabled || isSubmitted} type="button" className={isSubmitDisabled || isSubmitted ? "btn btn-block btn-disabled rounded my-3" :"btn btn-block ac-pushButton style-default my-3"} onClick={()=>onSubmit()}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UsernameDobAttachment;