import KeepConfirmationPage from "./KeepConfirmationPage";
import NoContactSupportPage from "./NoAPSContactSupportPage";
import ServiceErrorPage from "./ServiceErrorPage";
import TimeoutPage from "./TimeoutPage";
import React from "react";

const Es = "es";
const En = "en";

export const translations = {
  en: {
        "RetunToChatButton": "Return to chat",
        "Close": "Close",
        "Help": "Help",
        "PhoneSupport": "Phone Support",
        "OffBusinessHours": "Off Business Hours",
        SHOW_USER_NOT_FOUND: "Account could not be found. Try again or call (855) 708-4590 for help.",
        NavButton:{
            "BackButton": "Back",
            "NextButton": "Next",
            "Confirm": "Confirm",
            "Close": "Close",
            "Cancel": "Cancel",
            "ExitButton": "Exit",
            "ProvideFeedbackButton": "Provide Feedback",
            "BeginButton": "Begin",
            "StartOverButton": "Start over",
            "SubmitButton": "Submit"
        },
        WelcomePage: {
            "Title": "Your Coverage, Your Choice",
            "Body": "In a few easy steps, ",
            "Body1" :"we can help you keep or cancel your plan. ",
            "alt": "Medi-Cal and Covered California Logos"
        },
        AuthenticationPage: {
            "Title": "Confirm Your Identity",
            "DOB": "Date of Birth",
            "DOBPlaceholder": "mm/dd/yyyy",
            "DOBVaildation": "Enter a valid date of birth.",
            "SSN": "Social Security Number (SSN)",
            "SSNPlaceholder1": "123",
            "SSNPlaceholder2": "45",
            "SSNPlaceholder3": "6789",
            "SSNValidation": "Enter your Social Security number.",
            "ZIP": "ZIP Code",
            "ZIPPlaceholder": "Enter ZIP Code",
            "ZIPValidation": "Enter a valid ZIP code.",
            "alt": "Medi-Cal and Covered California Logos"
        },
        KeepOrCancelPage: {
            "Title": "Keep or Cancel Coverage",
            "InputValidationMessage": "Please make a selection.",
            "Body": "For household members no longer eligible for Medi-Cal.",
            PlanDetails:{
                "WhatwillyouPay": "What You’ll Pay",
                "Monthly": "mo",
                "Group": "Group",
                "Tooltip": "This is the total monthly dollar amount for all people in this group.",
                "PlanName": "Plan Name"
            },
            PersonSelection:{
                "Keep": "Keep",
                "Cancel": "Cancel",
                "Years": "yrs"
            },
            Note: <><a 
                    href="https://apply.coveredca.com/static/lw-web/login"
                    style={{
                    color: "var(--CalHEERS-Main-Blue, #2D77A3)",
                    textDecoration: "underline",
                    }}
            target="_blank"
            rel="noopener noreferrer"
           >Log in</a> to change your plan or make person-by-person coverage choices.</>
            
        },
        AgreePlanPage: {
            "SubHeader": "By accepting, you agree to the plan and financial help terms.",
            "AcceptTerms": "I accept the Covered California Terms & Conditions.",
            "Terms":"Agree and continue",
            "Details": "For details, refer to Terms & Conditions in the letter you received from Covered California."
        },    
        SourceOfHealthCoveragePage: {
           "SubHeader": "How will people canceling get their health coverage?",
           "Other": "Enter Reason",
           "Error":"Please enter a reason for cancelation."
        },
        ReviewAndConfirmPage:{
            SubHeader:"Review and Confirm",
            Group:"Plan Name",
            AdjustPlanDetails:{
                "WhatYouWillPay": "What You’ll Pay",
                "Monthly": "mo",
                "Tooltip": "This is the total monthly dollar amount for all people in this group."
            },
            PersonStatus:{
                "Keep": "Keep plan",
                "Cancel": "Cancel plan",
                "Edit": "Edit",
                "Years": "yrs"
            }


        },
        KeepOrCancelConfirmationPage:{
            SubHeader:"Account Updated",
            Body:"We’ll send you a letter to confirm your choices.",
            PeopleKeepingTheirPlan:"People Keeping Their Plan",
            PaymentReminder:"Payment Reminder",
            PaymentReminderMessage:"Pay your first bill to start coverage.",
            SignIntoYourAccount:"Sign in",
            SignIntoYourAccountMessage:" to check your Enrollment Dashboard for more details. You will also get more information from your health insurance company.",
            PeopleCancellingTheirPlan:"People Canceling Their Plan",
            PeopleCancellingTheirPlanMessage1:"If you change your mind, you can enroll until the end of your ",
            PeopleCancellingTheirPlanMessage2: "special enrollment period",

        },
        KeepConfirmationPage:{
            SubHeader:"Stay covered, pay today.",
            Body:"We’ll send you a letter to confirm your choices.",
            PeopleKeepingTheirPlan:"People Keeping Their Plan",
            PaymentReminder:"Payment Reminder",
            PaymentReminderMessage:<><a
            href="https://apply.coveredca.com/static/lw-web/login"
            style={{
              color: "var(--CalHEERS-Main-Blue, #2D77A3)",
              textDecoration: "underline",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >Sign</a> in to make your first payment.</>,
            SignIntoYourAccount:"Sign in",
            SignIntoYourAccountMessage: <>You can also wait for your health insurance company to send a bill. The <b>plan will be canceled unless you pay </b> by the end of your first month.</>,
        },
        CancelConfirmation: {
            SubHeader: "Plan Canceled",
            Body: "We’ll send you a letter to confirm your choices.",
            PeopleCancellingTheirPlanMessage:"If you change your mind, you can enroll until the end of your",
            PeopleCancellingTheirPlanMessage1: " special enrollment period",

        },
        FeedbackPage:{
            Title:"How did we do?",
            OptionalMessage:"Optional: Tell us about your experience"

        },
        ThankYouPage:{
            Title:"Thank you!",
            Body:"Your feedback means a lot! With your help, we can make our services even better for you.",
            alt:"Thank you"
        },
        ContactSupportPage: {
            Title: "A representative will need to help manage your move.",
            Body: "Your new plan will be canceled unless you take action. <a href='https://covered-ca.my.salesforce-sites.com/chat/?lang=en_US'>Chat online</a> or call to speak to receive free help from a representative.",
            alt: "Contact Support",
            Title1: "Covered California Service Center",
            PhoneNumber: "(855) 708-4590",
            Hours: "Monday to Friday: 8 a.m. to 6 p.m.",
        },
        ServiceErrorPage: {
            HighlightMessage:"Something went wrong.",
            Message:"Sorry, but we’re not able to complete this request right now. Please try again later"
        },
        TimeoutPage: {
            Message: "Your session has expired. To continue, click “Start over” below.",
        },
        NoContactSupportPage:{
            Title: "Contact support",
            Body: "Please contact a service center representative for assistance.",
            Body1: "You can  <a href='https://covered-ca.my.salesforce-sites.com/chat/?lang=en_US'>Chat with a representative </a> or call at",
            alt: "Contact Support",
            Title1: "Service Center Hours",
            PhoneNumber: "(855) 708-4590",
            Hours: "Monday to Friday: 8 a.m. to 6 p.m.",
        },
        KeepConfirmZeroPremium: {
            Title: "You’re covered. ",
            Body1: "We’ll send you a letter to confirm your choice.",
            Body2: <><a
                        href="https://apply.coveredca.com/static/lw-web/login"
                        style={{
                        color: "var(--CalHEERS-Main-Blue, #2D77A3)",
                        textDecoration: "underline",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >Sign in</a> to check your Enrollment Dashboard for more details. You will also get more information from your health insurance company.</>,
                        
                },
  },
    es: {
        "RetunToChatButton": "Volver al chat",
        "Close": "Cerrar",
        "Help": "Ayuda",
        "PhoneSupport": "Soporte telefónico",
        "OffBusinessHours": "Fuera del horario de atención",
        SHOW_USER_NOT_FOUND:"No se pudo encontrar la cuenta. Vuelve a intentarlo o llama al (855) 708-4590 para obtener ayuda",
        WelcomePage: {
            "Title": "Tu cobertura, tu decisión",
            "Body": "En unos sencillos pasos,",
            "Body1": "te podemos ayudar a mantener o cancelar tu plan.",
            "alt": "Logotipos de Medi-Cal y Covered California"
        },
        AuthenticationPage: {
            "Title": "Confirma tu identidad",
            "DOB": "Fecha de nacimiento",
            "DOBPlaceholder": "mm/dd/aaaa",
            "DOBVaildation": "Ingresa una fecha de nacimiento válida.",
            "SSN": "Número de Seguro Social (SSN)",
            "SSNPlaceholder1": "123",
            "SSNPlaceholder2": "45",
            "SSNPlaceholder3": "6789",
            "SSNValidation": "Ingresa un número de Seguro Social válido.",
            "ZIP": "Código postal",
            "ZIPPlaceholder": "Ingresa un código postal",
            "ZIPValidation": "Ingresa un código postal válido.",
            "alt": "Logotipos de Medi-Cal y Covered California"
        },
        NavButton:{
            "BackButton": "Volver",
            "NextButton": "Siguiente",
            "SubmitButton": "Enviar",
            "Confirm": "Confirmar",
            "Close": "Cerrar",
            "Cancel": "Cancelar",
            "BeginButton": "Comenzar",
            "ExitButton": "Salir",
            "ProvideFeedbackButton": "Dame tus comentarios",
            "StartOverButton": "Comenzar de nuevo"
        },
        KeepOrCancelPage: {
            "Title": "Mantener o cancelar cobertura",
            "InputValidationMessage": "Por favor haz una selección",
            "Body": "Para los miembros del hogar que ya no son elegibles para Medi-Cal.",
            PlanDetails:{
                "WhatwillyouPay": "Lo que pagarás",
                "Monthly": "mes",
                "Group": "Nombre del plan",
                "Tooltip": "Esta es la cantidad total mensual en dólares para todas las personas de este grupo.",
                "PlanName": "Nombre del plan"
            },
            PersonSelection:{
                "Keep": "Mantener",
                "Cancel": "Cancelar",
                "Years": "años"
            },
            Note: <><a 
                    href="https://apply.coveredca.com/static/lw-web/login"
                    style={{
                    color: "var(--CalHEERS-Main-Blue, #2D77A3)",
                    textDecoration: "underline",
                    }}
            target="_blank"
            rel="noopener noreferrer"
           >Inicia sesión</a> para cambiar tu plan o tomar decisiones de cobertura para cada persona.</>
        },
        AgreePlanPage: {
            "SubHeader": "Al aceptar, tú aceptas los términos del plan y ayuda financiera.",
            "AcceptTerms": "Acepto los Términos y condiciones de Covered California.",
            "Terms":"Aceptar y continuar",
            "Details": "Para más detalles, consulta los Términos y condiciones en la carta que recibiste de Covered California."
        },
        SourceOfHealthCoveragePage: {
            "SubHeader": "¿Cómo obtendrán cobertura de salud las personas que cancelan?",
            "Other": "Ingresa el motivo",
            "Error":"Por favor selecciona una razón de cancelación."
        },
        ReviewAndConfirmPage:{
            SubHeader:"Revisa y confirma",
            Group:"Nombre del plan",
            AdjustPlanDetails:{
                "WhatYouWillPay": "Lo que pagarás",
                "Monthly": "mes",
                "Tooltip": "Esta es la cantidad total mensual en dólares para todas las personas de este grupo.",
            },
            PersonStatus:{
                "Keep": "Mantener el plan",
                "Cancel": "Cancelar el plan",
                "Edit": "Cambiar",
                "Years": "años",

        },
    },
        KeepOrCancelConfirmationPage:{
            SubHeader:"La cuenta fue actualizada",
            Body:"Te enviaremos una carta para confirmar tu selección.",
            PeopleKeepingTheirPlan:"Personas que mantienen su plan",
            PaymentReminder:"Recordatorio de pago",
            PaymentReminderMessage:" Paga tu primera factura para comenzar la cobertura.",
            SignIntoYourAccount:"Inicia sesión",
            SignIntoYourAccountMessage:" para revisar tu Tablero de inscripción para más detalles. Tambien recibiras más información de tu compañía de seguro de salud.",
            PeopleCancellingTheirPlan:"Personas que cancelan su plan",
            PeopleCancellingTheirPlanMessage:"Tienes hasta el final de tu ",
         PeopleCancellingTheirPlanMessage1: "Si cambias de opinión, te puedes inscribir hasta el final de tu",
            PeopleCancellingTheirPlanMessage2:" periodo de inscripción especial"
        },
        KeepConfirmationPage:{
            SubHeader:"Manténte cubierto, paga hoy.",
            Body:"Te enviaremos una carta para confirmar tu selección.",
            PeopleKeepingTheirPlan:"Personas que mantienen su plan",
       
            
            PaymentReminder:"Recordatorio de pago",
            PaymentReminderMessage:<><a
            href="https://apply.coveredca.com/static/lw-web/login"
            style={{
              color: "var(--CalHEERS-Main-Blue, #2D77A3)",
              textDecoration: "underline",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >Inicia sesión</a> para hacer to primer pago.</>,
            SignIntoYourAccount:"Sign in",
            SignIntoYourAccountMessage: <>También puedes esperar a que tu compañía de seguro de salud te envíe una factura. El  <b>plan se cancelará a menos que pagues</b> al final de tu primer mes.</>,
        },
        CancelConfirmation: {
            SubHeader: "Plan cancelado",
            Body: "Te enviaremos una carta para confirmar tu selección.",
            PeopleCancellingTheirPlanMessage:"Si cambias de opinión, te puedes inscribir hasta el final de tu",
            PeopleCancellingTheirPlanMessage1: " período de inscripción especial",
        },
        FeedbackPage:{
            Title:"¿Cómo fue tu experiencia hoy?",
            OptionalMessage:"Opcional: Cuéntanos más sobre tu experiencia."
        },
        ThankYouPage: {
            Title: "¡Gracias!",
            Body: "¡Tus comentarios significan mucho! Con tu ayuda, podemos hacer que nuestros servicios sean aún mejores para ti.",
            alt: "Gracias"
        },
        ContactSupportPage: {
            Title: "Un representante del centro de servicio te ayudará a administrar tu cambio.",
            Body: "Tu plan nuevo se cancelará si no tomas acción. <a href='https://covered-ca.my.salesforce-sites.com/chat/?lang=es_US'>Chatea en línea</a>  o llama para recibir ayuda gratis de un representante.",
            alt: "Contactar soporte",
            Title1: "Centro de Servicio de Covered California",
            PhoneNumber: "(855) 708-4590",
            Hours: "Lunes a viernes: 8 a.m. a 6 p.m.",
        },
        ServiceErrorPage: {
            HighlightMessage:"Algo salió mal.",
            Message:"Lo sentimos, pero no podemos completar esta solicitud en este momento. Por favor, inténtalo de nuevo más tarde."
        },
        TimeoutPage: {
            Message: "Tu sesión expiró. Para continuar, haz clic en 'Comenzar de nuevo' a continuación.",
        },
        NoContactSupportPage:{
            Title: "CComunícate con el Centro de apoyo",
            Body: "Por favor, comunícate con un representante del centro de servicio para obtener ayuda.",
            Body1: "Puedes  <a href='https://covered-ca.my.salesforce-sites.com/chat/?lang=es_US'>Chatear con un representante</a> o llamar al",
            alt: "Contactar soporte",
            Title1: "Horario del Centro de Servicio",
            PhoneNumber: "(855) 708-4590",
            Hours: "Lunes a viernes: 8 a.m. a 6 p.m.",
        },
        KeepConfirmZeroPremium: {
            Title: "Estas cubierto. ",
            Body1: "Te enviaremos una carta para confirmar tu selección.",
            Body2: <><a
                        href="https://apply.coveredca.com/static/lw-web/login?lang=es_ES"
                        style={{
                        color: "var(--CalHEERS-Main-Blue, #2D77A3)",
                        textDecoration: "underline",
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >Inicia sesión</a> para revisar tu Tablreo de inscripción y obtener más detalles. También recibirás más información de tu compañía de seguro de salud.</>,
                        
                },

          
    }
}