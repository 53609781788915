import React, { useState, useEffect } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import ChatbotSelect from "../../UiKit/ChatbotSelect/ChatbotSelect";
import Form from "../../UiKit/Form/Form";
import { translations } from "./translation";
import { useLanguage } from "../../UiKit/Wizard/langugeContext"
import useEnrollmentStatus from "../../Hooks/useEnrollmentStatus";

function EnrollmentStatusCoverageYear(props) {
  const { next, previous, coverageYear, updateCoverageYear, enrollmentStatusEvent } = useEnrollmentStatus();
  const { currentLanguage } = useLanguage();
  const { sendLoggingEventToBackend } = props;
  const EnrollmentStatusCoverageYearTranslations = translations[currentLanguage]["EnrollmentStatusCoverageYear"];
  const buttonTranslations = translations[currentLanguage]["button"];

  const coverageYearOptions = [
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
  ];

  const [shouldShowValidationError, setShouldShowValidationError] = useState(false);


  const isValid = (coverageYear) => {
    if (coverageYear === "") return false;
    return true;
  };

  const onNextClick = () => {
    if (!isValid(coverageYear)) {
      setShouldShowValidationError(true);
      return;
    }
    setShouldShowValidationError(false);
    next();
  };

  const onSelection = (selectedOption) => {
    if (!isValid(selectedOption.value)) {
        setShouldShowValidationError(true);
        return; // Exit early if validation fails
    }
    
    setShouldShowValidationError(false);
    updateCoverageYear(selectedOption.value);

    let intentName = (currentLanguage === "es" 
        ? "enrollmentStatusCoverageYear" + selectedOption.value + "Spanish" 
        : "enrollmentStatusCoverageYear" + selectedOption.value
    );

    let text = (currentLanguage === "es" 
        ? "Enrollment Status Coverage Year " + selectedOption.value + " Spanish" 
        : "Enrollment Status Coverage Year " + selectedOption.value
    );

    sendLoggingEventToBackend({
        intentName: intentName,
        text: text
    });
};

return (
    <PageLayout>
        <PageLayout.Header style={{ marginBottom: "-17px" }}>
            <SubHeader subHeader={EnrollmentStatusCoverageYearTranslations.subHeader} />
        </PageLayout.Header>
        <PageLayout.Content>
            <Form>
                <Form.FormGroup>
                    <Form.Label>{EnrollmentStatusCoverageYearTranslations.bodyText.first}</Form.Label>
                    <Form.Control>
                        <ChatbotSelect
                            options={coverageYearOptions}
                            onChange={onSelection}
                            value={coverageYear} // Assuming this is a state reflecting the selected option
                            placeholder={EnrollmentStatusCoverageYearTranslations.Placeholder}
                            isRequired={true}
                            shouldShowValidation={shouldShowValidationError}
                            inputValidationMessage={EnrollmentStatusCoverageYearTranslations.validationMessage}
                        />
                    </Form.Control>
                </Form.FormGroup>
            </Form>
        </PageLayout.Content>
        <PageLayout.Navigation>
            <NavigationButton onClick={onNextClick} type={`primary`}>
                {buttonTranslations.Next}
            </NavigationButton>
            <NavigationButton onClick={() => previous()} type={"secondary"}>
                {buttonTranslations.Back}
            </NavigationButton>
        </PageLayout.Navigation>
    </PageLayout>
);
}

export default EnrollmentStatusCoverageYear;
