import React from 'react'

import ConnectedActivityWithSuggestedActions from "./SuggestedActions/ConnectedActivityWithSuggestedActions";
import ConnectedAHiddenActivity from './HiddenActivity/ConnectedHiddenActivity';

import UserSession from '../../Services/UserSession/UserSession';
import { jwtDecode } from 'jwt-decode';
const UserSessionService = new UserSession(localStorage, jwtDecode);

// this is based on 09.customization-reaction-buttons sample. Mainly for using the activity middleware
const activityMiddleware = () => next => card => {

    if (_isNew(card) && _hasSuggestedActions(card)) {
        return (
            children =>
                <ConnectedActivityWithSuggestedActions from={card.activity.from.role} key={card.activity.id} activityID={card.activity.id}>
                    {next(card)(children)}
                </ConnectedActivityWithSuggestedActions>
        );
    }

    /**
     * ignore the message sent by password reset wizard when closing the wizard
     */
    if (_isPasswordResetAutomatedCancelMessage(card)) {
        return (
            children =>
                <ConnectedAHiddenActivity from={card.activity.from.role} key={card.activity.id} activityID={card.activity.id}>
                    {next(card)(children)}
                </ConnectedAHiddenActivity>
        );
    }

    return next(card);
};

const _isPasswordResetAutomatedCancelMessage = (card) => {
    if (!card) return false;
    if (!card.activity) return false;
    if (card.activity.from.role !== 'user') return false;
    if (card.activity.text !== 'Password reset') return false;
    if (card.activity.type !== 'Forgot username') return false;
    return true;
}

const _hasSuggestedActions = (card) => {
    if (!card) return false;
    if (!card.activity) return false;
    if (!card.activity.suggestedActions) return false;
    if (!card.activity.suggestedActions.actions) return false;
    if (card.activity.suggestedActions.actions.length < 1) return false;
    return true;
}

const _isNew = (card) => {
    if (!card) return false;
    if (!card.activity) return false;
    if (!card.activity.id) return false;
    const startWatermark = UserSessionService.getWatermark();
    const currentActivityIdParts = card.activity.id.split("|");
    if (currentActivityIdParts.length < 2) return true;
    const currentActivityWatermark = parseInt(currentActivityIdParts[1]);
    if (currentActivityWatermark < startWatermark) return false;
    return true;
}

export default activityMiddleware;