import useChatbot from "./useChatbot";

const useAffordabilityToolActionHandler = () => {
    const { openAffordabilityTool } = useChatbot();

    const handleAffordabilityToolAction = (action) => {
        openAffordabilityTool();
    }

    return {
        handleAffordabilityToolAction
    }
}

export default useAffordabilityToolActionHandler;