import React, { useEffect, useState } from 'react';

function PasswordAttachment(props){
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [useExpiredToken, setUseExpiredToken] = useState(false);
  const [isUsedExpiredTokenVisible, setIsUsedExpiredTokenVisible] = useState(false);
  const [isPasswordValidationVisible, setIsPasswordValidationVisible] = useState(false);
  const [isConfirmPasswordValidationVisible, setIsConfirmPasswordValidationVisible] = useState(false);

  const onSubmit = (password) => {
    props.postActivity({
      type: 'message',
        value: {
          type: 'forgotPasswordCardClick',
          password: password,
          intentName: 'forgotPassword',
          useExpiredToken: useExpiredToken
        }
    }); 
    setIsDisabled(true);
    setIsSubmitDisabled(true);
  }

  const hasUpperCase = (password) => {
    return (/[A-Z]/.test(password));
  }
  
  const hasLowerCase = (password) => {
    return password.toUpperCase() !== password;
  }

  const hasNumber = (password) => {
    return /\d/.test(password);
  }

  const hasSpecialChar = (password) => {
    return /[~!@#$%^&*()_+\-=\[\]\\{}|;':",.\/<>?]/g.test(password);
  }

  const isPasswordMatch = (password, confirmPassword) => {
    if(password !== "" && password === confirmPassword){
      return true;
    }
    return false;
  }

  const isFiveToFiftyChars = (password) => {
    if(password.length >= 8 && password.length <= 50){
      return true;
    }
    return false;
  }

  const getCriteriaClass = (criteriaCheckFunction, isCharCriteriaMet, password) => {
    if(isCharCriteriaMet){
      if(criteriaCheckFunction(password)){
        return 'text-success pb-0';
      }
      return 'pb-0';
    }
    if(criteriaCheckFunction(password)){
      return 'text-success pb-0';
    }
    return 'text-danger pb-0';
  }

  const hasMetAtleastThreeCriteria = (password) => {
    let criteriaCount = 0;
    if(hasLowerCase(password)) criteriaCount++;
    if(hasUpperCase(password)) criteriaCount++;
    if(hasNumber(password)) criteriaCount++;
    if(hasSpecialChar(password)) criteriaCount++;
    if(criteriaCount > 2){
      return true;
    }
    return false;
  }

  const onPasswordUpdate = (newPassword) => {
    setPassword(newPassword);
    if(isFiveToFiftyChars(newPassword)){
      setIsPasswordValidationVisible(false);
    }else{
      setIsPasswordValidationVisible(true);
    }
    if(isPasswordMatch(newPassword, confirmPassword)){
      setIsConfirmPasswordValidationVisible(false);
    }else{
      setIsConfirmPasswordValidationVisible(true);
    }
    if(hasMetAtleastThreeCriteria(newPassword) && isPasswordMatch(newPassword, confirmPassword) && isFiveToFiftyChars(newPassword)){
      setIsSubmitDisabled(false);
    }else{
      setIsSubmitDisabled(true);
    }

  }

  const onConfirmPasswordUpdate = (newConfirmPassword) => {
    setConfirmPassword(newConfirmPassword);
    if(isPasswordMatch(password, newConfirmPassword)){
      setIsConfirmPasswordValidationVisible(false);
    }else{
      setIsConfirmPasswordValidationVisible(true);
    }
    if(hasMetAtleastThreeCriteria(password) && isPasswordMatch(password, newConfirmPassword) && isFiveToFiftyChars(password)){
      setIsSubmitDisabled(false);
    }else{
      setIsSubmitDisabled(true);
    }
  }

  return(
    <div className="container">
      <div className="row">
        <div className="col">
          <p className="mb-0">Your new password must be <span className={" font-weight-bold"}>between 8 and 50 characters</span> and contain <strong>at least 3 of the following</strong>:</p>
          <div style={{marginBottom: "20px"}}>
            <ul style={{paddingLeft: "20px"}}>
              <li style={{lineHeight: "20px"}} className={getCriteriaClass(hasUpperCase, hasMetAtleastThreeCriteria(password), password)}>UPPERCASE letter (A-Z)</li>
              <li style={{lineHeight: "20px"}} className={getCriteriaClass(hasLowerCase, hasMetAtleastThreeCriteria(password), password)}>Lowercase letter (a-z) </li>
              <li style={{lineHeight: "20px"}} className={getCriteriaClass(hasNumber, hasMetAtleastThreeCriteria(password), password)}>Number (0-9) </li>
              <li style={{lineHeight: "20px"}} className={getCriteriaClass(hasSpecialChar, hasMetAtleastThreeCriteria(password), password)}>Special character ` ~ ! @ # $ % ^ &amp; * ( ) _ + - = [ ]  { } | ; ‘ : “ , . / &lt; &gt; ?</li>
            </ul>
            <p>Passwords cannot be changed more than once a day.</p>           
          </div>
          <div style={{marginBottom: "20px"}}>
            It cannot:<br />
            <ul style={{paddingLeft: "20px"}}>
              <li style={{lineHeight: "20px"}} className="pb-0">be one of your previous 24 passwords</li>
              <li style={{lineHeight: "20px"}} className="pb-0">contain dictionary words, name, and common keyboard patterns (example: Qwerty1!)</li>
            </ul>
          </div>
          <form className="form">
            Please enter your new password
            <div className="form-group">
              <input disabled={isDisabled} type="password" className="form-control" onBlur={e => onPasswordUpdate(e.target.value)} onChange={e => onPasswordUpdate(e.target.value)}></input>
              <div className={isPasswordValidationVisible ? "d-block text-danger" : "d-none"}>Your new password must be between 8 and 50 characters</div>
            </div>
            <div className="form-group">
              <input disabled={isDisabled} type="password" className="form-control" onBlur={e => onConfirmPasswordUpdate(e.target.value)} onChange={e => onConfirmPasswordUpdate(e.target.value)}></input>
              <div className={isConfirmPasswordValidationVisible ? "d-block text-danger" : "d-none"}>Passwords do not match</div>
            </div>
            {isUsedExpiredTokenVisible ? 
              <div>
                <input
                    id="use-expired-token"
                    type="checkbox"
                    checked={useExpiredToken}
                    onChange={()=>setUseExpiredToken(!useExpiredToken)}
                />
                <label for="use-expired-token">use expired token</label>
              </div>
            : null}
            <button disabled={isSubmitDisabled} type="button" className={isSubmitDisabled ? "btn btn-block btn-disabled rounded my-3" :"btn btn-block ac-pushButton style-default my-3"} onClick={()=>onSubmit(password)}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default PasswordAttachment;