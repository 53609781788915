import React, { createContext, useState, useEffect } from "react";

export const ForgotUsernameContext = createContext();

const ForgotUsernameContextProvider = ({ children, sendActivity }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isForgotUsernameMenuOpen, setIsForgotUsernameMenuOpen] = useState(false);
    const [dob, setDob] = useState("");
    const [serviceNotAvailable, setServiceNotAvailable] = useState(false);
    const [activityData, _setActivityData] = useState(null);
    const [closeModal, setCloseModal] = useState({isOpen: false, type: null});
    const [helpModal, setHelpModal] = useState({isOpen: false, type: null})
    const [offBusinessHoursHelpModal, setOffBusinessHoursHelpModal] = useState({isOpen: false, type: null})
    const [email, setEmail] = useState('');
    const [maskedPhone, setMaskedPhone] = useState('');
    const [maskedEmail, setMaskedEmail] = useState('');
   

    // Custom setter for activityData
    const setActivityData = (data) => {

      _setActivityData(data);
    };

    useEffect(() => {
  
    }, [activityData]);

    
    const value = {
        currentPage,
        setCurrentPage,
        isForgotUsernameMenuOpen,
        setIsForgotUsernameMenuOpen,
        sendActivity,
        dob,
        setDob,
        activityData,
        setActivityData,
        serviceNotAvailable,
        setServiceNotAvailable,
        closeModal,
        setCloseModal,
        helpModal,
        setHelpModal,
        offBusinessHoursHelpModal,
        setOffBusinessHoursHelpModal,
        setEmail,
        email,
        maskedPhone,
        setMaskedPhone,
        maskedEmail,
        setMaskedEmail,
    };

    return (
        <ForgotUsernameContext.Provider value={value}>
            {children}
        </ForgotUsernameContext.Provider>
    );
}

export default ForgotUsernameContextProvider;
