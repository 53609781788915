const usePostActionHandler = () => {

    const handlePostAction = (action, next) => {
        return next(_updatePostAction(action))
    };

    const _updatePostAction = (action) => {
        let finalAction = {...action};
        if(_isSuggestedAction(finalAction)){
            finalAction = _addSuggestedActionProperties(finalAction);
        }
        finalAction = _addGaId(finalAction);
        finalAction = _addPageUrl(finalAction);
        return finalAction;
    };

    const _isSuggestedAction = (action) => {
        if(action && action.payload && action.payload.activity && action.payload.activity.text && action.payload.activity.text.indexOf('|||||') > -1){
            return true;
        }

        return false;
    }

    const _addSuggestedActionProperties = (action) => {
        const textArray = action.payload.activity.text.split("|||||");
        const text = textArray[0];
        return { 
            ...action, 
            payload: { 
                ...action.payload, 
                activity: { 
                    ...action.payload.activity, 
                    text, 
                    isSuggestedAction: true, 
                    isFromWelcomeMessage: false, 
                    deviceType: _getDeviceType() 
                } 
            } 
        }
    }

    const _addGaId = (action) => {
        action = { ...action, payload: { ...action.payload, activity: { ...action.payload.activity, gaId: getGaId() } } }
        return action;
    };
    
    const _addPageUrl = (action) => {
        action = { ...action, payload: { ...action.payload, activity: { ...action.payload.activity, pageUrl: window.location.pathname } } }
        return action;
    };

    const _getDeviceType = () => {
        const width = window.innerWidth;
        if (width > 1200) {
            return 'desktop';
        } else if (width > 768) {
            return 'tablet';
        } else {
            return 'mobile';
        }
    }

    function getGaId(){
        const cookieString = document.cookie;
        const cookieArrayDirty = cookieString.split(";");
        const cookieArrayClean = getCleanCookieArray(cookieArrayDirty);
        return findGaCookie(cookieArrayClean);
    }
    
    function findGaCookie(cookieArrayClean){
        const gaCookie = cookieArrayClean.find(cookieItem => cookieItem.key === "_ga");
        if(gaCookie){
            return gaCookie.value;
        }
        return "";
    }
    
    function getCleanCookieArray(cookieArrayDirty){
        const cookieArrayClean = [];
        for(let i=0; i<cookieArrayDirty.length; i++){
            const trimmed = cookieArrayDirty[i].trim();
            const keyPair = trimmed.split("=");
            const keyPairObject = {
                key: keyPair[0],
                value: keyPair[1]
            }
            cookieArrayClean.push(keyPairObject);
        }
        return cookieArrayClean;
    }

    return {
        handlePostAction
    }
}

export default usePostActionHandler;