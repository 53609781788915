import React, { createContext, useState, useEffect } from "react";
import CloseModal from "../Components/M2CWizard/CloseModal";

export const PasswordResetContext = createContext();

const PasswordResetContextProvider = ({ children, sendActivity }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isPasswordResetMenuOpen, setIsPasswordResetMenuOpen] = useState(false);
    const [username, setUsername] = useState("");
    const [dob, setDob] = useState("");
    const [otpType, setOtpType] = useState(null);
    const [passcode, setPasscode] = useState("");
    const [exceededAttemptMessage, setExceededAttemptMessage] = useState(""); 
    const [userNotFound, setUserNotFound] = useState(false);
    const [serviceNotAvailable, setServiceNotAvailable] = useState(false);
    const [activityData, _setActivityData] = useState(null);
    const [exceededOTPMessage, setExceededOTPMessage] = useState("");
    const [OTPRetry, setOTPRetry] = useState("");
    const [OTPSessionExpired, setOTPSessionExpired] = useState("");
    const [OTPServiceUnavailable, setOTPServiceUnavailable] = useState("");
    const [closeModal, setCloseModal] = useState({isOpen: false, type: null});
    const [helpModal, setHelpModal] = useState({isOpen: false, type: null})
    const [offBusinessHoursHelpModal, setOffBusinessHoursHelpModal] = useState({isOpen: false, type: null})
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [maskedPhone, setMaskedPhone] = useState('');
    const [maskedEmail, setMaskedEmail] = useState('');
    const [passwordInvalid, setPasswordInvalid] = useState("");
    const [isDidNotGetPasscodeModalOpen, setIsDidNotGetPasscodeModalOpen] = useState(false);

    // Custom setter for activityData
    const setActivityData = (data) => {
      _setActivityData(data);
    };

    useEffect(() => {
    }, [activityData]);

    
    const value = {
        currentPage,
        setCurrentPage,
        isPasswordResetMenuOpen,
        setIsPasswordResetMenuOpen,
        sendActivity,
        username,
        setUsername,
        dob,
        setDob,
        otpType,
        setOtpType,
        passcode,
        setPasscode,
        exceededAttemptMessage,
        setExceededAttemptMessage,
        userNotFound,
        setUserNotFound,
        serviceNotAvailable,
        setServiceNotAvailable,
        activityData,
        setActivityData,
        exceededOTPMessage,
        setExceededOTPMessage,
        OTPRetry,
        setOTPRetry,
        OTPSessionExpired,
        setOTPSessionExpired,
        OTPServiceUnavailable,
        setOTPServiceUnavailable,
        closeModal,
        setCloseModal,
        helpModal,
        setHelpModal,
        offBusinessHoursHelpModal,
        setOffBusinessHoursHelpModal,
        setPhone,
        setEmail,
        phone,
        email,
        maskedPhone,
        setMaskedPhone,
        maskedEmail,
        setMaskedEmail,
        passwordInvalid,
        setPasswordInvalid, 
        isDidNotGetPasscodeModalOpen,
        setIsDidNotGetPasscodeModalOpen
    };

    return (
        <PasswordResetContext.Provider value={value}>
            {children}
        </PasswordResetContext.Provider>
    );
}

export default PasswordResetContextProvider;
