import { useEffect } from 'react';
import useChatbotContext from "./useChatbotContext";

function useChatbot() {
  const {
    setIsMenuOpen,
    isMenuOpen,
    language,
    setLanguage,
    isAffordabilityToolWizardOpen,
    setIsAffordabilityToolWizardOpen,
    isEnrollmentStatusWizardOpen,
    setIsEnrollmentStatusWizardOpen,
    isM2CWizardOpen,
    setIsM2CWizardOpen,
    enrollmentStatusWizardEventData,
    setEnrollmentStatusWizardEventData,
    M2CWizardEventData,
    setM2CWizardEventData,
    isMinimized,
    setIsMinimized,
    isClosed,
    setIsClosed,
    sb260Event,
    setSb260Event,
    hasBeenOpened,
    setHasBeenOpened,
    setEnrollmentStatusEvent,
    enrollmentStatusEvent,
    showWelcomeScreen,
    setShowWelcomScreen,
    setPasswordResetEvent,
    passwordResetEvent,
    passwordResetEventData,
    setPasswordResetEventData,
    isPasswordResetMenuOpen,
    setIsPasswordResetMenuOpen,
    forgotUsernameEvent,
    setForgotUsernameEvent,
    forgotUsernameEventData,
    setForgotUsernameEventData,
    isForgotUsernameMenuOpen,
    setIsForgotUsernameMenuOpen,
    autoOpenUtterance,
    setAutoOpenUtterance
  } = useChatbotContext();

  useEffect(() => {
  }, [sb260Event]);

  useEffect(() => {
  }, [enrollmentStatusEvent]);


  const closeAllWizards = () => {

    closeAffordabilityTool();
  };

  const openAffordabilityTool = () => {
    closeAllWizards();
    setIsAffordabilityToolWizardOpen(true);
  };
  const closeAffordabilityTool = () => {
    setIsAffordabilityToolWizardOpen(false);
  };
  const openEnrollmentStatusWizard = (data) => {
    closeAllWizards();
    setIsEnrollmentStatusWizardOpen(true);
    setEnrollmentStatusWizardEventData(data);
    setEnrollmentStatusEvent({
      name: data.name,
      data: data.data
    });
  };
  const closeEnrollmentStatusWizard = () => {

    setIsEnrollmentStatusWizardOpen(false);
    setEnrollmentStatusWizardEventData();
  };
  const openM2CWizard = (data) => {
    closeAllWizards();
    setIsM2CWizardOpen(true);
    setM2CWizardEventData(data);
    setSb260Event({
      name: data.name,
      data: data.data
    });
  };
  const closeM2CWizard = () => {
    setIsM2CWizardOpen(false);
    setM2CWizardEventData();
  };
  const openPasswordResetWizard = (PasswordResetEvent) => {

    closeAllWizards();
    setIsPasswordResetMenuOpen(true);
    setPasswordResetEvent(PasswordResetEvent);
    setPasswordResetEventData(PasswordResetEvent.data);
  };
  const closePasswordResetWizard = () => {
    setIsPasswordResetMenuOpen(false);
    closeAllWizards();
    setPasswordResetEvent({ name: '', data: {} });
    setPasswordResetEventData(null);
    
  };
  const openWelcomePage = () => {
  
    setPasswordResetEvent({ name: 'openWelcomePage', data: {} });
    setPasswordResetEventData(null);
    
  };

  const openForgotUsernameWizard = (forgotUsernameEvent) => {
  
    closeAllWizards();
    setIsForgotUsernameMenuOpen(true);
    setForgotUsernameEvent(forgotUsernameEvent);
    setForgotUsernameEventData(forgotUsernameEvent.data);
  }

  const closeForgotUsernameWizard = () => {
   // console.log("closeForgotUsernameWizard");
    closeAllWizards();
    setIsForgotUsernameMenuOpen(false);
    
    setForgotUsernameEvent({ name: '', data: {} });
    setForgotUsernameEventData(null);
  }

  const openForgotUsernameWelcomePage = () => {
  
   openForgotUsernameWizard({name: "openForgotUsernameWelcomePage", data: {}});
    setForgotUsernameEventData(null);
  }

  const minimize = () => {
    setIsMinimized(true);
  };
  const open = () => {
    if (!hasBeenOpened) setHasBeenOpened(true);
    setIsClosed(false);
    setIsMinimized(false);
  };
  const close = () => {
    setHasBeenOpened(false);
    setIsClosed(true);
    setIsMinimized(true);
    setIsMenuOpen(false);
    closeAllWizards();
  }
  const openChatbotMenu = () => {
    setIsMenuOpen(true);
  };
  const closeChatbotMenu = () => {
    setIsMenuOpen(false);
  };
  const toggleChatbotMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const openWithUtterance = (utterance) => {
    open();
    setAutoOpenUtterance(utterance);
  }
  const getAutoOpenUtteranceFromQueryString = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const autoOpenUtteranceFromQueryString = urlParams.get('open-cici');
    return autoOpenUtteranceFromQueryString;
  }
  const hasAutoOpenUtterance = () => {
    const autoOpenUtteranceFromQueryString = getAutoOpenUtteranceFromQueryString();
    if(autoOpenUtteranceFromQueryString) return true;
    return false;
  }
  const handleAutoOpenUtterance = (autoOpenUtteranceFromQueryString) => {
    if(autoOpenUtteranceFromQueryString === "true"){
      open();
      return;
    }
    if(autoOpenUtteranceFromQueryString.toLowerCase() === "affordabilitytool"){
      open();
      setIsAffordabilityToolWizardOpen(true);
      return;
    }
    if(autoOpenUtteranceFromQueryString) openWithUtterance(autoOpenUtteranceFromQueryString);
  }




  return {
    isMinimized,
    isAffordabilityToolWizardOpen,
    isEnrollmentStatusWizardOpen,
    enrollmentStatusWizardEventData,
    isM2CWizardOpen,
    M2CWizardEventData,
    hasBeenOpened,
    isClosed,
    close,
    openM2CWizard,
    closeM2CWizard,
    open,
    minimize,
    openChatbotMenu,
    closeChatbotMenu,
    toggleChatbotMenu,
    closeAffordabilityTool,
    openAffordabilityTool,
    openEnrollmentStatusWizard,
    closeEnrollmentStatusWizard,
    sb260Event,
    showWelcomeScreen,
    setShowWelcomScreen,
    openPasswordResetWizard,
    closePasswordResetWizard,
    passwordResetEvent,
    openWelcomePage,
    isPasswordResetMenuOpen,
    openForgotUsernameWizard,
    closeForgotUsernameWizard,
    forgotUsernameEvent,
    openForgotUsernameWelcomePage,
    isForgotUsernameMenuOpen, 
    setShowWelcomScreen,
    openWithUtterance,
    autoOpenUtterance,
    getAutoOpenUtteranceFromQueryString,
    hasAutoOpenUtterance,
    handleAutoOpenUtterance
  };
}

export default useChatbot;
