import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import usePasswordReset from "../../Hooks/usePasswordReset";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
// import { translations } from "./translations";
import useChatbot from "../../Hooks/useChatbot";
import celebratingCici from "../../Images/Celebrating-CiCi.json";
import Lottie from "react-lottie";
import cicijson from "../../Images/CiCi-Celebrating-Wider.json"

function SuccessPage(props) {
    const { closePasswordReset } = usePasswordReset();
    const { currentLanguage } = useLanguage();
    const {closePasswordResetWizard} = useChatbot();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cicijson,
        rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <PageLayout>
            <PageLayout.Header>
                <SubHeader subHeader="Password Change Successful!" />
            </PageLayout.Header>
            <PageLayout.Content>
                <Body style={{ fontSize: "16px" }}
                    bodyText={
                        <>
                            <div style={{ marginTop: '10px' }}>
                            You can now log in with your new password.
                            </div>
                        </>
                    }
                />
                <div style={{ textAlign: "center", marginTop: "32px" }}>
                    <Lottie
                        options={defaultOptions}
                        height={112}
                        width={288}
                    />
                </div>
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton 
                // onClick={() => closePasswordReset()} 
                onClick={() => closePasswordResetWizard()}
                type="primary" style={{ marginBottom: "36px" }}>
                Exit
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

export default SuccessPage;
