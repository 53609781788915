import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import usePasswordReset from "../../Hooks/usePasswordReset";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import welcomeImage from "../../Images/Welcome.svg";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "../M2CWizard/translations";
import useChatbot from "../../Hooks/useChatbot";
import useWebChat from "../../Hooks/useWebchat/useWebChat";
import {useState, useEffect, useRef} from "react";

function WelcomePage({ onNext }) {
    const { currentLanguage } = useLanguage();
    const { closePasswordReset } = usePasswordReset();
    const {closePasswordResetWizard} = useChatbot();
    
    const welcomePageTranslations = translations[currentLanguage]["WelcomePage"];

    
    return (
        <PageLayout>
            <PageLayout.Content>
                <Content welcomePageTranslations={welcomePageTranslations} />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton  onClick={() => onNext()} type={"primary"}>Begin</NavigationButton>
                <NavigationButton onClick={() => closePasswordReset()} type={"secondary"}>Exit</NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

function Content( ) {

    const { sendMessage } = useWebChat();
    const { closePasswordReset } = usePasswordReset();
    const { closePasswordResetWizard } = useChatbot();
    const [closeTriggered, setCloseTriggered] = useState(0);
    const timeoutRef = useRef(null);    
 

    const handlePasswordReset= () => {
        closePasswordReset();
        console.log("closePasswordReset");
        setTimeout(() => {
            console.log("Sending message: Forgot my username");
            sendMessage("Forgot my username");
        }, 1000);
    }


    return (
        <div
            style={{
                fontSize: '21px',
                lineHeight: '29.4px',
                fontWeight: 'normal',
                margin: '0',
            }}
        >
            <SubHeader
                style={{ display: 'flex', marginBottom: '8px', marginTop: '0px' }}
                subHeader="Let’s reset your password."
            />
            <Body
                bodyText={
                    <>
                    <div style={{ marginTop: '10px' }}>
                        You’ll need to know your username to reset your password.{" "}
                        <a
                            style={{
                                color: "var(--CalHEERS-Main-Blue, #2D77A3)",
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            rel="noopener noreferrer"
                            onClick={handlePasswordReset}
                        >
                        Forgot your username?</a>
                        {/* <a href="/forgot-username">Forgot your username?</a> */}
                    </div>
                </>
                }
            />
        </div>
    );
}

export default WelcomePage;

