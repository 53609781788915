//enrollment status wizrd
import React, {useState, useEffect} from "react";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Header  from "../../UiKit/Wizard/Header/Header"; 
import useEnrollmentStatus from "../../Hooks/useEnrollmentStatus";
import WelcomePage from "./WelcomePage";
import AuthenticationPage from "./Authentication";

import styles from "../../UiKit/Wizard/Header/Header.module.css";
import ChatbotLayout from "../../UiKit/Chatbot/ChatbotLayout/ChatbotLayout";
import EnrollmentStatusHeader from "./EnrollmentStatusHeader";
import EnrollmentStatusMenu from "./EnrollmentStatusMenu";
import EnrollmentStatusContext  from "../../Contexts/EnrollmentStatusContext";
import useWebChat from "../../Hooks/useWebChat";
import CloseModal from "./CloseModal";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import EnrollmentStatusNoStatus from "./EnrollmentStatusNoStatus";
import EnrollmentStatusUnsupportedScenarios from "./EnrollmentStatusUnsupportedScenarios";
import EnrollmentStatusCoverageYear from "./EnrollmentStatusCoverageYear";
import EnrollmentStatusResults from "./EnrollmentStatusResults";
import EnrollmentStatusServiceError from "./EnrollmentStatusServiceError";
import EnrollmentStatusFeedbackPage from "./EnrollmentStatusProvideFeedback";
import EnrollmentStatusThankYou from "./EnrollmentStatusThankYouPage";
import translations from "./translation";
import Loading from "../../UiKit/Wizard/Loading/Loding";
import WebchatMenu from "../Webchat/WebchatMenu/WebchatMenu";


function EnrollmentStatusWizard({ sendActivity , sendLoggingEventToBackend, sendMessage}) {
  return (
      <EnrollmentStatusContext>
          <EnrollmentStatusHelper sendActivity={sendActivity} sendLoggingEventToBackend={sendLoggingEventToBackend} sendMessage={sendMessage} />
      </EnrollmentStatusContext>
  )
}

export const Pages = {
  WELCOMEPAGE:0,
  AUTHENTICATION: 1,
  COVERAGE_YEAR: 2,
  ENROLLMENTRESULTS: 3,
  UNSUPPORTED_SCENARIOS: 4,
  NO_ENROLLMENT_STATUS: 5,
  ENROLLMENT_STATUS_SERVICEERROR: 6, 
  ENROLLMENT_STATUS_FEEDBACK: 7,
  ENROLLMENT_STATUS_THANKYOU: 8,
}

function EnrollmentStatusHelper({ sendActivity, sendLoggingEventToBackend, sendMessage }) {
    //const { close, sendActivity, event, onHelpClick, lang, closeContactSupport, chatWithRepresentative, serviceHourData } = props;
    const {  status, currentPage, EnrollmentStatusEventData, setCurrentPage, enrollmentStatusEvent , closeEs, closeModal,  openRelativeCloseModal, coverageYear, setCoverageYear} = useEnrollmentStatus();
    const [authenticationData, setAuthenticationData] = useState({ ssn: '', dob: '', zipCode: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [planDetails, setPlanDetails] = useState();
    const {store} = useWebChat();
    const [selectedOption, setSelectedOption] = useState("");
    const { currentLanguage } = useLanguage();
    const introPageTranslations = translations[currentLanguage]['RetunToChatButton'];



    
    const authenticate = async ({ dob, ssn, zip }) => {
      setIsLoading(true);
      sendActivity({
        type: "message",
        value: {
          wizard: "sb260",
          dob,
          ssn,
          zip,
        },
      });
    };


const handleSetCurrentPage = (page) => {
  setCurrentPage(page);
}
const handleSelectedOptionChange = (newOption) => {
  setSelectedOption(newOption);
};


    useEffect(() => {
      setIsLoading(false);
          if (enrollmentStatusEvent.name === "SHOW_START") {
            const intentName = currentLanguage === "en" ? "enrollmentStatusStarted" : "enrollmentStatusStartedSpanish";
            const text = currentLanguage === "en" ? "Enrollment Status Started" : "Enrollment Status Started Spanish";
            sendLoggingEventToBackend({intentName: intentName, text: text});
            setCurrentPage(0);
            }
          if (enrollmentStatusEvent.name === "SHOW_AUTHENTICATE_SUCCESS") {
              setPlanDetails({
                plan: enrollmentStatusEvent.data.plan,
                monthlyPremium: enrollmentStatusEvent.data.monthlyPremium,
                isZeroPremium: enrollmentStatusEvent.data.isZeroPremium,
                ...enrollmentStatusEvent.data,
              });
              const intentName = currentLanguage === "en" ? "enrollmentStatusAuthenticateSuccess" : "enrollmentStatusAuthenticateSuccessSpanish";
              const text = currentLanguage === "en" ? "Enrollment Status Authenticate success" : "Enrollment Status Authenticate success Spanish";
              sendLoggingEventToBackend({intentName: intentName, text: text});

              if (enrollmentStatusEvent.data.getDetails && enrollmentStatusEvent.data.getDetails["2023"] && enrollmentStatusEvent.data.getDetails["2024"]) {
                setCurrentPage(Pages.COVERAGE_YEAR);
              } else {
                setCurrentPage(Pages.ENROLLMENTRESULTS);
              }
                
           
          }
          if (enrollmentStatusEvent.name === "SHOW_NO_ENROLLMENT_STATUS") {
            const intentName = currentLanguage === "en" ? "enrollmentStatusNoResult" : "enrollmentStatusAuthenticateNoResultSpanish";
            const text = currentLanguage === "en" ? "Enrollment Status no result" : "Enrollment Status no result Spanish";
            sendLoggingEventToBackend({intentName: intentName, text: text});
            setCurrentPage(4)
          }

          if (enrollmentStatusEvent.name === "SHOW_UNSUPPORTED_SCENARIO") {
            const intentName = currentLanguage === "en" ? "enrollmentStatusUnsupportedMulti-Case" : "enrollmentStatusUnsupportedMulti-CaseSpanish";
            const text = currentLanguage === "en" ? "Enrollment status unsupported multi-case" : "Enrollment status unsupported multi-case Spanish";
            sendLoggingEventToBackend({intentName: intentName, text: text});
            setCurrentPage(5)
          }

          if (enrollmentStatusEvent.name === "SHOW_SERVICE_ERROR" || enrollmentStatusEvent.name === "SHOW_AUTHENTICATE_ERROR" || enrollmentStatusEvent.name === "SHOW_SERVICE_TIMEOUT_ERROR" || enrollmentStatusEvent.name === "SHOW_SERVICE_UNAVAILABLE_ERROR") {
            const intentName = currentLanguage === "en" ? "enrollmentStatusServiceError" : "enrollmentStatusServiceErrorSpanish";
            const text = currentLanguage === "en" ? "Enrollment status service error" : "Enrollment status service error Spanish";
            sendLoggingEventToBackend({intentName: intentName, text: text});
            setCurrentPage(6)
          }

          if (enrollmentStatusEvent.name === "SHOW_USER_NOT_FOUND") {
            const intentName = currentLanguage === "en" ? "enrollmentStatusUserNotFound" : "enrollmentStatusUserNotFoundSpanish";
            const text = currentLanguage === "en" ? "Enrollment status user not found" : "Enrollment status user not found Spanish";
            sendLoggingEventToBackend({intentName: intentName, text: text});
            setCurrentPage(4);
          }
      
      
        setIsLoading(false);
      }, [enrollmentStatusEvent.data, enrollmentStatusEvent.name]);


    const getContent = () => {
        if (status === null) {
            return <div>Loading</div>
        }
        if (status === "error") {
            return <div>Error</div>
        }
        return (
            <>
                {currentPage === 0 && <WelcomePage />}
                {currentPage === 1 && <AuthenticationPage  
                    onSubmit={authenticate} 
                    data={planDetails}
                    authenticationData={authenticationData} 
                    setAuthenticationData={setAuthenticationData}
                    />}
                {currentPage === 2 && <EnrollmentStatusCoverageYear
                    sendLoggingEventToBackend={sendLoggingEventToBackend}/>}
                {currentPage=== 3 && <EnrollmentStatusResults 
                      data ={planDetails}
                      selectedYear = {coverageYear}
                      onNext = {() => {
                        setCurrentPage(Pages.ENROLLMENT_STATUS_FEEDBACK);
                      }} 
                      sendMessage={sendMessage}
                      onClose={closeEs}
                    />}
                {currentPage === 4 && <EnrollmentStatusNoStatus
                    data={enrollmentStatusEvent.data}
                    onNext = {() => {
                      setCurrentPage(Pages.ENROLLMENT_STATUS_FEEDBACK);
                    }}
                    onClose={closeEs}
                    />} 
                {currentPage === 5 && <EnrollmentStatusUnsupportedScenarios
                    onNext = {() => {
                      setCurrentPage(Pages.ENROLLMENT_STATUS_FEEDBACK);
                    }}/>}
                {currentPage === 6 && <EnrollmentStatusServiceError />}
                {currentPage === 7 && < EnrollmentStatusFeedbackPage />}
                {currentPage === 8 && <EnrollmentStatusThankYou sendMessage={sendMessage}/>}

                   
            </>
        )
    }

    const handleClick = () => {
      openRelativeCloseModal(); 
    };

    return (
      <ChatbotLayout>
        <WebchatMenu />
          {closeModal.isOpen && <CloseModal type={closeModal.type}/>}
          <ChatbotLayout.Header>
          <EnrollmentStatusHeader />
          </ChatbotLayout.Header>
          <ChatbotLayout.Wizard>
            <WizardLayout>                    
              <WizardLayout.Header>
                  <Header>
                    <div className={styles.title} onClick={handleClick}>
                        {introPageTranslations}
                    </div>
                  </Header>
              </WizardLayout.Header>
                {isLoading && <Loading />} 
                  {getContent()}
            </WizardLayout>
          </ChatbotLayout.Wizard>
      </ChatbotLayout>
    );
}

 export default EnrollmentStatusWizard;