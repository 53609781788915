import React, { useState, useEffect, useRef } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useEnrollmentStatus from "../../Hooks/useEnrollmentStatus";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import moment from "moment";
import InputMask from "react-input-mask";
import sendEventToDataLayer from "../../___archive/datalayer-event-logger-for-transactions/sendEventToDataLayer";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translation";


const SSNInput = React.forwardRef((props, ref) => (
    <input ref={ref} {...props} />
));

function AuthenticationPage(props) {
    const { next, previous } = useEnrollmentStatus();
    const { dob, ssnPart1, ssnPart2, ssnPart3, setSsnPart1, setSsnPart2, setSsnPart3, setZip, setDob, zip, EnrollmentStatusWizrdEventData } = useEnrollmentStatus();
    const [shouldShowDOBValidationError, setShouldShowDOBValidationError] = useState(false);
    const [shouldShowSSNValidationError, setShouldShowSSNValidationError] = useState(false);
    const [shouldShowZipValidationError, setShouldShowZipValidationError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const { sendLoggingEventToBackend } = props;
    const { currentLanguage } = useLanguage();
    const authenticationPageTranslations = translations[currentLanguage]["AuthenticationPage"];
    const buttonTranslations = translations[currentLanguage]["button"];
    
    const submit = props.onSubmit;
    const ssnPart1Ref = useRef(null);  

    const ssnPart2Ref = useRef(null);
    const ssnPart3Ref = useRef(null);
    const zipRef = useRef(null);

    

    useEffect(() => {
        if (hasSubmitted) {
            if (isSsnValid(ssnPart1, ssnPart2, ssnPart3)) {
                setShouldShowSSNValidationError(false);
            } else {
                setShouldShowSSNValidationError(true);
            }
        }
    }, [ssnPart1, ssnPart2, ssnPart3, hasSubmitted]);

    const isDobValid = (dob) => {
        return moment(dob, "MM/DD/YYYY", true).isValid();
    };

    const isSsnValid = (part1, part2, part3) => part1.length === 3 && part2.length === 2 && part3.length === 4 && !isNaN(`${part1}${part2}${part3}`);

    const isZipValid = (zip) => {
        if (zip.length !== 5) return false;
        if (isNaN(zip)) return false;
        return true;
    };

    const onDOBChange = (e) => {
        const dob = e.target.value;
        setDob(dob);
            
        if (isDobValid(dob) && dob.length === 10 && ssnPart1Ref.current) {
            ssnPart1Ref.current.focus(); 
        }
    
        if (!isDobValid(dob) && hasSubmitted) {
            setShouldShowDOBValidationError(true);
        } else {
            setShouldShowDOBValidationError(false);
        }
    
        if (errors.length > 0) {
            const updatedErrors = errors.filter(error => error !== authenticationPageTranslations.DOBVaildation);
            setErrors(updatedErrors);
        }
    };
    
    const onSSNChange = (part, value) => {
        const numericValue = value.replace(/\D/g, ''); 
        if (part === 1) setSsnPart1(numericValue);
        if (part === 2) setSsnPart2(numericValue);
        if (part === 3) setSsnPart3(numericValue);
    };

    const onZipChange = (e) => {
        const numericValue = e.target.value.replace(/\D/g, ''); 
        setZip(numericValue);

        if (!isZipValid(numericValue) && hasSubmitted) {
            setShouldShowZipValidationError(true);
        } else {
            setShouldShowZipValidationError(false);
        }

        if (errors.length > 0) {
            const updatedErrors = errors.filter(error => error !== authenticationPageTranslations.ZIPValidation);
            setErrors(updatedErrors);
        }
    };

    const cleanSSN = () => {
        return `${ssnPart1}${ssnPart2}${ssnPart3}`;
    };

    const onSubmit = () => {
        const formErrors = [];
        
        if (!isDobValid(dob)) {
            formErrors.push(authenticationPageTranslations.DOBVaildation);
            setShouldShowDOBValidationError(true);
        } else {
            setShouldShowDOBValidationError(false);
        }
    
        if (!isSsnValid(ssnPart1, ssnPart2, ssnPart3)) {
            formErrors.push(authenticationPageTranslations.SSNValidation);
            setShouldShowSSNValidationError(true);
        } else {
            setShouldShowSSNValidationError(false);
        }
    
        if (!isZipValid(zip)) {
            formErrors.push(authenticationPageTranslations.ZIPValidation);
            setShouldShowZipValidationError(true);
        } else {
            setShouldShowZipValidationError(false);
        }
    
        setErrors(formErrors);
        if (formErrors.length < 1) {
            submit({
                ssn: cleanSSN(),
                dob: moment(dob).format("YYYY-MM-DD"),
                zip,
              });   
            setHasSubmitted(true);
            next();
        } 
    };
 

    const handleSSNChange = (part, setter, ref) => (e) => {
        const value = e.target.value.replace(/\D/g, ''); 
        setter(value);
    
           if ((part === 1 && value.length === 3 && ref && ref.current) ||
            (part === 2 && value.length === 2 && ref && ref.current) ||
            (part === 3 && value.length === 4 && ref && ref.current)) {
            ref.current.focus();
        }

        if (hasSubmitted) {
            setShouldShowSSNValidationError(!isSsnValid(ssnPart1, ssnPart2, ssnPart3));
        }
    };
    

    return (
        <PageLayout>
            <PageLayout.Header style={{ marginBottom: "-17px" }}>
                <SubHeader style={{ marginBottom: "-" }} subHeader={authenticationPageTranslations.Title} />
            </PageLayout.Header>
            <PageLayout.Content>
                <Form>
                    <Form.FormGroup>
                        <Form.Label style={{ display: "block" }}>
                            {authenticationPageTranslations.DOB}
                        </Form.Label>
                        <Form.Control>
                            <InputMask
                                mask="99/99/9999"
                                value={dob}
                                onChange={onDOBChange}
                                placeholder={authenticationPageTranslations.DOBPlaceholder}
                                maskChar={null}
                            >
                                {(inputProps) => (
                                    <Input
                                        {...inputProps}
                                        type="text"
                                        isValid={!shouldShowDOBValidationError}
                                    />
                                )}
                            </InputMask>
                        </Form.Control>
                        {shouldShowDOBValidationError &&
                            <InputValidationMessage>
                               {authenticationPageTranslations.DOBVaildation}
                            </InputValidationMessage>
                        }
                    </Form.FormGroup>
                    <Form.FormGroup>
                        <Form.Label style={{ display: "block" }}>
                           {authenticationPageTranslations.SSN}
                        </Form.Label>
                        <Form.Control style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "12px", alignSelf: "stretch" }}>
                        <Input
                            placeholder={authenticationPageTranslations.SSNPlaceholder1}
                            type="text"
                            maxLength="3"
                            value={ssnPart1}
                            onChange={handleSSNChange(1, setSsnPart1, ssnPart2Ref)} 
                            isValid={!shouldShowSSNValidationError}
                            ref={ssnPart1Ref} 
                        />
                        <Input
                            placeholder={authenticationPageTranslations.SSNPlaceholder2}
                            type="text"
                            maxLength="2"
                            value={ssnPart2}
                            onChange={handleSSNChange(2, setSsnPart2, ssnPart3Ref)} 
                            isValid={!shouldShowSSNValidationError}
                            ref={ssnPart2Ref}  
                        />
                        <Input
                            placeholder={authenticationPageTranslations.SSNPlaceholder3}
                            type="text"
                            maxLength="4"
                            value={ssnPart3}
                            onChange={handleSSNChange(3, setSsnPart3, zipRef)} 
                            isValid={!shouldShowSSNValidationError}
                            ref={ssnPart3Ref}  
                        />
                    </Form.Control>

                        {shouldShowSSNValidationError && (
                            <InputValidationMessage>{authenticationPageTranslations.SSNValidation}</InputValidationMessage>
                        )}
                    </Form.FormGroup>
                    <Form.FormGroup>
                        <Form.Label style={{ display: "block" }}>
                            {authenticationPageTranslations.ZIP}
                        </Form.Label>
                        <Form.Control>
                            <Input
                                placeholder={authenticationPageTranslations.ZIPPlaceholder}
                                type="text"
                                maxLength="5"
                                value={zip}
                                onChange={onZipChange}
                                isValid={!shouldShowZipValidationError}
                                ref={zipRef} 
                            />
                        </Form.Control>
                        {shouldShowZipValidationError &&
                            <InputValidationMessage>
                                {authenticationPageTranslations.ZIPValidation}
                            </InputValidationMessage>
                        }
                    </Form.FormGroup>
                </Form>
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={onSubmit} type={"primary"}>{buttonTranslations.Next}</NavigationButton>
                <NavigationButton onClick={() => previous()} type={"secondary"}>{buttonTranslations.Back}</NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

export default AuthenticationPage;
