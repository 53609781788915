
const AFFORDABILITY_THRESHOLD = 9.12;

function getContributionPercentageOfIncome(householdAnnualIncome, contributionAmount, contributionFrequency) {
    return parseFloat(((contributionAmount * contributionFrequency / householdAnnualIncome) * 100).toFixed(2));
};

// function isOfferAffordable(contributionPercentageOfIncome) {
//     return contributionPercentageOfIncome <= AFFORDABILITY_THRESHOLD;
// };
function getAffordabilityThreshold(coverageYear) {
    switch (coverageYear) {
        case '2024':
            return 8.39;
        case '2025':
            return 9.02;
        default:
            return 9.12; 
    }
}

function isOfferAffordable(contributionPercentageOfIncome, coverageYear) {
    const threshold = getAffordabilityThreshold(coverageYear);
    return contributionPercentageOfIncome <= threshold;
}


function isAnyOfferAffordable(offers) {
    const affordableOffers = offers.filter(offer => isOfferAffordable(offer));
    if (affordableOffers.length > 0) {
        return true;
    }
    return false;
};

export default {
    getContributionPercentageOfIncome,
    isOfferAffordable,
    isAnyOfferAffordable,
    getAffordabilityThreshold
}
