import React from 'react';
import useChatbot from '../../Hooks/useChatbot';
import useWebChat from '../../Hooks/useWebChat';
import formatNumberToDollar from '../../Utilities/formatNumberToDollar';
import useLanguageHook from '../../Hooks/useLanguageHook';

import UserSession from '../../Services/UserSession/UserSession';
import { jwtDecode } from 'jwt-decode';
const UserSessionService = new UserSession(localStorage, jwtDecode);

function DeveloperTools() {
    const { openAffordabilityTool,
        isAffordabilityToolWizardOpen,
        isEnrollmentStatusWizardOpen,
        enrollmentStatusWizardEventData,
        isM2CWizardOpen,
        M2CWizardEventData,
        isClosed,
        isMinimized,
        hasBeenOpened,
        isPasswordResetMenuOpen,
        passwordResetEvent,
        forgotUsernameEvent,
        isForgotUsernameMenuOpen
    } = useChatbot();
    //const {sendActivity} = useWebChat();

    const { sendActivity, isInitialized, directline } = useWebChat();
    const { currentLanguage } = useLanguageHook();
    const clearLocalStorage = () => {
        localStorage.clear();
    }
    return (
        <div style={{ position: "fixed", bottom: 0, left: 0 }}>
            <h3>token</h3>
            <div>
                isNewUser: {UserSessionService.isNewUser().toString()}
            </div>
            <div>
                isOfflineDirectLine: {UserSessionService.isOfflineDirectLine(directline).toString()}
            </div>
            <div>
                previousSessionHasExpired: {UserSessionService.previousSessionHasExpired().toString()}
            </div>
            <div>
                token expiration: {UserSessionService.getTokenExpiration() ? new Date(UserSessionService.getTokenExpiration()).toLocaleString() : "null"}
            </div>
            <div>
                Language: {currentLanguage}
            </div>
            <div>
                {formatNumberToDollar(10000.23242323)}
            </div>
            <strong>Chatbot</strong>
            <div>hasBeenOpened: {hasBeenOpened.toString()}</div>
            <div>isMinimized: {isMinimized.toString()}</div>
            <div>isInitialized: {isInitialized().toString()}</div>
            <div>isClosed: {isClosed.toString()}</div>
            <strong>Wizards</strong>
            <div>isAffordabilityToolOpen: {isAffordabilityToolWizardOpen.toString()}</div>
            <div>isEnrollmentStatusWizardOpen: {isEnrollmentStatusWizardOpen.toString()}</div>
            <div>
                enrollmentStatusWizardEventData:
                <pre>
                    {JSON.stringify(enrollmentStatusWizardEventData, null, 2)}
                </pre>
            </div>
            <div>isM2CWizardOpen: {isM2CWizardOpen.toString()}</div>

            <div>
                M2CWizardEventData:
                <pre>
                    {JSON.stringify(M2CWizardEventData, null, 2)}
                </pre>
            </div>
            <button onClick={() => sendActivity({
                "type": "message",
                "value": {
                    "wizard": "sb260",
                    "dob": "2011-11-11",
                    "ssn": "121212121",
                    "zip": "43243"
                }
            })}>send hello</button>
            <button onClick={openAffordabilityTool}>open affordability tool</button>
            <div>
                <button onClick={clearLocalStorage}>Clear LocalStorage</button>
            </div>
            <div>
                isPasswordResetMenuOpen: {isPasswordResetMenuOpen.toString()}
            </div>
            <div> isPasswordResetEvent : </div>
            <pre>
                    {JSON.stringify(passwordResetEvent, null, 2)}
                </pre>
            <div> isForgotUsernameEvent :{isForgotUsernameMenuOpen.toString()} </div>
                <pre>
                    {JSON.stringify(forgotUsernameEvent, null, 2)}
                </pre>
        </div>
    )
}

export default DeveloperTools;