import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useAffordabilityTool from "../../Hooks/useAffordabilityTool";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function IntroPage() {
  const { next } = useAffordabilityTool();
  const { currentLanguage } = useLanguage();
  const introPageTranslations = translations[currentLanguage]['IntroPage'];

  const handleNextClick = () => {
    next();
  };
  return (
    <PageLayout>
      <PageLayout.Content>
        <Content currentLanguage={currentLanguage} introPageTranslations={introPageTranslations} />
      </PageLayout.Content>
      <PageLayout.Navigation>
        <NavigationButton  onClick={handleNextClick}  type="primary" style={{ marginBottom: "36px" }}>
          {introPageTranslations.BeginButton}
        </NavigationButton>
      </PageLayout.Navigation>
    </PageLayout>
  );
}

function Content({ introPageTranslations }) {
  return (
    <div
      style={{
        fontSize: '21px',
        lineHeight: '29.4px',
        fontWeight: 'normal',
        margin: '0',
      }}
    >
      <SubHeader
        style={{ display: 'flex', marginBottom: '24px', marginTop: '0px' }}
        subHeader={introPageTranslations.subHeader}
      />
      <Body
        bodyText={
          <>
            <div>{introPageTranslations.bodyText.first}</div>
            <div style={{ marginTop: '10px' }}
              dangerouslySetInnerHTML={{ __html: introPageTranslations.bodyText.second }}
            />
          </>
        }
      />
    </div>
  );
}

export default IntroPage;
