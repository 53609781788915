import React from 'react';
import './WelcomeMessageAttachment.css';

// import './FeedbackAttachment.css';
const WelcomeMessageAttachment = (props) => {
    const store = props.store;

    const dispatchARP = () => {
      store.dispatch({
        type: 'WEB_CHAT/SEND_MESSAGE',
        payload: {
          text: 'American Rescue Plan'
        }
      });
    }
    
    const WelcomeAttachmentBody = () => {
      return (
        <div>
          <div className='text-body'>
            <p style={{fontSize: "1rem"}}>
              Did you know that the <span className="arp-dispatch" onClick={dispatchARP}>American Rescue Plan</span> has made health insurance more affordable than ever?<br /><br />
              Check out our quick and easy <a style={{textDecoration: "none"}} href="https://www.coveredca.com/#quick-calculator" target="_blank" rel="noopener noreferrer">calculator </a> to see how much you can save.
            </p>
          </div>
        </div>
      )
    }

    return(
      <div className={ 'welcomeAttachment width-100' }>
        <WelcomeAttachmentBody  />
      </div>
    );
}

export default WelcomeMessageAttachment;