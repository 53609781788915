import React from 'react';

const HiddenActivity = (props) => {
    return (
        <div className="d-none" style={{display: "none"}}>
            {props.children}
        </div>
      );
}

export default HiddenActivity;