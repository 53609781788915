import React, { useContext, useEffect, useState} from "react";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import useForgotUsername from "../../Hooks/useForgotUsername";
import ChatbotLayout from "../../UiKit/Chatbot/ChatbotLayout/ChatbotLayout";
import Header from "../../UiKit/Wizard/Header/Header";
import WelcomePageForgotUsername from "./WelcomePage";
import styles from "../../UiKit/Wizard/Header/Header.module.css";
import useChatbot from "../../Hooks/useChatbot";
import ForgotUsernameContextProvider from "../../Contexts/ForgotUsernameContext";
import CloseModal from "./CloseModal";
import ForgotUsernameMenu from "./ForgotUsernameMenu";
import ForgotUsernameHeader from "./ForgotUsernameHeader";
import AuthenticationPage from "./AuthenticationPage";
import SuccessPageForgotUsername from "./SuccessPage";
import WebchatMenu from "../Webchat/WebchatMenu/WebchatMenu";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";



function ForgotUsernameWizard({ postActivity, sendActivity, forgotUsernameEvent }) {
  return (
    <ForgotUsernameContextProvider>
      <ForgotUsernameWizardHelper
        postActivity={postActivity}
        sendActivity={sendActivity}
        forgotUsernameEvent={forgotUsernameEvent}
      />
    </ForgotUsernameContextProvider>
  );
}

export const PAGES = {
  WELCOME: 0,
  AUTHENTICATION: 1,
  SUCCESS: 2,
};

function ForgotUsernameWizardHelper({ postActivity, sendActivity }) {
  const {
    close,
    currentPage,
    setCurrentPage,
    next,
    previous,
    closeModal,
    openRelativeCloseModal,
  } = useForgotUsername();

  const { forgotUsernameEvent, openForgotUsernameWizard, closeForgotUsernameWizard } = useChatbot();
  const [isUserNotFound, setIsUserNotFound] = useState(false);
  const [userNotFoundMessage, setUserNotFoundMessage] = useState("");
  const { currentLanguage } = useLanguage();

  const buttontranslations = translations[currentLanguage]["Button"];

  useEffect(() => {
    if (forgotUsernameEvent.name === "openForgotUsernameWelcomePage") {

        setCurrentPage(PAGES.WELCOME);    
    } 
  }, [forgotUsernameEvent, setCurrentPage]);

  useEffect(() => {
    if (forgotUsernameEvent.data && forgotUsernameEvent.flag === "userNotFound") {

      setIsUserNotFound(true);
      setUserNotFoundMessage(forgotUsernameEvent.data.userNotFound);
      return;
    }
    if (forgotUsernameEvent.name === "openWelcomePage") {

      return;
    }
      return;
  }, [forgotUsernameEvent]);

  useEffect(() => {

    if(forgotUsernameEvent.data && forgotUsernameEvent.flag === "userNotFound") {
      return;
    }
    if(forgotUsernameEvent.name === "openForgotUsernameWelcomePage") {
      return
    }

  }, [forgotUsernameEvent]);

  useEffect(() => {
    if (forgotUsernameEvent.name === "cancelForgotUsername") {
      sendActivity({type: "message", value: { intentName: "cancelTransaction" }});
    }
    if (forgotUsernameEvent.name === 'cancelConfirmed'){
      closeForgotUsernameWizard();
    }
  }, [forgotUsernameEvent]);


  const getContent = () => {

    if (forgotUsernameEvent.name === "openForgotUsernameWelcomePage" && !isUserNotFound) {
      setCurrentPage(PAGES.WELCOME);
    }
    if (forgotUsernameEvent.name === "openAuthenticationPage" || (forgotUsernameEvent.name === "openForgotUsernameWelcomePage" && isUserNotFound)) {
   
      setCurrentPage(PAGES.AUTHENTICATION);
    }
    if (forgotUsernameEvent.name === "openSuccessPage") {

      setCurrentPage(PAGES.SUCCESS);
    }

    return (
            <>
            {currentPage === 0 && (
                <WelcomePageForgotUsername
                postActivity={postActivity}
                sendActivity={sendActivity}
                onNext={() =>
                    openForgotUsernameWizard({
                    name: "openAuthenticationPage",
                    data: {},
                    })
                }
                onClose={close}
                />
            )}
            {currentPage === 1 && (
          <AuthenticationPage
            postActivity={postActivity}
            sendActivity={sendActivity}
            onNext={next}
            onPrevious={() =>
              openForgotUsernameWizard({ name: "openForgotUsernameWelcomePage", data: {} })
            }
            userNotFound={isUserNotFound ? userNotFoundMessage : ""}
          />
        )}
        {currentPage === 2 && (
          <SuccessPageForgotUsername
          postActivity={postActivity}
          sendActivity={sendActivity}
          forgotUsernameEvent={forgotUsernameEvent}
          onNext={close}
        /> )}
            
            </>
        );
    };

    const handleClick = () => {

      openRelativeCloseModal();
    };
  

 

  return (
    <ChatbotLayout>
          <WebchatMenu />
          {closeModal.isOpen && <CloseModal type={closeModal.type} />}
          <ChatbotLayout.Header>
            <ForgotUsernameHeader />
            </ChatbotLayout.Header>
            <ChatbotLayout.Wizard>
            <WizardLayout>
                <WizardLayout.Header>
                    <Header>
                        <div className={styles.title} onClick={handleClick}>
                            {buttontranslations.returnToChat}
                        </div>
                    </Header>
                </WizardLayout.Header>
                {getContent()}
            </WizardLayout>
        </ChatbotLayout.Wizard>
    </ChatbotLayout>
  );
}

export default ForgotUsernameWizard;