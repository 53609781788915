import React, { useState, useEffect } from "react";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Header from "../../UiKit/Wizard/Header/Header";
import M2CMenu from "./M2CMenu";
import useM2C from "../../Hooks/useM2C";
import WelcomePage from "./WelcomePage";
import AuthenticationPage from "./AuthenticationPage";
import KeepOrCancelPage from "./KeepOrCancelPage";
import AgreePlanPage from "./AgreePlanPage";
import SourceOfHealthCoveragePage from "./SourceOfHealthCoveragePage";
import ReviewAndConfirmPage from "./ReviewAndConfirmPage";
import KeepOrCancelConfirmationPage from "./KeepOrCancelConfirmationPage";
import FeedbackPage from "./FeedbackPage";
import ThankYouPage from "./ThankYouPage";
import ServiceErrorPage from "./ServiceErrorPage";
import ContactSupportPage from "./ContactSupportPage";
import KeepConfirmationPage from "./KeepConfirmationPage";
import CancelConfirmationPage from "./CancelConfirmation";
import TimeoutPage from "./TimeoutPage";
import styles from "../../UiKit/Wizard/Header/Header.module.css";
import ChatbotLayout from "../../UiKit/Chatbot/ChatbotLayout/ChatbotLayout";
import M2CHeader from "./M2CHeader";
import M2CContext from "../../Contexts/M2CContext";
import useWebChat from "../../Hooks/useWebChat";
import CloseModal from "./CloseModal";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";
import sendEventToDataLayer from "../../___archive/datalayer-event-logger-for-transactions/sendEventToDataLayer";
import Loading from "../../UiKit/Wizard/Loading/Loding";
import NoAPSContactSupportPage from "./NoAPSContactSupportPage";
import KeepOrCancelSelectPage from "./KeepOrCancelSelectPage";
import KeepConfirmationZeroPremium from "./KeepConfirmationZeroPremium";
import WebChatMenu from "../Webchat/WebchatMenu/WebchatMenu";

const mockSb260Event = {
  name: "SHOW_SERVICE_TIMEOUT_ERROR",
  data: {},
};

function M2CWizard({ sendActivity, Sb260Event, sendLoggingEventToBackend }) {
  return (
    <M2CContext>
      <M2CWizardToolHelper
        sendActivity={sendActivity}
        sb260Event={Sb260Event}
        sendLoggingEventToBackend={sendLoggingEventToBackend}
      />
    </M2CContext>
  );
}
export const Pages = {
  AUTHENTICATION: 1,
  KEEPORCANCEL: 2,
  AGREEPLAN: 3,
  SOURCEOFHEALTHCOVERAGE: 4,
  REVIEWANDCONFIRM: 5,
  KEEPORCANCELCONFIRMATION: 6,
  KEEPCONFIRMATION: 7,
  CANCELCONFIRMATION: 8,
  FEEDBACK: 9,
  THANKYOUPAGE: 10,
  SERVICEERROR: 11,
  CONTACTSUPPORT: 12,
  TIMEOUT: 13,
  SHOW_NO_APS_INDIVIDUAL: 14,
  KEEPORCANCELSELECT: 15,
  KEEPCONFORMATIONZERO: 16,
};

function M2CWizardToolHelper({ sendActivity, sendLoggingEventToBackend }) {
  const {
    status,
    currentPage,
    M2CWizardEventData,
    setCurrentPage,
    sb260Event,
    close,
    closeModal,
    openRelativeCloseModal,
  } = useM2C();
  const [authenticationData, setAuthenticationData] = useState({
    ssn: "",
    dob: "",
    zipCode: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [planDetails, setPlanDetails] = useState();
  const [memberDecisions, setMemberDecisions] = useState(null);
  const [keepEnrollmentYear, setkeepEnrollmentYear] = useState(null);
  const { store } = useWebChat();
  const [hasCancelMembers, setHasCancelMembers] = useState(false);
  const [hasKeepMembers, setHasKeepMembers] = useState(false);
  const [enrollments, setEnrollments] = useState([]);
  const [otherText, setOtherText] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const { currentLanguage } = useLanguage();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const introPageTranslations =
    translations[currentLanguage]["RetunToChatButton"];


  const handleMemberDecisions = (decision) => {
    setMemberDecisions(decision); // Store the decision made by the user

    if (decision === "keep") {
      setHasKeepMembers(true);
      setHasCancelMembers(false);
  } else {
    setHasCancelMembers(true);
    setHasKeepMembers(false);  
  }
};

const handleNext = () => {

  // Check the value of hasKeepMembers and update the current page accordingly
  if (hasKeepMembers) {
      setCurrentPage(Pages.AGREEPLAN);  // Navigate to the AGREEPLAN page if hasKeepMembers is true
  } else {
      setCurrentPage(Pages.SOURCEOFHEALTHCOVERAGE);  // Navigate to the SOURCEOFHEALTHCOVERAGE page if false
  }
};


  const handleConfirm = (updatedEnrollments) => {
    setEnrollments(updatedEnrollments);
  };

  const eventNameMessageMap = {
    SHOW_SERVICE_ERROR:
      "Oops, something went wrong. Please come back later or use the 'Help' option for support.",
    SHOW_USER_NOT_FOUND:
      "Account could not be found. Try again or call (855) 708-4590 for help.",
  };

  const eventNameMessageMapEs = {
    SHOW_SERVICE_ERROR:
      "Algo salió mal. Por favor vuelve más tarde o utiliza la opción de 'Ayuda' para obtener apoyo.",
    SHOW_USER_NOT_FOUND:
      "No se pudo encontrar la cuenta. Vuelve a intentarlo o llama al (855) 708-4590 para obtener ayuda",
  };

  const getEventNameMessageMap = (event) => {
    const eventMap =
      currentLanguage === "es" ? eventNameMessageMapEs : eventNameMessageMap;
    if (
      [
        "SHOW_SERVICE_ERROR",
        "SHOW_SERVICE_TIMEOUT_ERROR",
        "SHOW_SERVICE_UNAVAILABLE_ERROR",
      ].includes(event.name)
    ) {
      return eventMap["SHOW_SERVICE_ERROR"];
    }
    return eventMap[event.name];
  };

  const handlePlanDetailsUpdate = (newPlanDetails) => {
    setPlanDetails(newPlanDetails);
  };

  const updatekeepEnrollmentYear = (year) => {
    setkeepEnrollmentYear(year);
  };

  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };
  useEffect(() => {
    if (sb260Event && sb260Event.name === "SHOW_START") {
    }
  }, [sb260Event]);

  useEffect(() => {
    if (sb260Event && sb260Event.name === "SHOW_START") {
      setCurrentPage(Pages.WELCOME);
    }
  }, [sb260Event]);

  const authenticate = async ({ dob, ssn, zip }) => {
    setIsLoading(true);
    await sendActivity({
      type: "message",
      value: { wizard: "sb260", dob, ssn, zip },
    });
  };

  const optIn = async () => {
    setIsLoading(true);
    await sendActivity({
      type: "message",
      value: {
        wizard: "sb260",
        consentYears: 5,
        optIn: true,
        memberDecisions: enrollments,
      },
    });
  };

  const optOut = async (optOutReasonCode) => {
    setIsLoading(true);
    await sendActivity({
      type: "message",
      value: {
        wizard: "sb260",
        optIn: false,
        optOutReasonCode: optOutReasonCode,
        memberDecisions: enrollments,
      },
    });
  };

  const handleKeepOrCancelPage = (page) => {
    setCurrentPage(Pages.KEEPORCANCELSELECT);
  };

  const handleSetCurrentPage = (page) => {
    setCurrentPage(page);
  };
  const handleSelectedOptionChange = (newOption) => {
    setSelectedOption(newOption);
  };

  useEffect(() => {
    setIsLoading(false);
    if (sb260Event.name === "SHOW_START") {
      setCurrentPage(0);
    }
    if (sb260Event.name === "SHOW_AUTHENTICATE_SUCCESS") {
      setPlanDetails({
        plan: sb260Event.data.plan,
        monthlyPremium: sb260Event.data.monthlyPremium,
        isZeroPremium: sb260Event.data.isZeroPremium,
        enrollmentYear: sb260Event.data.enrollmentYear,
        ...sb260Event.data,
      });
    }
    if (sb260Event.name === "SHOW_AUTHENTICATE_SUCCESS") {
      setCurrentPage(Pages.KEEPORCANCELSELECT);
    }

    const eventActions = {
      SHOW_UNSUPPORTED_SCENARIO: {
        setPage: Pages.CONTACTSUPPORT,
        logEvent: "M2CFlowUnsupportedScenario",
      },
      SHOW_NO_APS_INDIVIDUAL: {
        setPage: Pages.SHOW_NO_APS_INDIVIDUAL,
        logEvent: "M2CFlowNoApsIndividual",
      },
      SHOW_USER_NOT_FOUND: {
        setPage: Pages.AUTHENTICATION,
        logEvent: "M2CFlowUserNotFound",
      },
      SHOW_SERVICE_TIMEOUT_ERROR: {
        setPage: Pages.TIMEOUT,
        logEvent: "KeepFlowTimeOutError",
        dataLayerEvent: "cici_sb260_timeout_error",
      },
      SHOW_SERVICE_ERROR: {
        setPage: Pages.SERVICEERROR,
        logEvent: "APIServiceError",
        dataLayerEvent: "cici_sb260_service_error",
      },
      SHOW_SERVICE_UNAVAILABLE_ERROR: {
        setPage: Pages.SERVICEERROR,
        logEvent: "APIServiceUnavailableError",
        dataLayerEvent: "cici_sb260_service_unavailable_error",
      },
    };

    const action = eventActions[sb260Event.name];

    if (action) {
      setCurrentPage(action.setPage);

      if (action.logEvent) {
        sendLoggingEventToBackend({
          intentName: action.logEvent,
          text: action.logEvent,
        });
      }

      if (action.dataLayerEvent) {
        sendEventToDataLayer({
          event: "chatbot",
          eventDetails: {
            name: action.dataLayerEvent,
          },
        });
      }

      return;
    }
  }, [sb260Event.data, sb260Event.name]);

  const getContent = () => {
    if (status === null) {
      return <div>Loading</div>;
    }
    if (status === "error") {
      return <div>Error</div>;
    }
    return (
      <>
        {currentPage === 0 && <WelcomePage 
          onNext={() => {
            setCurrentPage(Pages.AUTHENTICATION);
          }}
          sendLoggingEventToBackend={sendLoggingEventToBackend}
        />}
        {currentPage === 1 && (
          <AuthenticationPage
            onSubmit={authenticate}
            data={planDetails}
            authenticationData={authenticationData}
            setAuthenticationData={setAuthenticationData}
            sendLoggingEventToBackend={sendLoggingEventToBackend}
            error={
              getEventNameMessageMap(sb260Event)
                ? htmlDecode(getEventNameMessageMap(sb260Event))
                : null
            }
          />
        )}
        {currentPage === 15 && (
          <KeepOrCancelSelectPage
            data={planDetails}
            setMemberDecisions={handleMemberDecisions}
            currentMemberDecisions={memberDecisions}
            onPlanDetailsUpdate={handlePlanDetailsUpdate}
            updatekeepEnrollmentYear={updatekeepEnrollmentYear}
            onNext={handleNext}
          />

        )}
        {currentPage === 2 && (
          <KeepOrCancelPage
            data={planDetails}
            setMemberDecisions={setMemberDecisions}
            currentMemberDecisions={memberDecisions}
            onPlanDetailsUpdate={handlePlanDetailsUpdate}
            updatekeepEnrollmentYear={updatekeepEnrollmentYear}
            onNext={() => {
              if (hasKeepMembers) setCurrentPage(Pages.AGREEPLAN);
              else setCurrentPage(Pages.SOURCEOFHEALTHCOVERAGE);
            }}
          />
        )}
        {currentPage === 3 && (
          <AgreePlanPage
            data={planDetails}
            setCurrentPage={setCurrentPage}
            onSubmit={optIn}
            memberDecisions={memberDecisions}
            keepEnrollmentYear={keepEnrollmentYear}
            onConfirm={handleConfirm}
            eventName={sb260Event.name}
            onNext={() => {
              if (hasCancelMembers && hasKeepMembers)
                setCurrentPage(Pages.SOURCEOFHEALTHCOVERAGE);
              else if (hasKeepMembers) setCurrentPage(Pages.REVIEWANDCONFIRM);
              else if (hasCancelMembers)
                setCurrentPage(Pages.SOURCEOFHEALTHCOVERAGE);
              else setCurrentPage(Pages.REVIEWANDCONFIRM);
            }}
            onBack={handleKeepOrCancelPage}
            acceptedTerms={acceptedTerms}
            setAcceptedTerms={setAcceptedTerms}
            sendLoggingEventToBackend={sendLoggingEventToBackend}
          />
        )}
        {currentPage === 4 && (
          <SourceOfHealthCoveragePage
            handleSetCurrentPage={handleSetCurrentPage}
            onNext={() => setCurrentPage(Pages.REVIEWANDCONFIRM)}
            onBack={() => {
              if (hasKeepMembers) setCurrentPage(Pages.AGREEPLAN);
              else setCurrentPage(Pages.KEEPORCANCELSELECT);
            }}
            onOptOut={optOut}
            initialSelectedOption={selectedOption}
            selectedOption={selectedOption}
            handleSelectedOptionChange={handleSelectedOptionChange}
            data={planDetails}
            setCurrentPage={setCurrentPage}
            eventName={sb260Event.name}
            initialOtherText={otherText}
            handleOtherTextChange={setOtherText}
            memberDecisions={memberDecisions}
            onConfirm={handleConfirm}
            sendLoggingEventToBackend={sendLoggingEventToBackend}
          />
        )}
        {currentPage === 5 && (
          <ReviewAndConfirmPage
            data={planDetails}
            memberDecisions={memberDecisions}
            setCurrentPage={setCurrentPage}
            eventName={sb260Event.name}
            updatekeepEnrollmentYear={updatekeepEnrollmentYear}
            onConfirm={handleConfirm}
            onSubmit={optIn}
            eventCode={sb260Event.code}
            onOptOut={optOut}
            selectedOption={selectedOption}
            onClose={close}
            onBack={() => {
              if (hasCancelMembers && hasKeepMembers) {
                setCurrentPage(Pages.SOURCEOFHEALTHCOVERAGE);
              } else if (hasKeepMembers) {
                setCurrentPage(Pages.AGREEPLAN);
              } else {
                setCurrentPage(Pages.SOURCEOFHEALTHCOVERAGE);
              }
            }}
            onNext={async () => {
              //await optIn();
              if (hasKeepMembers) {
                if (planDetails.isZeroPremium) {
                  setCurrentPage(Pages.KEEPCONFORMATIONZERO);
                }
                else{
                  setCurrentPage(Pages.KEEPCONFIRMATION);
                }
                
              } else {
                setCurrentPage(Pages.CANCELCONFIRMATION);
              }
            }}
            sendLoggingEventToBackend={sendLoggingEventToBackend}
          />
        )}
        {currentPage === 6 && (
          <KeepOrCancelConfirmationPage
            data={planDetails}
            setCurrentPage={setCurrentPage}
            onNext={() => setCurrentPage(Pages.FEEDBACK)}
            onClose={close}
            memberDecisions={memberDecisions}
            sendLoggingEventToBackend={sendLoggingEventToBackend}
          />
        )}  
        {currentPage === 7 && <KeepConfirmationPage 
           data={planDetails}
           setCurrentPage={setCurrentPage}
           onNext={() => setCurrentPage(Pages.FEEDBACK)}
           onClose={close}
           memberDecisions={memberDecisions}
           sendLoggingEventToBackend={sendLoggingEventToBackend}
        />}
        {currentPage === 8 && <CancelConfirmationPage 
           data={planDetails}
           setCurrentPage={setCurrentPage}
           onNext={() => setCurrentPage(Pages.FEEDBACK)}
           onClose={close}
           memberDecisions={memberDecisions}
           sendLoggingEventToBackend={sendLoggingEventToBackend}
        />}
        {currentPage === 9 && (
          <FeedbackPage
            onClose={close}
            onNext={() => setCurrentPage(Pages.THANKYOUPAGE)}
            sendLoggingEventToBackend={sendLoggingEventToBackend}
          />
        )}
        {currentPage === 10 && <ThankYouPage onNext={close} />}
        {currentPage === 11 && <ServiceErrorPage onNext={close} />}
        {currentPage === 12 && <ContactSupportPage onNext={close} />}
        {currentPage === 13 && <TimeoutPage onNext={close} />}
        {currentPage === 14 && <NoAPSContactSupportPage onNext={close} />}
        {currentPage === 16 && 
          <KeepConfirmationZeroPremium
            data={planDetails}
            setCurrentPage={setCurrentPage}
            onNext={() => setCurrentPage(Pages.FEEDBACK)}
            onClose={close}
            memberDecisions={memberDecisions}
            sendLoggingEventToBackend={sendLoggingEventToBackend}
           
            />}
      
      </>
    );
  };

  const handleClick = () => {
    openRelativeCloseModal();
  };

  return (
    <ChatbotLayout>
      <WebChatMenu />
      {closeModal.isOpen && <CloseModal type={closeModal.type} />}
      <ChatbotLayout.Header>
        <M2CHeader />
      </ChatbotLayout.Header>
      <ChatbotLayout.Wizard>
        <WizardLayout>
          <WizardLayout.Header>
            <Header>
              <div className={styles.title} onClick={handleClick}>
                {introPageTranslations}
              </div>
            </Header>
          </WizardLayout.Header>
          {isLoading && <Loading />} 
          {getContent()}
        </WizardLayout>
      </ChatbotLayout.Wizard>
    </ChatbotLayout>
  );
}

export default M2CWizard;
