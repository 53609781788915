import React from "react";
import classes from "./HeaderCloseButton.module.css";
import closeIcon from "../../../../Images/header-close-icon.svg";

function HeaderCloseButton({ onClick }) {
    const altText = "Close";
    const onCloselick = () => {
        onClick();
    };
    return (
        <button 
        tabIndex = "0"
         className={`${classes['header-close-button']}`} onClick={onCloselick} aria-label={altText}>
            <img src={closeIcon} alt={altText} />
        </button >
    );
}

export default HeaderCloseButton;