import React, { useState } from 'react';

function PasscodeAttachment(props){
  const [otpType, setOtpType] = useState('email');
  const [isDisabled, setIsDisabled] = useState(false);
  const [useExpiredToken, setUseExpiredToken] = useState(false);
  const [isUsedExpiredTokenVisible, setIsUsedExpiredTokenVisible] = useState(false);

  const onSelect = (event) => {
    setOtpType(event.currentTarget.value);
  }

  const onSubmit = () => {
    props.postActivity({
      type: 'message',
        value: {
          type: 'forgotPasswordCardClick',
          otpType: otpType,
          intentName: 'forgotPassword',
          useExpiredToken: useExpiredToken
        }
    }); 
    setIsDisabled(true);
  }

  const verificationData = props.activity.attachments[0].content.verificationData;
  const redactedPhone = verificationData ? verificationData.phone : null;
  const redactedEmail = verificationData ? verificationData.email : null;

  return(
    <div className="container">
      <div className="row">
        <div className="col">
          <p>
           To verify your identity, how would you like to receive your one-time passcode?
          </p>
          <form className="form">
            <div class="form-check">
              <input disabled={isDisabled} class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="email" onChange={(e)=>onSelect(e)} checked={otpType === 'email'}/>
              <label class="form-check-label" for="flexRadioDefault1">
                To your email: {redactedEmail ? redactedEmail : ""}
              </label>
            </div>
            <div class="form-check">
              <input disabled={isDisabled} class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="phone" onChange={(e)=>onSelect(e)} checked={otpType === 'phone'} />
              <label class="form-check-label" for="flexRadioDefault2">
                To your cell phone number: {redactedPhone ? redactedPhone : ""}<br />
                  *Standard text message rate applies
              </label>
            </div>
            {isUsedExpiredTokenVisible ? 
              <div>
                <input
                    id="use-expired-token"
                    type="checkbox"
                    checked={useExpiredToken}
                    onChange={()=>setUseExpiredToken(!useExpiredToken)}
                />
                <label for="use-expired-token">use expired token</label>
              </div>
            : null}
            <button disabled={isDisabled} type="button" className={isDisabled ? "btn btn-block btn-disabled rounded my-3" :"btn btn-block ac-pushButton style-default my-3"} onClick={()=>onSubmit()}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default PasscodeAttachment;