import useChatbot from "./useChatbot";

const useM2CActionHandler = () => {
    const { openM2CWizard } = useChatbot();

    const handleM2CAction = (action) => {
        openM2CWizard(action.payload.activity.value);
    }

    return {
        handleM2CAction
    }
}

export default useM2CActionHandler;