// Desc: Welcome page for the forgot username flow
import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useForgotUsername from "../../Hooks/useForgotUsername";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";

import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";
import useChatbot from "../../Hooks/useChatbot";

function WelcomePageForgotUsername({ onNext }) {
    const { currentLanguage } = useLanguage();
    const { closeForgotUsername } = useForgotUsername();
    const {closeForgotUsernameWizard} = useChatbot();
    
    const welcomePageTranslations = translations[currentLanguage]["WelcomePage"];
    const buttontranslations = translations[currentLanguage]["Button"];
    return (
        <PageLayout>
            <PageLayout.Content>
                <Content welcomePageTranslations={welcomePageTranslations} />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton  onClick={() => onNext()} type={"primary"}>{buttontranslations.begin}</NavigationButton>
                <NavigationButton onClick={() => closeForgotUsername()} type={"secondary"}>{buttontranslations.exit}</NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

function Content({welcomePageTranslations}){

    return (
        <div
            style={{
                fontSize: '21px',
                lineHeight: '29.4px',
                fontWeight: 'normal',
                margin: '0',
            }}
        >
            <SubHeader
                style={{ display: 'flex', marginBottom: '8px', marginTop: '0px' }}
                subHeader={welcomePageTranslations.title}
            />
            <Body
                bodyText={
                    <>
                    <div style={{ marginTop: '10px' }}>
                        {welcomePageTranslations.body}
                        {/* <a href="/forgot-username">Forgot your username?</a> */}
                    </div>
                    <div style={{ marginTop: '32px' }}>
                        {welcomePageTranslations.body2}
                    </div>
                </>
                }
            />
        </div>
    );
}

export default WelcomePageForgotUsername;