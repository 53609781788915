import useChatbot from './useChatbot';

const useForgotUsernameActionHandler = () => {
  
    const { openForgotUsernameWizard } = useChatbot();

    const handleForgotUsernameAction = (action) => {
     
        if(usernameDobAttachment(action)){
           
            openForgotUsernameWizard({name: "openForgotUsernameWelcomePage", data: action.payload.activity});
            return;
        }

        if(isCancel(action)){
        
            openForgotUsernameWizard({ name: "cancelForgotUsername", data: action.payload.activity });
            return;
        }
        if(_isCancelConfirmed(action)){

            openForgotUsernameWizard({ name: "cancelConfirmedForgotUsername", data: action.payload.activity });
            return;
        }
       
        if (!action.payload.activity.attachments) {

            if (_isSuccessfulForgotUsername(action)) {
               // console.log("Opening success page");
                openForgotUsernameWizard({ name: "openSuccessPage", data: {} });
            
            return;
            }
            if (_isUserNotFound(action)) {
         
                openForgotUsernameWizard({
                    name: "openAuthenticationPage",
                    data: { userNotFound: "We could not find an account with this information." },
                    flag: "userNotFound",
                });
                return;
            }
        }

        const attachment = action.payload.activity.attachments[0];
        //console.log("attachment-------------------", attachment.type);
        if (attachment.contentType !== "application/vnd.microsoft.card.adaptive") {
        
            switch (attachment.contentType) {
                case "application/usernameDobCardForgotUsername":
             
                    openForgotUsernameWizard({
                        name: "openForgotUsernameWelcomePage",
                        data: action.payload.activity,
                    });
                    break;
                default:

                    openForgotUsernameWizard({
                        name: "startWizard",
                        data: action.payload.activity,
                    });
            }
        } else {
            const adaptiveCardContent = attachment.content;
            const textBlock = adaptiveCardContent.body.find(
                (bodyElement) => bodyElement.type === "TextBlock"
            );
            if (textBlock) {
                const bodyText = textBlock.text.trim();
                switch (bodyText) {
                    case "You session has expired. Are you ready to continue resetting your password?":
                        openForgotUsernameWizard({
                            name: "openServiceCenterPage",
                            data: action.payload.activity,
                        });
                        break;
                    default:
                        console.log("default");
                        openForgotUsernameWizard({
                            name: "startWizard",
                            data: action.payload.activity,
                        });
                }
            }
        }
    }

    const usernameDobAttachment = (action) => {
        //console.log("usernameDobAttachment *******************", action.payload.activity.attachments[0].contentType);
        if(!_hasAttachments(action)) return false;
        if(action.payload.activity.attachments[0].contentType !== "application/usernameDobCardForgotUsername") return false;
        return true;
    }
    const _isSuccessfulForgotUsername = (action) => {
    
        if(action.payload.activity.text === "If you do not get an email with your username, or cannot login, please reach out to the Covered California Service Center at 1-855-708-4590") return true;
        return false;
    }
    const _hasAttachments = (action) => {
       // console.log("hasAttachments------->", action.payload.activity.attachments && action.payload.activity.attachments.length > 0);
        if(action.payload.activity.attachments && action.payload.activity.attachments.length > 0) return true;
        return false;
    }
    const isCancel = (action) => {
        if(!_hasAttachments(action)) return false;
        if(!_isAdaptiveCard(action.payload.activity.attachments[0])) return false;
        const attachment = action.payload.activity.attachments[0];
        const adaptiveCardContent = attachment.content;
        const textBlock = adaptiveCardContent.body.find(
            (bodyElement) => bodyElement.type === "TextBlock"
        );
        if(!textBlock) return false;
        const bodyText = textBlock.text.trim();
        if(!bodyText) return false;
        if(bodyText !== "Are you sure you want to cancel retrieving you username?") return false;
        return true;
    }
    const _isAdaptiveCard = (attachment) => {
        if(attachment.contentType === "application/vnd.microsoft.card.adaptive") return true;
        return false;
    }
    const _isCancelConfirmed = (action) => {

        if (action.payload.activity.text === "No problem. What else can I help you with?"){
            
            return true;
        }
        return false;
    }
    const _isUserNotFound = (action) => {
    
        if (
            action.payload.activity.text ===
            "We could not find an account with this information on file. Please try again."
        ) {
            return true;
        }
    };
    //console.log("return", handleForgotUsernameAction);
    return {
        
        handleForgotUsernameAction
    }
}

export default useForgotUsernameActionHandler;