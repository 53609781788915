import { useContext } from "react";
import { AffordabilityToolContext } from "../Contexts/AffordabilityToolContext";
import { ChatbotContext } from "../Contexts/ChatbotContext";
import AffordabilityToolService from "../Services/AffordabilityToolService";
import getContributionFrequencyFromString from "../Utilities/getContributionFrequencyFromString";

import useAffordabilityToolContext from './useAffordabilityToolContext';
import useChatbot from './useChatbot';
import useAffordabilityToolInApp from "./useAffordabilityToolInApp";
import { useLanguage} from "../UiKit/Wizard/langugeContext";

function useAffordabilityTool() {

 // const affordabilityToolHookInApp = useAffordabilityToolInApp();clear
 
  const chatbotHook = useChatbot();
  const { currentLanguage } = useLanguage();

  const {
    isAffordabilityToolMenuOpen,
    setIsAffordabilityToolMenuOpen,
    currentPage,
    setCurrentPage,
    setCoverageYear,
    coverageYear,
    annualHouseholdIncome,
    setAnnualHouseholdIncome,
    isEmployee,
    setIsEmployee,
    contributionAmount,
    setContributionAmount,
    contributionFrequency,
    setContributionFrequency,
    esiOffers,
    setEsiOffers,
    name,
    setName,
    setHouseholdMemberType,
    householdMemberType,
    closeModal, 
    setCloseModal,
    helpModal,
    setHelpModal,
    offBusinessHoursHelpModal,
    setOffBusinessHoursHelpModal
  } = useAffordabilityToolContext();

  const { setIsAffordabilityToolWizardOpen } = useContext(ChatbotContext);

  const next = () => {
    if (currentPage > 7) return;
    setCurrentPage(currentPage + 1);
  };

  const previous = () => {
    if (currentPage === 0) return;
    setCurrentPage(currentPage - 1);
  };

  const close = () => {
    setIsAffordabilityToolWizardOpen(false);
  };

  const restart = () => {
    setCurrentPage(0);
    setCoverageYear("");
    setAnnualHouseholdIncome("");
    setIsEmployee(null);
    setContributionAmount("");
    setContributionFrequency("");
    setName("");
    setHouseholdMemberType("");
  };

  const updateCoverageYear = (year) => {
    setCoverageYear(year);
  };

  const updateHouseholdIncome = (income) => {
    setAnnualHouseholdIncome(income);
  };

  const updateIsEmployee = (isEmployee) => {
    setIsEmployee(isEmployee);
  };

  const updateContributionCost = (cost) => {
    setContributionAmount(cost);
  };

  const updateContributionFrequency = (frequency) => {
    setContributionFrequency(frequency);
  };

  const updateEsiOffers = (offers) => {
    setEsiOffers(offers);
  };

  const updateHouseholdMember = (householdMember) => {
    setHouseholdMemberType(householdMember);
  };

  // const addCurrentOffer = () => {
  //   const contributionPercentageOfIncome = AffordabilityToolService.getContributionPercentageOfIncome(
  //     annualHouseholdIncome,
  //     contributionAmount,
  //     getContributionFrequencyFromString(contributionFrequency)
  //   );

  const addCurrentOffer = () => {
    const frequencyValue = getContributionFrequencyFromString(contributionFrequency, currentLanguage);
    const contributionPercentageOfIncome = AffordabilityToolService.getContributionPercentageOfIncome(
        annualHouseholdIncome,
        contributionAmount,
        frequencyValue
    );


    const isAffordable = AffordabilityToolService.isOfferAffordable(contributionPercentageOfIncome, coverageYear);

    const newOffer = {
      name,
      coverageYear,
      annualHouseholdIncome,
      isEmployee,
      contributionAmount,
      contributionFrequency,
      contributionPercentageOfIncome,
      householdMemberType,
      isAffordable,
    };

    updateEsiOffers([...esiOffers, newOffer]);
  };

  const getContributionPercentageOfIncome = () => {
    return AffordabilityToolService.getContributionPercentageOfIncome(
      annualHouseholdIncome,
      contributionAmount,
      contributionFrequency
    );
  };

  const getAffordabilityResults = () => {
    return AffordabilityToolService.isAnyOfferAffordable(esiOffers);
  };

  const getCurrentOffer = () => {
    return esiOffers[esiOffers.length - 1];
  };

  const isEmployeeValid = (isEmployee) => {
    return isEmployee !== "" && isEmployee !== null && isEmployee !== undefined;
  };

  const isContributionAmountValid = (contributionAmount) => {
    return contributionAmount !== "" && !isNaN(contributionAmount);
  };

  const isContributionFrequencyValid = (contributionFrequency) => {
    return contributionFrequency !== "";
  };

  const toggleAffordabilityToolMenu = () => {
    setIsAffordabilityToolMenuOpen(!isAffordabilityToolMenuOpen);
}   
   
  const openFixedCloseModal = () => {
      setCloseModal({isOpen: true, type: "fixed"});
  };
  const openRelativeCloseModal = () => {
      setCloseModal({isOpen: true, type: "relative"});
  };
  const closeCloseModal = () => {
      setCloseModal({isOpen: false, type: null});
  }

  const openFixedHelpModal = () => {
    setHelpModal({isOpen: true, type: "fixed"});
  };
  const openRelativeHelpModal = () => {
    setHelpModal({isOpen: true, type: "relative"});
  };

  const closePhoneSupportModal = () => {
    setHelpModal({isOpen: false, type: null});
  }

  const openFixedOffHoursHelpModal = () => {
    setOffBusinessHoursHelpModal({isOpen: true, type: "fixed"});
  };
  const openRelativeOffHoursHelpModal = () => {
    setOffBusinessHoursHelpModal({isOpen: true, type: "relative"});
  };

  const closePhoneSupportOffHoursModal = () => {
    setOffBusinessHoursHelpModal({isOpen: false, type: null});
  }

  return {
    name,
    currentPage,
    setCurrentPage,
    coverageYear,
    annualHouseholdIncome,
    isEmployee,
    contributionAmount,
    contributionFrequency,
    esiOffers,
    isAffordabilityToolMenuOpen,
    closeModal,
    closeCloseModal,
    openFixedCloseModal,
    openRelativeCloseModal,
    updateEsiOffers,
    updateCoverageYear,
    updateHouseholdIncome,
    updateIsEmployee,
    updateContributionCost,
    updateContributionFrequency,
    updateHouseholdMember,
    addCurrentOffer,
    getCurrentOffer,
    next,
    previous,
    close,
    restart,
    getContributionPercentageOfIncome,
    getAffordabilityResults,
    isEmployeeValid,
    isContributionAmountValid,
    isContributionFrequencyValid,
    toggleAffordabilityToolMenu,
    householdMemberType,
    helpModal,
    openFixedHelpModal,
    openRelativeHelpModal,
    closePhoneSupportModal,
    openFixedOffHoursHelpModal,
    openRelativeOffHoursHelpModal,
    closePhoneSupportOffHoursModal,
    offBusinessHoursHelpModal,
    ...chatbotHook
  };
}

export default useAffordabilityTool;
