import React, { useRef } from 'react';
import classes from './SuggestedActions.module.css';

function SuggestedActions(props) {

    const suggestedActionsRef = useRef();

    const hide = () => {
        suggestedActionsRef.current.remove();
    }

    const sendMessage = (message) => {
        props.store.dispatch({
            type: "WEB_CHAT/SEND_MESSAGE",
            payload: {
                text: message + '|||||suggestedaction',
            },
        });
        props.context.scrollToEnd();
    }

    const onSuggestedActionClick = (event, message) => {

        event.preventDefault();
        sendMessage(message);
        hide();
    }

    return (
        <div className={classes['suggested-actions']} ref={suggestedActionsRef}>
            {props.activity.suggestedActions.actions.map((action) => (
                <button
                    key={action.value}
                    type="button"
                    className={classes['suggested-actions__action']}
                    onClick={(event) => onSuggestedActionClick(event, action.value)}
                >
                    {action.title}
                </button>
            ))}
        </div>
    );
}

export default SuggestedActions;