// enrollment status contxt
import React, { createContext, useState } from "react";

export const EnrollmentStatusContext = createContext();

const EnrollmentStatusContextProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [coverageYear, setCoverageYear] = useState("");
    const [dob, setDob] = useState('');
    const [ssnPart1, setSsnPart1] = useState('');
    const [ssnPart2, setSsnPart2] = useState('');
    const [ssnPart3, setSsnPart3] = useState('');
    const [zip, setZip] = useState('');
    const [closeModal, setCloseModal] = useState({isOpen: false, type: null});
    const [helpModal, setHelpModal] = useState({isOpen: false, type: null})
    const [offBusinessHoursHelpModal, setOffBusinessHoursHelpModal] = useState({isOpen: false, type: null})
    const [IsEnrollmentStatusMenuOpen, setIsEnrollmentStatusMenuOpen] = useState(false);
    const [isEnrollmentStatusWizardOpen, setIsEnrollmentStatusWizardOpen] = useState(true);



    const value = {
        currentPage,
        setCurrentPage,
        dob,
        setDob,
        ssnPart1,
        setSsnPart1,
        ssnPart2,
        setSsnPart2,
        ssnPart3,
        setSsnPart3,
        zip,
        setZip,
        closeModal,
        setCloseModal,
        helpModal,
        setHelpModal,
        offBusinessHoursHelpModal,
        setOffBusinessHoursHelpModal,
        coverageYear,
        setCoverageYear,
        IsEnrollmentStatusMenuOpen,
        setIsEnrollmentStatusMenuOpen,
        isEnrollmentStatusWizardOpen,
        setIsEnrollmentStatusWizardOpen
    };
    return (
        <EnrollmentStatusContext.Provider value={value}>
            {children}
        </EnrollmentStatusContext.Provider>
    );
};
export default EnrollmentStatusContextProvider;