import React from 'react';
import classes from './Modal.module.css';
import modalCloseIcon from '../../Images/modal-close-icon.svg';

function Modal({ children, type, insideWizard, isPasscodeModal }) {
  return (
    <div className={`${classes['modal']} ${type === 'fixed' ? classes['modal--fixed'] : ""} ${insideWizard ? classes['modal--inside-wizard'] : ""} ${isPasscodeModal ? classes['passcode-modal'] : ""}`}>
      {children}
    </div>
  );
}

Modal.Dialog = ({ children , style}) => {
  return (
    <div className={`${classes['modal__dialog']}`} style={style}>
      {children}
    </div>
  )
}

Modal.Header = ({ children, style }) => {
  return (
    <div className={`${classes['modal__header']}`} style={style}>
      {children}
    </div>
  )
}

Modal.Title = ({ children, style }) => {
  return (
    <div className={`${classes['modal__title']}`} style={style}>
      {children}
    </div>
  )
}

Modal.Body = ({ children }) => {
  return (
    <div className={`${classes['modal__body']}`}>
      {children}
    </div>
  )
}

Modal.Close = ({ onClick }) => {
  return (
    <button onClick={onClick} className={`${classes['modal__close']}`}>
      <img src={modalCloseIcon} alt="Close Modal" />
    </button>
  )
}

Modal.Content = ({ children }) => {
  return (
    <div className={`${classes['modal__content']}`}>
      {children}
    </div>
  )
}

export default Modal;
