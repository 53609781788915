import React, { useState, useEffect, useContext } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import usePasswordReset from "../../Hooks/usePasswordReset";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Body from "../../UiKit/Wizard/Body/Body";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import { PasswordResetContext } from "../../Contexts/PasswordResetContext";
import Input from "../../UiKit/Wizard/Input/Input";
import useChatbot from "../../Hooks/useChatbot";
import classes from "../../UiKit/InputValidationMessage/InputValidationMessage.module.css";

function OtpTypePage({ postActivity, sendActivity, onNext, passwordResetEvent, onPrevious }) {
  const { next, previous } = usePasswordReset();
  const { otpType, setOtpType, setPhone, setEmail, setMaskedPhone, setMaskedEmail, phone, email } = useContext(PasswordResetContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [useExpiredToken, setUseExpiredToken] = useState(false);
  const [isUsedExpiredTokenVisible, setIsUsedExpiredTokenVisible] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const { closePasswordResetWizard } = useChatbot();

  useEffect(() => {
    if (passwordResetEvent && passwordResetEvent.data && passwordResetEvent.data.attachments) {
      const attachments = passwordResetEvent.data.attachments;
      if (attachments[0] && attachments[0].content && attachments[0].content.verificationData) {
        const verificationData = attachments[0].content.verificationData;
        const phone = verificationData.phone || '';
        const email = verificationData.email || '';
        setPhone(phone);
        setEmail(email);

        // Mask the phone and email and store them in context
        const maskedPhone = redactPhone(phone);
        const maskedEmail = redactEmail(email);
        setMaskedPhone(maskedPhone);
        setMaskedEmail(maskedEmail);  
      }
    }
  }, [passwordResetEvent, setPhone, setEmail, setMaskedPhone, setMaskedEmail]);



  const redactEmail = (email) => {
    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 1) + '*********';
    return `${maskedLocalPart}@${domain}`;
  };

  const redactPhone = (phone) => {
    const lastFourDigits = phone.slice(-4); 
    return `XXX-XXX-${lastFourDigits}`; 
  };

  const onSelect = (value) => {
    setOtpType(value);
    setShowValidationError(false);
  };

  const onSubmit = () => {
    if (otpType) {
      sendActivity({
        type: 'message',
        value: {
          type: 'forgotPasswordCardClick',
          otpType: otpType,
          intentName: 'forgotPassword',
          useExpiredToken: useExpiredToken
        }
      });
      setIsDisabled(true);
      setShowValidationError(false);
      onNext();
    } else {
      setShowValidationError(true);
    }
  };

  // const radioOptions = [
  //   {
  //     label: `To your email: ${email ? redactEmail(email) : ""}`,
  //     value: 'email'
  //   },
  //   {
  //     label: `To your cell phone: ${phone ? redactPhone(phone) : ""}`,
  //     value: 'phone'
  //   }
  // ];


  const radioOptions = [
    {
      label: (
        <>
          To your email: <strong>{email ? redactEmail(email) : ""}</strong>
        </>
      ),
      value: 'email',
    },
    {
      label: (
        <>
          To your cell phone: <strong>{phone ? redactPhone(phone) : ""}</strong>
        </>
      ),
      value: 'phone',
    },
  ];
  
  return (
    <PageLayout>
      <PageLayout.Header style={{ marginBottom: "8px" }}>
        <SubHeader subHeader="Receive One-time Passcode" />
      </PageLayout.Header>
      <PageLayout.Content>
        <Form>
          <Form.FormGroup style={{ marginTop: "8px" }}>
            <Body
              bodyText={
                <>
                  <div style={{ marginTop: '10px' }}>
                    To verify your identity, how would you like to receive your one-time passcode?
                  </div>
                </>
              }
            />
            <div style={{ marginTop: '16px', marginBottom: '32px' }}>
              <Form.RadioGroup
                name="otpType"
                selectedOption={otpType}
                onChange={onSelect}
                options={[radioOptions[0]]}
                disabled={isDisabled}
                optionStyle={{ marginBottom: '12px' }}
                validationFailed={showValidationError}
              />
              <Form.RadioGroup
                name="otpType"
                selectedOption={otpType}
                onChange={onSelect}
                options={[radioOptions[1]]}
                disabled={isDisabled}
                optionStyle={{ marginBottom: '0px' }}
                validationFailed={showValidationError}
              />
            </div>
            {showValidationError && (
             <InputValidationMessage className={classes['otp-validation-message']}>
             You must make a selection to continue.
           </InputValidationMessage>
           
            )}
            <div style={{
              color: '#334048',
              fontFamily: "Open Sans",
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '140%',
            }}>
              *Standard text message rates apply.
            </div>
            {isUsedExpiredTokenVisible && (
              <Form.Label>
                <Form.Control>
                  <Input
                    type="checkbox"
                    name="useExpiredToken"
                    checked={useExpiredToken}
                    onChange={() => setUseExpiredToken(!useExpiredToken)}
                  />
                  Use expired token
                </Form.Control>
              </Form.Label>
            )}
            {/* {showValidationError && (
              <InputValidationMessage>
                You must make a selection to continue.
              </InputValidationMessage>
            )} */}
          </Form.FormGroup>
        </Form>
      </PageLayout.Content>
      <WizardLayout.Navigation>
        <NavigationButton onClick={onSubmit} type={"primary"}>
          Submit
        </NavigationButton>
        {/* <NavigationButton onClick={() => closePasswordResetWizard()} type={"secondary"}>
          Exit
        </NavigationButton> */}
      </WizardLayout.Navigation>
    </PageLayout>
  );
}

export default OtpTypePage;