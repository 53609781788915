import React from "react";
import styles from "./planCard.module.css";

function formatDate(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
}

function PlanCard({
  premium,
  enrollmentStatus,
  enrollmentStartDate,
  enrollmentEndDate,
  planDetails,
  carrierName,
  healthHouseholdMembers,
  dentalHouseholdMembers,
  lang,
  planType,
  totalPlans,
  index,
  translations,
}) {

  const formattedStartDate = formatDate(enrollmentStartDate);
  const formattedEndDate = formatDate(enrollmentEndDate);

  function formatStatus(status, lang) {
    if (lang === "es") {
      if (status.toUpperCase() === "CONFIRM") {
        return "Inscrito";
      } else if (status.toUpperCase() === "PENDING") {
        return "Pendiente";
      } else if (status.toUpperCase() === "CANCEL") {
        return "Cancelado";
      } else if (status.toUpperCase() === "TERM") {
        return "Terminado";
      }
    } else if (lang === "en") {
      if (status.toUpperCase() === "CONFIRM") {
        return "Enrolled";
      } else if (status.toUpperCase() === "CANCEL") {
        return "Cancelled";
      } else if (status.toUpperCase() === "TERM") {
        return "Terminated";
      }
    }
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'Enrolled':
        return '#1E824C';
      case 'Pending':
        return '#FFCE56';
      case 'Terminated':
        return '#D50000';
        case 'inscrito':
          return '#1E824C';
        case 'Pendiente':
          return '#FFCE56';
        case 'Terminado':
          return '#D50000';
      default:
        return 'grey';
    }
  };

  const displayStatus = formatStatus(enrollmentStatus, lang);
  const statusColor = getStatusColor(displayStatus);
  const planName = `${carrierName} ${planDetails}`;
  const memberCount = healthHouseholdMembers || dentalHouseholdMembers;
  const member = translations.memberLabel;
  const members = translations.membersLabel;
  const memberLabel = memberCount === 1 ? member : members;


  //console.log("translations", translations)
  return (
    <>
    <div className={styles.planCard}>
      <div className={styles.planCardHeader}>
        <b className={styles.planType}>
          {planType === "Health" && totalPlans >= 1
            ? lang === "en"
              ? `Health Plan Group ${index + 1}`
              : lang === "es"
              ? `Plan de Salud Grupo ${index + 1}`
              : null
            : planType === "Health"
            ? translations.healthHeader
            : translations.dentalHeader}
        </b>
        <div className={styles.status}>
          <span className={styles.statusDot} style={{ backgroundColor: statusColor }}></span>
          <p className={styles.statusText}>{displayStatus}</p>
        </div>
      </div>
      <hr className={styles.planCardDivider} />
      <div className={styles.planCardBody}>
        <div className={styles.planDetail}>
          <span className={styles.planDetailLabel}>{translations.label1}:</span>
          <span>{memberCount} {memberLabel}</span>
        </div>
        <div className={styles.planDetail}>
          <span className={styles.planDetailLabel}>{translations.label2}:</span>
          <span>{planName}</span>
        </div>
        <div className={styles.planDetail}>
          <span className={styles.planDetailLabel}>{translations.label3}:</span>
          <span>{formattedStartDate} - {formattedEndDate}</span>
        </div>
        <div className={styles.planDetail}>
          <span className={styles.planDetailLabel}>{translations.label4}:</span>
          <span>${Number(premium).toFixed(2)} {translations.perMonth}</span>
        </div>
      </div>
    </div>
    </>
  );
}

export default PlanCard;
