import React from 'react';
import {connectToWebChat} from 'botframework-webchat';
import OtpTypeAttachment from './OtpTypeAttachment';

const ConnectedAttachmentWithOtpType = connectToWebChat(
  ({ postActivity }) => ({ postActivity })
)(props => 
<OtpTypeAttachment {...props} />
)

export default ConnectedAttachmentWithOtpType;