import React, {useEffect, useState} from "react";
import useM2C from "../../Hooks/useM2C";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import KeepOrCancelSelection from "../../UiKit/Wizard/KeepOrCancelSelect/KeepOrCancelSelect";
import {useLanguage} from "../../UiKit/Wizard/langugeContext";
import {translations} from "./translations";
import Body from "../../UiKit/Wizard/Body/Body";
import PlanDetails from "../../UiKit/Wizard/PlanDetails/PlanDetails";
import { FormGroup } from "react-bootstrap";

function KeepOrCancelSelectPage(props) {
    const {next, previous, sb260Event} = useM2C();
    const {setMemberDecisions, onNext, updatekeepEnrollmentYear} = props;
    const {currentLanguage} = useLanguage();
    const KeepTranslations = translations[currentLanguage]["KeepOrCancelPage"];
    const NavButton = translations[currentLanguage]["NavButton"];

    const [selectedOption, setSelectedOption] = useState("");
    const [showValidationError, setShowValidationError] = useState(false); // State to track selected option

    const handleSelectionChange = (option) => {
        setSelectedOption(option);
        setMemberDecisions(option); // Update the parent component with the user's decision
        setShowValidationError(false); // Clear validation error when an option is selected
    };

    const onNextClick = () => {
        if (selectedOption) {
            setShowValidationError(false); 
            const year = sb260Event.data.enrollmentYear;
            updatekeepEnrollmentYear(year); // Update the parent component with the enrollment year
            onNext(); 
        } else {
            setShowValidationError(true); // Show error if no option is selected
        }
    };


    return (
        <PageLayout>
           <PageLayout.Header style={{ marginBottom: "-10px" }}>
                <SubHeader style={{ marginBottom: "8px" }} subHeader={KeepTranslations.Title} />
            </PageLayout.Header>       
            <PageLayout.Content>
                <Body style={{marginTop:"10px"}} bodyText={KeepTranslations.Body} />
                <Form style ={{paddingTop: '32px'}}>
                    <PlanDetails
                      group={KeepTranslations.PlanDetails.PlanName}
                      planName={sb260Event.data.plan}
                      monthlyCost={sb260Event.data.monthlyPremium}
                    />
                    <hr style={{ border: "0px", borderTop: "1px solid", margin: "16px 0", borderColor: "#D1D1D1" }} />
                    <div style={{ display: "flex", alignItems: "center", gap: "20px",  flexDirection: "row" }}>
                   <Form.RadioGroup
                        name="keepOrCancel"
                        selectedOption={selectedOption}
                        onChange={handleSelectionChange}
                        options={[
                            { value: "keep", label: KeepTranslations.PersonSelection.Keep },
                            { value: "cancel", label: KeepTranslations.PersonSelection.Cancel },
                        ]}
                        containerStyle={{
                            display: "flex",         
                            flexDirection: "row",   
                            alignItems: "center",     
                            gap: "20px",             
                        }}
                    />
                    </div>
                </Form>
                <Body style={{marginTop:"32px"}}bodyText={KeepTranslations.Note} />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={onNextClick} type={"primary"}>{NavButton.NextButton}</NavigationButton>
                <NavigationButton onClick={() => previous()} type={"secondary"}>{NavButton.BackButton}</NavigationButton>
            </PageLayout.Navigation>
            
                    
        </PageLayout>
    );

}
export default KeepOrCancelSelectPage;
