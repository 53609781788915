import React, { useContext } from 'react';
import classes from './HeaderMenuToggle.module.css';
import menuIcon from '../../../../Images/header-menu-toggle-icon.svg';
import { ChatbotContext } from '../../../../Contexts/ChatbotContext';

function HeaderMenuToggle({ onClick }) {
  const { isMenuOpen, setIsMenuOpen } = useContext(ChatbotContext);

  const onMenuToggleClick = () => {
    setIsMenuOpen(prevState => !prevState); 
    if (onClick) onClick();
  };

  const handleKeyDown = (event) => {
   // console.log('Menu toggle keydown:', event.key);
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); 
     // console.log("Opening menu with Enter/Space");
      onMenuToggleClick(); 
    }
  };

  const handleFocus = () => {
   // console.log("Button is focused");
  };
  

  return (
    <button
      onClick={onMenuToggleClick}
      onKeyDown={handleKeyDown} 
      aria-expanded={isMenuOpen}
      aria-label="Toggle menu"
      className={classes['header-menu-toggle']}
      tabIndex="0" 
      onFocus={handleFocus} 
    >
      <img src={menuIcon} alt="Menu" />
    </button>
  );
}

export default HeaderMenuToggle;
