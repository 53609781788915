import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import usePasswordReset from "../../Hooks/usePasswordReset";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
// import { translations } from "./translations";
import useChatbot from "../../Hooks/useChatbot";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import { PAGES } from "./PasswordResetWizard";
// import PasswordResetContextProvider from "../../Contexts/PasswordResetContext";

function ServiceCenterPage(props) {
    const { closePasswordReset, setCurrentPage, resetPasswordResetState } = usePasswordReset();
    const { currentLanguage } = useLanguage();
    const {closePasswordResetWizard, openWelcomePage} = useChatbot();
    // const { resetPasswordResetState } = PasswordResetContextProvider();

    const handleStartOver = () => {
         //resetPasswordResetState();
        openWelcomePage();
        //setCurrentPage(PAGES.WELCOME);  // Set the current page to WELCOME
    };


    return (
        <PageLayout>
            <PageLayout.Content>
                 <Alert
                        iconType='red'
                        frameIcon='red'
                        message="Your session has expired. To continue, click “Exit” below."
                    />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton 
                // onClick={() => closePasswordReset()} 
                 onClick={() => closePasswordResetWizard()}
               // onClick={handleStartOver}  // Navigate to WelcomePage
                type="primary" style={{ marginBottom: "36px" }}>
                Exit
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

export default ServiceCenterPage;
